import React, { FC } from 'react';
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useCustomMutation } from "../../../../../api/useCustomMutation";
import { toast } from "react-toastify";
import { UPLOAD_ICON_FOR_MAP_URL } from "../../../../../api/urls";
import { ls_getProjectInfo } from "../../../../../helpers/localStorage";

interface Props {
  plantingId: number,
  setIconUrl: (url: string) => void
}

const ChooseIcon: FC<Props> = ({ setIconUrl, plantingId }) => {
  const { register, handleSubmit } = useForm()
  const project = ls_getProjectInfo()
  const mutation = useCustomMutation()

  const onClick = async (form: any) => {
    try {
      const data = new FormData()
      data.append('image', form.icon)
      data.append('id', plantingId.toString())
      data.append('schema', project.schema)
      data.append('imageFolder', project.imageFolder)
      data.append('preload', 'true')

      const response = await mutation.mutateAsync({
        url: UPLOAD_ICON_FOR_MAP_URL,
        method: 'patch',
        data
      })

    } catch (e: any) {
      toast.error(
        <p>
          {e.response?.data.message}
          {e.response?.data.error}
        </p>
      )
    }
  }

  return (
    <div>
      <TextField
        {...register('icon', { required: true })}
        size={'small'}
        type={'file'}
        label={'Иконка'}
        InputLabelProps={{ shrink: true }}
      />

      <LoadingButton variant={"outlined"} onClick={handleSubmit(onClick)}>
        Далее
      </LoadingButton>
    </div>
  );
};

export default ChooseIcon;