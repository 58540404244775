import React from 'react';
import { Grid } from '@mui/material';
import Import from "../../../../components/import/Import";

const ImportFeatures = () => {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Import/>
      </Grid>

      {/*<Grid item>*/}
      {/*  <ReplaceSectionOrBorder type={'section'}/>*/}
      {/*</Grid>*/}

      {/*<Grid item>*/}
      {/*  <ReplaceSectionOrBorder type={'border'}/>*/}
      {/*</Grid>*/}
    </Grid>
  );
};

export default ImportFeatures;