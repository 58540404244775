export const ROLES = {
  superAdmin: 'superAdmin',
  admin: 'admin',
  superUser: 'superUser',
  editorUser: 'editorUser',
  user: 'user',
}

export const SCOPES = {
  superAdmin: 'superAdmin',
  admin: 'admin',
  superUser: 'superUser',
  editorUser: 'editorUser',
  user: 'user',
  canCreate: 'can-create',
  canEdit: 'can-edit',
  canDelete: 'can-delete',
  canView: 'can-view',
}

export const PERMISSIONS = {
  [ROLES.superAdmin]: [
    SCOPES.canView,
    SCOPES.canEdit,
    SCOPES.canCreate,
    SCOPES.canDelete,
    SCOPES.superAdmin,
    SCOPES.admin,
    SCOPES.user,
    SCOPES.superUser,
  ],
  [ROLES.admin]: [
    SCOPES.canView,
    SCOPES.canEdit,
    SCOPES.canDelete,
    SCOPES.canCreate,
    SCOPES.admin,
    SCOPES.superUser,
  ],
  [ROLES.user]: [
    SCOPES.canView,
    SCOPES.user,
  ],
  [ROLES.superUser]: [
    SCOPES.editorUser,
    SCOPES.superUser,
    SCOPES.canCreate,
    SCOPES.canView,
    SCOPES.canEdit,
    SCOPES.user,
  ],
  [ROLES.editorUser]: [
    SCOPES.canCreate,
    SCOPES.canView,
    SCOPES.canEdit,
    SCOPES.user,
    SCOPES.editorUser,
  ],
}