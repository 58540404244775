import React, {FC, useState} from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack
} from '@mui/material';
import {toast} from 'react-toastify';
import {LoadingButton} from '@mui/lab';
import Loader from '../UI/Loader/Loader';
import {useCustomMutation} from '../../api/useCustomMutation';
import {EXPORT_URL} from "../../api/urls";
import styles from './exportDataFromDB.module.scss';
import SelectProject from "../SelectProject/SelectProject";
import {useRecoilState} from "recoil";
import {currentProjectAtom} from "../../store/projectStore";
import {SCOPES} from "../../helpers/permissions/permissionsMaps";
import PermissionsGate from "../../helpers/permissions/PermissionsGate";
import {useGetRole} from "../../helpers/permissions/useGetRole";

interface Props {
  isLoading?: boolean,
  needShowSelect?: boolean
}

const ExportDataFromDB: FC<Props> = ({isLoading = false, needShowSelect = true}) => {
  const mutation = useCustomMutation()
  const [links, setLinks] = useState<string[]>([])
  const [currentProject, setCurrentProject] = useRecoilState(currentProjectAtom)
  const [type, setType] = useState('statement')
  const {role} = useGetRole()

  const onProjectClick = (project: any) => {
    setCurrentProject(project)
  }

  const exportMenuItems = [
    {key: 'excel', name: 'excel', permission: 'admin'},
    // {key: 'geojson-all', name: 'geojson-all'},
    {key: 'geojson', name: 'geojson'},
    {key: 'statement', name: 'Перечётная ведомость'}
  ]

  const handleChange = (e: any) => {
    setType(e.target.value)
  }

  const onExport = async () => {
    try {
      if (!currentProject) {
        return
      }

      const {data} = await mutation.mutateAsync({
        method: 'get',
        url: EXPORT_URL + `?schema=${currentProject.schema}&exportType=${type}`
      })

      toast.success(data.message)

      if (!data.urls) {
        return
      }

      setLinks(data.urls)

      setTimeout(() => {
        setLinks([])
      }, 120000)

    } catch (e: any) {
      console.log(e)
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  const onClear = () => {
    setLinks([])
  }

  if (isLoading) {
    return <Loader/>
  }

  return (
    <Card elevation={4} className={styles.card}>
      <CardHeader title={`Отчёт по объекту ${currentProject ? currentProject.name : ''}`}/>

      <CardContent>
        {needShowSelect &&
          <div>
            <SelectProject needFirstSelect={false} onProjectClick={onProjectClick}/>

            <h2>{currentProject ? currentProject.name : 'Выберите проект'}</h2>
          </div>
        }

        <FormControl fullWidth className={styles.select__format}>
          <InputLabel id="demo-simple-select-label">Формат</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={type ?? ''}
            label="Формат"
            onChange={handleChange}
          >
            {exportMenuItems.map(({key, name, permission}) => {
              console.log(
                name,
                role,
                permission,
                permission ? role?.toLowerCase().includes(permission) : 'suka',
                !permission ? 'block' : (role?.toLowerCase().includes(permission) ? 'block' : 'none')
              )
              return (
                <MenuItem
                  value={key}
                  style={{display: !permission ? 'block' : (role?.toLowerCase().includes(permission) ? 'block' : 'none')}}
                >
                  {name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <FormHelperText>
          После успешного экспорта ссылки и файлы на сервере живут 120 секунд
        </FormHelperText>

        {links.length > 0 &&
          links.map(link =>
            <Stack key={link} spacing={1} padding={1}>
              <a download href={link} target={'_blank'} rel="noreferrer">{link}</a>
            </Stack>
          )}

      </CardContent>

      <CardActions>
        <LoadingButton
          disabled={!type}
          variant={'contained'}
          loading={mutation.isLoading}
          onClick={onExport}
        >
          Экспортировать
        </LoadingButton>

        <Button onClick={onClear} disabled={!links.length} variant={'contained'}>
          Очистить
        </Button>
      </CardActions>
    </Card>
  );
};

export default ExportDataFromDB;