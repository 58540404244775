import React, { FC, useEffect } from 'react';
import useEditUserPermission from "../useEditUserPermission";
import styles from '../../../../users.module.scss';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack
} from "@mui/material";
import { IProject } from "../../../../../../../models/IProject";

interface Props {
  params: any
  closeDialog: () => void
}

const EditProjectPermissions: FC<Props> = ({ params, closeDialog }) => {

  const {
    projectsData,
    currentProjects,
    userPermission,
    submitDelete,
    projects,
    setProjects,
    theme,
    handleOpenAlert,
    handleCloseAlert,
    handleChange,
    handleSubmit,
    handleSubmitDelete,
    findProjectByPermissionId,
    MenuProps,
    getStyles,
    getUserPermission
  } = useEditUserPermission(params)

  useEffect(() => {
    getUserPermission()
    if (projects.length === 0 && userPermission?.length === 0 && projectsData) {
      setProjects(projectsData?.data)
    }

    if (userPermission && projectsData) {
      const projectsSet = new Set(userPermission.map((perm: any) => (perm.project)))

      const newProjects = projectsData?.data?.filter((project: any) => {
        return !projectsSet.has(project.name)
      })

      setProjects(newProjects)
    }
  }, [userPermission, projectsData])

  return (
    <>
      <div className={styles.user_permission_content}>
        <p>Проекты назначенные пользователю:</p>

        <div className={styles.user_permissions_wrapper}>
          {(userPermission && userPermission.length !== 0) ?
            userPermission.map((perm: any, index: number) => (
              <Chip
                color="primary"
                size={'small'}
                key={index}
                label={perm.project}
                onDelete={() => handleOpenAlert(perm.id)}
              />
            ))
            :
            <div>У пользователя нет ограничений</div>
          }
        </div>

        <FormControl>
          <InputLabel>Назначить новые проекты</InputLabel>

          <Select
            multiple
            value={currentProjects}
            onChange={handleChange}
            className={styles.user_permissions__input}
            input={
              <OutlinedInput
                id="select-multiple-chip"
                label="Назначить новые проекты"
              />
            }
            renderValue={(selected: any) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value: any, index: number) => (
                  <Chip key={index} label={value}/>
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {projects.map((project: IProject) => (
              <MenuItem
                key={project.name}
                value={project.name}
                style={getStyles(project.name, currentProjects, theme)}
              >
                {project.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div>
          <Button onClick={closeDialog}>
            Отмена
          </Button>

          <Button onClick={handleSubmit}>
            Сохранить
          </Button>
        </div>
      </div>

      <Dialog open={!!submitDelete} onClose={handleCloseAlert}>
        <DialogTitle>
          Удалить у пользователя {params.row.email} права на проект {findProjectByPermissionId()}?
        </DialogTitle>

        <DialogContent>
          <Stack direction={'row'} justifyContent={'space-evenly'}>
            <Button onClick={handleSubmitDelete}>
              Да
            </Button>

            <Button onClick={handleCloseAlert}>
              Нет
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditProjectPermissions;