// packages
import React, { FC, useState } from 'react'
import { GridRenderCellParams } from '@mui/x-data-grid'
import { useForm } from 'react-hook-form'
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
// components
import Loader from '../../../../components/UI/Loader/Loader';
import Icon from "../../../../components/UI/Icons/Icon";
import DeleteOrRestoreButtons from "../../../../components/UI/CrudActions/DeleteOrRestoreButtons";

interface Props {
  params: GridRenderCellParams
  onEdit: any
  onRemove: (id: number) => void
  isLoading: boolean
  municipalities: any
  onRestore: (id: number) => void
}

const AreaActions: FC<Props> = ({ params, onEdit, onRemove, isLoading, municipalities, onRestore }) => {
  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      idRgis: params.row.idRgis,
      idMunicipality: params.row.idMunicipality,
      name: params.row.name,
    }
  })

  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false)

  const handleCloseEdit = () => setIsOpenEditDialog(false)
  const handleOpenEdit = () => setIsOpenEditDialog(true)

  const findMunicipalityByName = (name: string) => {
    const municipality = municipalities.find((obj: any) => obj.name === name)
    return municipality.id
  }

  const onEditProject = (dto: any) => {
    if (typeof dto.idMunicipality !== 'number') {
      dto.idMunicipality = findMunicipalityByName(dto.idMunicipality)
      onEdit(params.row.id, dto)
      return
    }

    onEdit(params.row.id, dto)
    handleCloseEdit()
    reset()
  }

  if (municipalities) {
    const autoCompleteData: any[] = municipalities.map((muni: any) => ({
      name: muni.name,
      id: muni.id
    }))

    return (
      <>
        <IconButton onClick={handleOpenEdit}>
          <Icon icon={'edit'} color={'#ff5b00'}/>
        </IconButton>

        <Dialog open={isOpenEditDialog} onClose={handleCloseEdit}>
          <DialogTitle>
            Обновление объекта
          </DialogTitle>

          <DialogContent>
            <Stack spacing={2} padding={1}>
              <TextField {...register('name')} label={'Название'}/>
              <TextField {...register('idRgis')} label={'Регион'}/>

              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option.name || ''}
                options={autoCompleteData}
                sx={{ width: 300 }}
                renderInput={
                  (params) =>
                    <TextField
                      {...params}
                      {...register('idMunicipality')}
                      label={'Округ'}
                    />
                }
              />

            </Stack>
          </DialogContent>

          <DialogActions>
            <LoadingButton onClick={handleCloseEdit} autoFocus loading={isLoading}>
              Отмена
            </LoadingButton>
            <LoadingButton onClick={handleSubmit(onEditProject)}>
              Обновить
            </LoadingButton>
          </DialogActions>
        </Dialog>

        <DeleteOrRestoreButtons
          isLoading={isLoading}
          onRestore={onRestore}
          params={params}
          onRemove={onRemove}
          currentObject={'area'}
        />
      </>
    )
  } else {
    return (
      <>
        <Dialog open={isOpenEditDialog} onClose={handleCloseEdit}>
          <Loader/>
        </Dialog>

        <DeleteOrRestoreButtons
          isLoading={isLoading}
          onRestore={onRestore}
          params={params}
          onRemove={onRemove}
          currentObject={'user'}
        />
      </>
    )
  }
}

export default AreaActions