import React, { useEffect } from 'react';
import useMapPlanting from './useMapPlanting';
import { useParams } from 'react-router-dom';
import { IPlanting } from '../../../../models/IPlanting';
import { Controller, useForm } from 'react-hook-form';
import { showInfoField } from '../../../../helpers/showInfoField';
import { plantingDto } from '../usePlantings';
import { Button, Stack, TextField, Typography } from '@mui/material';
import styles from './editPlantingFormStyles.module.scss';
import Loader from '../../../../components/UI/Loader/Loader';
import EditPhotos from "../../../../components/UI/EditPhotos/EditPhotos";
import { DELETE_PLANTING_PHOTO_URL } from "../../../../api/urls";
import { Autocomplete } from "@mui/lab";
import EditIconDialog from "./EditIcon/EditIconDialog";

const EditPlantingForm = () => {
  const params = useParams()

  const form = useForm<IPlanting>({
    defaultValues: {
      id: 0,
      plantingNumber: 0,
      idSection: 0,
      sectionNumber: '',
      age: 0,
      count: 0,
      diameter: '',
      height: 0,
      notes: '',
      trunkCount: 0,
      kind: '',
      genus: '',
      lifeForm: '',
      condition: '',
      name: '',
    }
  })

  const {
    planting,
    isLoading,
    onSubmit,
    plantingRefetch,
    cancel,
    dictionary,
    projectInfo,
  } = useMapPlanting(Number(params.id))

  useEffect(() => {
    if (planting) {
      const filtredPlanting: any = {}

      for (const { fieldName } of plantingDto) {
        if (typeof planting[fieldName] === 'boolean') {
          filtredPlanting[fieldName] = planting[fieldName]
          continue
        }
        filtredPlanting[fieldName] = showInfoField(planting[fieldName])
      }

      form.reset(filtredPlanting)
    }
  }, [form, planting])

  if (isLoading || !planting) {
    return (<Loader/>)
  }

  return (
    <Stack className={styles.content} direction={'row'} spacing={2}>
      <div className={styles.left__content}>
        <Stack className={styles.inputs__stack}>
          <div className={styles.field}>
            <Typography>ID насаждения:</Typography>

            <Controller
              name={'id'}
              control={form.control}
              render={({ field }) =>
                <TextField
                  onFocus={(event) => {
                    event.target.select()
                  }}
                  disabled
                  size={'small'}
                  {...field}
                  label={'ID насаждения:'}
                />
              }
            />
          </div>

          <div className={styles.field}>
            <Typography>Номер насаждения</Typography>

            <Controller
              name={'plantingNumber'}
              control={form.control}
              render={({ field }) =>
                <TextField
                  onFocus={(event) => {
                    event.target.select()
                  }}
                  size={'small'}
                  {...field}
                  label={'Номер насаждения:'}
                />
              }
            />
          </div>

          <div className={styles.field}>
            <Typography>№ сектора:</Typography>

            <Controller
              name={'sectionNumber'}
              control={form.control}
              render={({ field }) =>
                <TextField
                  onFocus={(event) => {
                    event.target.select()
                  }}
                  size={'small'}
                  disabled
                  {...field}
                  label={'№ сектора:'}
                />
              }
            />
          </div>

          <div className={styles.field}>
            <Typography>Диаметр</Typography>

            <Controller
              name={'diameter'}
              control={form.control}
              render={({ field }) =>
                <TextField
                  onFocus={(event) => {
                    event.target.select()
                  }}
                  size={'small'}
                  {...field}
                  label={'Диаметр: '}
                />
              }
            />
          </div>

          <div className={styles.field}>
            <Typography>Высота</Typography>

            <Controller
              name={'height'}
              control={form.control}
              render={({ field }) =>
                <TextField
                  onFocus={(event) => {
                    event.target.select()
                  }}
                  size={'small'}
                  {...field}
                  label={'Высота: '}
                />
              }
            />
          </div>

          <div className={styles.field}>
            <Typography>Возраст</Typography>

            <Controller
              name={'age'}
              control={form.control}
              render={({ field }) =>
                <TextField
                  onFocus={(event) => {
                    event.target.select()
                  }}
                  size={'small'}
                  {...field}
                  label={'Возраст: '}
                />
              }
            />
          </div>

          <div className={styles.field}>
            <Typography>Кол-во</Typography>

            <Controller
              name={'count'}
              control={form.control}
              render={({ field }) =>
                <TextField
                  onFocus={(event) => {
                    event.target.select()
                  }}
                  size={'small'}
                  {...field}
                  label={'Кол-во: '}
                />
              }
            />
          </div>

          <div className={styles.field}>
            <Typography>Примечание</Typography>

            <Controller
              name={'notes'}
              control={form.control}
              render={({ field }) =>
                <TextField
                  onFocus={(event) => {
                    event.target.select()
                  }}
                  size={'small'}
                  {...field}
                  label={'Примечание: '}
                />
              }
            />
          </div>

          <div className={styles.field}>
            <Typography>Кол-во стволов</Typography>

            <Controller
              name={'trunkCount'}
              control={form.control}
              render={({ field }) =>
                <TextField
                  onFocus={(event) => {
                    event.target.select()
                  }}
                  size={'small'}
                  {...field}
                  label={'Кол-во стволов: '}
                />
              }
            />
          </div>

          <div className={styles.field}>
            <Typography>Вид:</Typography>

            <Controller
              name={'kind'}
              control={form.control}
              render={({ field }) =>
                <Autocomplete
                  getOptionLabel={(option: any) => option.name || ''}
                  disablePortal
                  defaultValue={null}
                  sx={{ width: 300 }}
                  // @ts-ignore
                  options={dictionary.kinds}
                  onChange={(e) => {
                    // @ts-ignore
                    form.control._formValues.kind = e.target.innerText
                    form.formState.dirtyFields.kind = true
                  }}
                  renderInput={(params) =>
                    <TextField
                      onFocus={(event) => {
                        event.target.select()
                      }}
                      label={'Вид:'}
                      {...params}
                      {...field}
                    />}
                />
              }
            />
          </div>

          <div className={styles.field}>
            <Typography>Род:</Typography>

            <Controller
              name={'genus'}
              control={form.control}
              render={({ field }) =>
                <Autocomplete
                  getOptionLabel={(option: any) => option.name || ''}
                  disablePortal
                  defaultValue={null}
                  sx={{ width: 300 }}
                  // @ts-ignore
                  options={dictionary.genus}
                  onChange={(e) => {
                    // @ts-ignore
                    form.control._formValues.genus = e.target.innerText
                    form.formState.dirtyFields.genus = true
                  }}
                  renderInput={(params) =>
                    <TextField
                      onFocus={(event) => {
                        event.target.select()
                      }}
                      label={'Род:'}
                      {...params}
                      {...field}
                    />}
                />
              }
            />
          </div>

          <div className={styles.field}>
            <Typography>Жизненная форма:</Typography>

            <Controller
              name={'lifeForm'}
              control={form.control}
              render={({ field }) =>
                <Autocomplete
                  getOptionLabel={(option: any) => option.name || ''}
                  disablePortal
                  defaultValue={null}
                  sx={{ width: 300 }}
                  // @ts-ignore
                  options={dictionary.lifeForms}
                  onChange={(e) => {
                    // @ts-ignore
                    form.control._formValues.lifeForm = e.target.innerText
                    form.formState.dirtyFields.lifeForm = true
                  }}
                  renderInput={(params) =>
                    <TextField
                      onFocus={(event) => {
                        event.target.select()
                      }}
                      label={'Жизненная форма:'}
                      {...params}
                      {...field}
                    />}
                />
              }
            />
          </div>

          <div className={styles.field}>
            <Typography>Состояние:</Typography>

            <Controller
              name={'condition'}
              control={form.control}
              render={({ field }) =>
                <Autocomplete
                  getOptionLabel={(option: any) => option.name || ''}
                  disablePortal
                  defaultValue={null}
                  sx={{ width: 300 }}
                  // @ts-ignore
                  options={dictionary.conditions}
                  onChange={(e) => {
                    // @ts-ignore
                    form.control._formValues.condition = e.target.innerText
                    form.formState.dirtyFields.condition = true
                  }}
                  renderInput={(params) =>
                    <TextField
                      onFocus={(event) => {
                        event.target.select()
                      }}
                      label={'Состояние:'}
                      {...params}
                      {...field}
                    />}
                />
              }
            />
          </div>

          <div className={styles.field}>
            <Typography>Название:</Typography>

            <Controller
              name={'name'}
              control={form.control}
              render={({ field }) =>
                <Autocomplete
                  getOptionLabel={(option: any) => option.name || ''}
                  disablePortal
                  defaultValue={null}
                  sx={{ width: 300 }}
                  // @ts-ignore
                  options={dictionary.names}
                  onChange={(e) => {
                    // @ts-ignore
                    form.control._formValues.name = e.target.innerText
                    form.formState.dirtyFields.name = true
                  }}
                  renderInput={(params) =>
                    <TextField
                      onFocus={(event) => {
                        event.target.select()
                      }}
                      label={'Название:'}
                      {...params}
                      {...field}
                    />}
                />
              }
            />
          </div>

          <EditIconDialog planting={planting}/>
        </Stack>

        <div className={styles.buttons}>
          <Button
            onClick={cancel}
            className={styles.submit__button}
            variant={'contained'}
          >
            Отмена
          </Button>

          <Button
            onClick={form.handleSubmit(onSubmit)}
            className={styles.submit__button}
            variant={'contained'}
            color={'success'}
          >
            Подтвердить
          </Button>
        </div>

      </div>

      <Stack>
        <EditPhotos
          refetch={plantingRefetch}
          deleteUrl={DELETE_PLANTING_PHOTO_URL}
          photos={planting.photos}
          uuid={planting.uuid}
        />
      </Stack>
    </Stack>
  );
};

export default EditPlantingForm;