import { axiosInstance } from '../axios'
import {
  GET_ALL_DELETED_PROJECTS_URL,
  GET_DATA_FOR_MAP_URL, GET_DATA_FOR_MINI_MAP_URL,
  GET_PLANTING_URL,
  GET_PLANTINGS_URL,
  GET_PROJECT_WITHOUT_SCHEMA_DATA_URL,
  GET_PROJECTS_BY_MUNICIPALITY_URL,
  GET_PROJECTS_URL,
  GET_PROJECTS_WITH_PERMISSIONS_URL
} from '../urls'
import { IProject } from '../../models/IProject'
import { ls_getProjectInfo } from '../../helpers/localStorage'

export const getProjects = () => {
  return axiosInstance.get<IProject[]>(GET_PROJECTS_URL)
}

export const getProjectsWithPermissions = async () => {
  const response = await axiosInstance.get<IProject[]>(GET_PROJECTS_WITH_PERMISSIONS_URL)
  return response.data
}

export const getDeletedProjects = () => {
  return axiosInstance.get<IProject[]>(GET_ALL_DELETED_PROJECTS_URL)
}

export const getPlantings = (offset: number, count: number, searchParams: any, sortedParams: any) => {
  const schema = ls_getProjectInfo().schema
  return axiosInstance.get(GET_PLANTINGS_URL + `?schema=${schema}&offset=${offset}&count=${count}&search=${JSON.stringify(searchParams)}&sortedName=${JSON.stringify(sortedParams)}`)
}

export const getPlantingById = (id: number) => {
  const schema = ls_getProjectInfo().schema
  return axiosInstance.get(GET_PLANTING_URL + id + `?schema=${schema}`)
}

export const getDataForMap = () => {
  const schema = ls_getProjectInfo().schema
  return axiosInstance.get(GET_DATA_FOR_MAP_URL + schema)
}

export const getProjectWithoutSchema = async () => {
  const response = await axiosInstance.get(GET_PROJECT_WITHOUT_SCHEMA_DATA_URL)

  return response.data
}

export const getProjectByMunicipality = async () => {
  return await axiosInstance.get(GET_PROJECTS_BY_MUNICIPALITY_URL)
}

export const getDataForMiniMap = async (idPlanting: number, schema: string) => {
  return await axiosInstance.get(GET_DATA_FOR_MINI_MAP_URL + `?schema=${schema}&idPlanting=${idPlanting}`)
}