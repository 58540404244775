import React from 'react';
import { Grid } from "@mui/material";
import DeletePhotosDuplicates from "../../../../components/deletePhotosDuplicates/DeletePhotosDuplicates";

const FindersMenu = () => {
  return (
    <Grid spacing={2} container>
      <Grid sm={3} item>
        <DeletePhotosDuplicates/>
      </Grid>
    </Grid>
  );
};

export default FindersMenu;