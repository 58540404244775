export const BASE_URL = process.env.REACT_APP_BASE_URL

// auth urls
export const REFRESH_URL = '/auth/refresh'
export const SIGN_IN_URL = '/auth/login'
export const LOGOUT_URL = '/auth/logout'

// user urls
export const SIGN_UP_URL = '/user/register'
export const GET_ALL_USERS_URL = '/user/all'
export const GET_ALL_DELETED_USERS_URL = '/user/deleted'
export const GET_USER_URL = '/user/'
export const GET_USERS_ROLE_URL = '/user/role'
export const UPDATE_USER_URL = '/user/'
export const UPDATE_USER_ROLE_URL = '/user/role'
export const DELETE_USER_URL = '/user/'
export const ACTIVATE_USER_URL = '/user/activate/'
export const DEACTIVATE_USER_URL = '/user/deactivate/'
export const RESTORE_USER_URL = '/user/restore/'
export const CHECK_RESET_PASSWORD_LINK_URL = '/user/check-reset-link'
export const SEND_RESET_PASSWORD_LINK_URL = '/user/send-reset-password-link'
export const RESET_PASSWORD_URL = '/user/reset-password'

// userPermission urls
export const GET_USER_PERMISSIONS_URL = '/user-permission/'
export const CREATE_USER_PERMISSION_URL = '/user-permission/'
export const DELETE_USER_PERMISSION_URL = '/user-permission/'

// project urls
export const CREATE_PROJECT_URL = '/project'
export const GET_PROJECTS_URL = '/project'
export const GET_PROJECTS_WITH_PERMISSIONS_URL = '/project/withPermissions'
export const GET_PROJECTS_BY_MUNICIPALITY_URL = '/project/byMunicipality'
export const GET_ALL_DELETED_PROJECTS_URL = '/project/deleted'
export const GET_PROJECT_URL = '/project/'
export const GET_DATA_FOR_MAP_URL = '/project/desktop/map/'
export const GET_DATA_FOR_MINI_MAP_URL = '/project/desktop/mini-map/'
export const GET_DATA_FOR_MAP_BY_SECTIONS_URL = '/project/desktop/map-by-sections/'
export const UPDATE_PROJECT_URL = '/project/'
export const SOFT_DELETE_PROJECT_URL = '/project/remove/'
export const UNSOFT_DELETE_PROJECT_URL = '/project/delete/'
export const RESTORE_PROJECT_URL = '/project/restore/'

// area urls
export const CREATE_AREA_URL = '/area'
export const GET_AREAS_URL = '/area'
export const GET_ALL_DELETED_AREAS_URL = '/area/deleted'
export const UPDATE_AREA_URL = '/area/'
export const DELETE_AREA_URL = '/area/'
export const RESTORE_AREA_URL = '/area/restore/'

//dictionary urls
export const GET_DICTIONARY_URL = '/dictionary'
export const UPDATE_DICTIONARY_URL = '/dictionary'
export const DELETE_DICTIONARY_URL = '/dictionary/'
export const CREATE_DICTIONARY_URL = '/dictionary/'
export const GET_ALL_DICTIONARY_URL = '/dictionary/all'

// municipality urls
export const GET_MUNICIPALITIES_URL = '/municipality'
export const GET_ALL_DELETED_MUNICIPALITIES_URL = '/municipality/deleted'
export const CREATE_MUNICIPALITY_URL = '/municipality'
export const UPDATE_MUNICIPALITY_URL = '/municipality/'
export const DELETE_MUNICIPALITY_URL = '/municipality/'
export const RESTORE_MUNICIPALITY_URL = '/municipality/restore/'

// planting urls
export const CREATE_PLANTING_URL = '/planting'
export const GET_PLANTING_URL = '/planting/'
export const GET_PLANTINGS_URL = '/planting'
export const GET_PLANTINGS_SECTIONS = '/planting/planting-sections'
export const UPDATE_PLANTING_URL = '/planting/'
export const DELETE_PLANTING_URL = '/planting/'
export const DELETE_PLANTING_PHOTO_URL = '/planting/photo'

// sections
export const GET_SECTIONS_URL = '/section'
export const CREATE_SECTION_URL = '/section'
export const UPDATE_SECTION_URL = '/section/'
export const DELETE_SECTION_URL = '/section/'

//borders
export const CREATE_BORDER_URL = '/border'
export const GET_BORDERS_URL = '/border'
export const UPDATE_BORDER_URL = '/border/'
export const DELETE_BORDER_URL = '/border/'

// statistic
export const GET_PROJECT_WITHOUT_SCHEMA_DATA_URL = '/project/check-schema'

// features urls
export const IMPORT_URL = '/import'
export const EXPORT_URL = '/export'

//croc urls
export const UPDATE_IMAGE_ON_CROC_URL = '/croc/update-image'
export const COPY_IMAGE_ON_CROC_AND_DB_URL = '/croc/copy-image'
export const CREATE_IMAGE_ON_CROC_AND_DB_URL = '/croc/create-image'
export const UPLOAD_ICON_FOR_MAP_URL = '/croc/upload-icon-for-map'

//user actions history urls
export const WRITE_USER_HISTORY_URL = '/user-actions-history/pog'
export const GET_USER_ACTIONS_HISTORY_URL = '/user-actions-history'

//algorithms
export const DELETE_PHOTOS_DUPLICATES_URL = '/algorithms/delete-photos-duplicates'
export const COPY_SCHEMA_URL = '/algorithms/copy-schema'
export const CHECK_SEQUENCES_URL = '/algorithms/run/sequence'

//replace
export const REPLACE_SECTION_BY_FILE_URL = '/exchange/replace/section'
export const REPLACE_BORDER_BY_FILE_URL = '/exchange/replace/border'