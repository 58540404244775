import React, { FC, useState } from 'react';
import { DictionaryType } from "../../models/_admin/DictionaryType.type";
import Loader from "../UI/Loader/Loader";
import styles from "./dictionaryStyles.module.scss";
import { Stack } from "@mui/material";
import { russifier } from "./dictionary";
import { useDictionary } from "./UseDictionary";
import { DataGrid, ruRU } from "@mui/x-data-grid";
import CreateDictionaryDialog from "./dialogs/CreateDictionaryDialog";

interface Props {
  type: DictionaryType
}

const Dictionaries: FC<Props> = ({ type }) => {
  const [isLoading, setIsLoading] = useState(false);

  const toggleIsLoading = (v: boolean) => {
    setIsLoading(v);
  }

  const { columns, rows, refetch } = useDictionary(type, toggleIsLoading, isLoading)

  if (!rows) {
    return <Loader/>
  }

  return (
    <div className={styles.wrapper}>
      <Stack spacing={1}>
        <div className={styles.title}>
          <h1>Управление {russifier[type]?.control}</h1>

          <CreateDictionaryDialog refetch={refetch} type={type}/>
        </div>

        <div className={styles.table_wrapper}>
          <DataGrid
            columns={columns}
            rows={rows}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          />
        </div>
      </Stack>
    </div>
  );
};

export default Dictionaries;