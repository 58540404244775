import React, { FC, useState } from 'react';
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import Icon from "../../UI/Icons/Icon";
import LoadingButton from "@mui/lab/LoadingButton";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { russifier } from "../dictionary";
import { DictionaryType } from "../../../models/_admin/DictionaryType.type";

interface Props {
  type: DictionaryType
  params: GridRenderCellParams
  onEdit: any
  onRemove: any
  isLoading: boolean
}

const DictionaryActions: FC<Props> = ({ type, params, onEdit, onRemove, isLoading }) => {
  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      name: params.row.name,
    }
  })

  const [open, setOpen] = useState({ edit: false, delete: false })

  const toggleEdit = () => {
    setOpen(prevState => ({ ...prevState, edit: !prevState.edit }))
  }
  const toggleDelete = () => {
    setOpen(prevState => ({ ...prevState, delete: !prevState.delete }))
  }

  const onEditDictionary = (dto: any) => {
    onEdit({ ...dto, id: params.row.id, t: type })
    toggleEdit()
    reset()
  }

  const onDeleteDictionary = (dto: any) => {
    onRemove(params.row.id, dto)
    toggleDelete()
    reset()
  }

  return (
    <>
      <Dialog open={open.delete} onClose={toggleDelete}>
        <DialogTitle>
          Удаление {russifier[type].delete}
        </DialogTitle>

        <DialogContent>
          <Typography variant={'h6'}>
            Вы действительно хотите удалить {russifier[type].delete}?
          </Typography>

          <DialogContentText>
            После удаления {russifier[type].edit} восстановить будет невозможно
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={toggleDelete} autoFocus>
            Отмена
          </Button>

          <Button onClick={onDeleteDictionary}>
            Удалить
          </Button>
        </DialogActions>
      </Dialog>

      <IconButton onClick={toggleDelete}>
        <Icon icon={'delete'} color={'#ff0000'}/>
      </IconButton>

      <Dialog open={open.edit} onClose={toggleEdit}>
        <DialogTitle>
          Обновление {russifier[type].edit}
        </DialogTitle>

        <DialogContent>
          <Stack spacing={2} padding={1}>
            <TextField {...register('name')} label={'Название'}/>
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton onClick={toggleEdit} autoFocus loading={isLoading}>
            Отмена
          </LoadingButton>
          <LoadingButton onClick={handleSubmit(onEditDictionary)}>
            Обновить
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <IconButton onClick={toggleEdit}>
        <Icon icon={'edit'} color={'#ff5b00'}/>
      </IconButton>
    </>
  )
};

export default DictionaryActions;