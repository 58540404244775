import React, { FC } from 'react';
import TableCell from '@mui/material/TableCell';
import { showInfoField } from '../../../helpers/showInfoField';
import PermissionsGate from '../../../helpers/permissions/PermissionsGate';
import { SCOPES } from '../../../helpers/permissions/permissionsMaps';
import styles from './table.module.scss';
import { Link } from 'react-router-dom';
import { Confirm } from 'react-st-modal';
import Icon from '../../../components/UI/Icons/Icon';
import { IHeader } from "../../../models/IHeader";

interface ITableCellsProps {
  cells: {
    [key: string]: string | number
  }
  keysWithWidth: IHeader[]
  onDetails: Function
  onDelete: Function
  editUrl: string
}

const TableCells: FC<ITableCellsProps> = ({
                                                cells,
                                                keysWithWidth,
                                                onDetails,
                                                onDelete,
                                                editUrl,
                                              }) => {
  const cellSize = {
    small: styles.small_width_col,
    medium: styles.medium_width_col,
    large: styles.large_width_col
  }

  const handleDelete = async ({ id, objectNumber }: { id: number, objectNumber: number }) => {
    const result = await Confirm(
      'Вы уверены что хотите удалить?',
      'Удаление',
      'Удалить',
      'Отмена'
    )

    if (!result) {
      return
    }

    onDelete({ id, objectNumber })
  }

  return (
    <>
      {keysWithWidth.map(({ fieldName, width, permissions }) =>
        <PermissionsGate key={fieldName} scopes={permissions}>
          <TableCell align={'center'} className={cellSize[width]}>
            {showInfoField(cells[fieldName], { dateWithoutTime: true })}
          </TableCell>
        </PermissionsGate>
      )}

      <TableCell align={'center'} padding={'none'} width={'2rem'}>
        <Icon
          icon={'info'}
          className={styles.icon}
          onClick={() => onDetails(cells.id)}
        />
      </TableCell>

      <PermissionsGate scopes={[SCOPES.canEdit]}>
        <TableCell align={'center'} padding={'none'} width={'2rem'}>
          <Link to={editUrl + cells.id}>
            <Icon
              icon={'edit'}
              color={'#ff5b00'}
              className={styles.icon}
            />
          </Link>
        </TableCell>

        <TableCell align={'center'} padding={'none'} width={'2rem'}>
          <Icon
            icon={'delete'}
            color={'#ff0000'}
            className={styles.icon}
            onClick={() => handleDelete({
              id: Number(cells.id),
              objectNumber: cells.monumentNumber ? Number(cells.monumentNumber) : Number(cells.plantingNumber)
            })}
          />
        </TableCell>
      </PermissionsGate>
    </>
  );
};

export default TableCells;