import React, { FC, SyntheticEvent, useState } from 'react';
import { Box, Tab, Table, TableBody, Tabs, Typography } from "@mui/material";
import CustomTabPanel from "../../../components/UI/CustomTabPanel/CustomTabPanel";
import { StyledTableCell, StyledTableRow } from "../../../components/UI/StyledTable/StyledTable";
import styles from "./info.module.scss";
import { showInfoField } from "../../../helpers/showInfoField";
import Loader from "../../../components/UI/Loader/Loader";

interface Props {
  statistic: any[]
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Statistic: FC<Props> = ({ statistic }) => {
  const [value, setValue] = useState(0)

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  if(!statistic?.length) {
    return <Loader/>
  }

  return (
    <Box sx={{ width: '100%', marginTop: '1rem' }}>
      <Typography variant={'h6'}>Статистика</Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'center' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {statistic.map((s, i) => (
            <Tab key={i} label={s.label} {...a11yProps(i)} />
          ))}
        </Tabs>
      </Box>

      {statistic.map((s, i) => (
        <CustomTabPanel value={value} index={i} key={i}>
          <Table size={'small'} aria-label="purchases">
            <TableBody>
              {Object.entries(s.value ?? {}).map(([key, v]) => (
                <StyledTableRow key={key}>
                  <StyledTableCell className={styles.cell_none_border}>
                    {showInfoField(key)}
                  </StyledTableCell>

                  <StyledTableCell className={styles.cell_none_border}>
                    {showInfoField(v)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </CustomTabPanel>
      ))}
    </Box>
  );
};

export default Statistic;