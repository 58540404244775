import {ls_getProjectInfo} from "../../../helpers/localStorage";
import React, {useMemo, useState} from "react";
import {useRecoilState} from "recoil";
import {mapStateAtom} from "../../../store/mapStore";
import {notExistingPhotosAtom} from "../../../store/notExistingPhotosStore";
import {useCustomMutation} from "../../../api/useCustomMutation";
import {IPlanting, IPlantingSection} from "../../../models/IPlanting";
import {GET_DATA_FOR_MAP_BY_SECTIONS_URL, GET_DATA_FOR_MAP_URL, GET_PLANTING_URL,} from "../../../api/urls";
import {toast} from "react-toastify";
import styles from "./mapLayout.module.scss";
import Icon from "../../../components/UI/Icons/Icon";
import {PLANTING_PAGE} from "../../../router/routeConsts";

interface IMapData {
  borders: any[],
  sections: IPlantingSection[],
  plantingsWithPhotos: IPlanting[],
  plantingsWithoutPhotos: IPlanting[],
  plantingsWithoutSections: IPlanting[],
  sectionsCenters: number[],
}

const useMapLayout = () => {
  const projectInfo = ls_getProjectInfo()
  const [mapData, setMapData] = useState<IMapData | undefined>(undefined)
  const [mapState, setMapState] = useRecoilState(mapStateAtom)
  const [notExisingPhotos] = useRecoilState(notExistingPhotosAtom)
  const mutation = useCustomMutation()

  const onLowerPlanting = () => {
    setMapState(prev => ({
      ...prev,
      selectedObject: null,
      preSelectedObject: {id: prev.selectedObject.lowerId, type: "common"}
    }))
  }

  const onGreaterPlanting = () => {
    setMapState(prev => ({
      ...prev,
      selectedObject: null,
      preSelectedObject: {id: prev.selectedObject.greaterId, type: "common"}
    }))
  }

  const onGoToPlanting = (id: number) => {
    if (!Number.isInteger(Number(id)) || id < 0) {
      toast.error('id насаждения может быть только целым положительным числом')
      return
    }

    setMapState(prev => ({
      ...prev,
      selectedObject: null,
      preSelectedObject: {id: Number(id), type: "common"}
    }))
  }

  const onCopy = async (textToCopy: string) => {
    try {
      await navigator.clipboard.writeText(textToCopy)

      toast.success('Скопировано')
    } catch (e: any) {
      console.log(e)
      toast.error(e.message)
    }
  }

  const onShowInfo = () => {
    toast(
      (
        <div className={styles.info_wrapper}>
          <p>Чтобы отобразить насаждения сектора, выберите его нажав на него на карте, либо наведите мышку на</p>

          <div className={styles.add_icon}>
            <Icon
              icon={"add"}
              colorClassProp={styles.white_icon_color}
            />
          </div>

          и нажмите на нужный сектор.

          <p>
            Для того, чтобы убрать насаждения сектора, нажмите на него или его номер ещё раз.
          </p>

          <div className={styles.warning_text}>
            Внимание!!! Для сохранения быстрой работы карты, не выбирайте много секторов.
          </div>
        </div>
      ), {autoClose: 30000}
    )
  }

  const onAddRemoveSelectedSection = (value: string) => {
    if (!value) {
      return
    }

    if (value === 'all-sections') {
      if (!mapData) {
        return
      }

      if (mapState.selectedSectionsNumbers.length === mapData.sections.length) {
        setMapState((prev) => ({...prev, selectedSectionsNumbers: []}))
      } else {
        setMapState((prev) => ({...prev, selectedSectionsNumbers: mapData.sections.map((s) => s.sectionNumber)}))
      }

      return
    }

    if (value === 'remove-all-sections') {
      setMapState((prev) => ({...prev, selectedSectionsNumbers: [prev.selectedSectionsNumbers[0]]}))
      return
    }

    if (mapState.selectedSectionsNumbers.includes(value)) {
      setMapState((prev) => ({
        ...prev,
        selectedSectionsNumbers: mapState.selectedSectionsNumbers.filter((A) => A !== value)
      }))
    } else {
      setMapState((prev) => ({
        ...prev,
        selectedSectionsNumbers: [...mapState.selectedSectionsNumbers, value]
      }))
    }
  }

  const onShowSection = (sNumber: string | undefined) => setMapState((prev) => ({
    ...prev,
    preSelectedSectionNumber: sNumber
  }))

  const loadPlantingInfo = () => {
    if (!mapState.preSelectedObject) {
      return
    }

    switch (mapState.preSelectedObject.type) {
      case "common":
      case "plantingWithoutSection":
        mutation.mutateAsync({
          method: 'get',
          url: GET_PLANTING_URL + mapState.preSelectedObject.id + `?schema=${projectInfo?.schema}`
        })
          .then(({data}) => {
            setMapState(prev =>
              ({...prev, selectedObject: {...data.planting, lowerId: data.lowerId, greaterId: data.greaterId}}))
          })
          .catch(({response}) => {
            setMapState(prev =>
              ({...prev, preSelectedObject: null}))
            // toast.error(response?.data?.message)
          })
        break
      default:
        console.log(mapState.preSelectedObject)
    }
  }

  const loadMapData = () => {
    if (!mapData?.borders) {
      mutation.mutateAsync({
        method: 'get',
        url: GET_DATA_FOR_MAP_URL + `?schema=${projectInfo?.schema}`
      }).then(({data}) => {
        setMapData(data.mapData)

        if (mapState.schema !== projectInfo.schema) {
          setMapState((prev) => ({
            ...prev,
            schema: projectInfo.schema,
            selectedSectionsNumbers: [data.mapData.sections[0]?.sectionNumber]
          }))
        } else {
          setMapState((prev) => ({
            ...prev,
            schema: projectInfo.schema,
            selectedSectionsNumbers: prev.selectedSectionsNumbers?.length ? [...prev.selectedSectionsNumbers] : [data.mapData.sections[0]?.sectionNumber],
          }))
        }

        if (mapState.selectedObject && !mapState.selectedSectionsNumbers.includes(mapState.selectedObject.sectionNumber)) {
          onAddRemoveSelectedSection(mapState.selectedObject.sectionNumber)
        }
      })
    } else {
      mutation.mutateAsync({
        method: 'patch',
        data: {
          schema: projectInfo?.schema,
          sectionsNumbers: mapState.selectedSectionsNumbers
        },
        url: GET_DATA_FOR_MAP_BY_SECTIONS_URL
      }).then(({data}) => {
        setMapData((prev) => ({...prev, ...data}))
      })
    }

    if (mapState.needReFetch) {
      setMapState((prev) => ({
        ...prev,
        needReFetch: false,
      }))
    }
  }

  const onClose = () => {
    setMapState(prev => ({
      ...prev,
      selectedObject: null,
      preSelectedObject: null,
    }))
  }

  const onCloseSelectFinger = () => {
    setMapState((prev) => ({
      ...prev,
      selectFinger: {isOn: false, plantingArray: [], sectionsArray: []}
    }))
  }

  const memoizedPlantingsWithPhotos = useMemo(
    () => mapData?.plantingsWithPhotos
      .map((planting: any) => ({
        ...planting,
        pathOptions: {color: 'rgb(20,128,236)'}
      })),
    [mapData?.plantingsWithPhotos]
  )

  const memoizedPlantingsWithoutPhotos = useMemo(
    () => mapData?.plantingsWithoutPhotos
      .map((planting: any) => ({
        ...planting,
        pathOptions: {color: 'rgb(200,0,255)'}
      })),
    [mapData?.plantingsWithoutPhotos]
  )

  const memoizedPlantingsWithoutSections = useMemo(
    () => mapData?.plantingsWithoutSections
      .map((planting: any) => ({
        ...planting,
        pathOptions: {color: 'rgb(255,0,0)'}
      })),
    [mapData?.plantingsWithoutSections]
  )

  const plantingsWithoutPhotoOnCroc = useMemo(
    () => notExisingPhotos.plantings.map(({planting}) => ({...planting})),
    [notExisingPhotos.plantings]
  )

  return {
    projectInfo,
    mapData,
    plantingsWithoutPhotoOnCroc,
    memoizedBorders: mapData?.borders,
    memoizedSections: mapData?.sections,
    memoizedPlantingsWithPhotos,
    memoizedPlantingsWithoutPhotos,
    memoizedPlantingsWithoutSections,
    notExisingPhotos,
    onClose,
    loadPlantingInfo,
    onAddSection: onAddRemoveSelectedSection,
    loadMapData,
    onShowSection,
    onShowInfo,
    onCloseSelectFinger,
    onCopy,
    onLowerPlanting,
    onGreaterPlanting,
    onGoToPlanting
  }
}

export default useMapLayout;