import React, { useState } from 'react';
import styles from '../styles.module.scss';
import { toast } from "react-toastify";
import { Card, CardActions, CardContent, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useCustomMutation } from "../../api/useCustomMutation";
import { CHECK_SEQUENCES_URL, WRITE_USER_HISTORY_URL } from "../../api/urls";
import SelectProject from "../SelectProject/SelectProject";
import CustomCardHeader from "../UI/MyCardHeader/CustomCardHeader";
import { algorithmsTitles } from "../../utils/utils";

const CheckSequencesInDb = () => {
  const mutation = useCustomMutation()
  const [project, setProject] = useState<any>()

  const onProjectClick = (project: any) => {
    setProject(project)
  }

  const onSubmit = async () => {
    try {

      let changesDescription = {
        action: 'algorithm',
        objectId: project.id,
        data: [{algorithmName: 'CheckSequencesInDb'}]
      } as any

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: project.schema,
          changesDescription,
          actionObject: 'project'
        },
        url: WRITE_USER_HISTORY_URL
      })

      let currentSchema = project.schema

      const { data } = await mutation.mutateAsync({
        method: 'get',
        url: CHECK_SEQUENCES_URL + `?schema=${currentSchema}`
      })

      toast.success(data.message)
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  const onInfoClick = () => {
    toast(
      <>
        Проверка последовательностей в схеме проекта.
      </>
    )
  }

  return (
    <Card elevation={6} className={styles.card}>

      <CustomCardHeader title={algorithmsTitles.CheckSequencesInDb} onInfoClick={onInfoClick}/>

      <CardContent>
        <div>
          <h3>{project?.name}</h3>
        </div>
      </CardContent>

      <CardActions>
        <Stack direction={'row'} spacing={3}>
          <SelectProject onProjectClick={onProjectClick}/>

          <LoadingButton
            variant={'contained'}
            loading={mutation.isLoading}
            onClick={onSubmit}
          >
            Начать проверку
          </LoadingButton>
        </Stack>
      </CardActions>
    </Card>
  );
};

export default CheckSequencesInDb;