import React, {FC} from 'react';
import styles from './NavigateBetweenPlantingsStyles.module.scss';
import {Button, Stack, TextField} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import Icon from "../UI/Icons/Icon";

interface Props {
  onGoTo: (id: number) => void,
  onForward?: () => void,
  onBack?: () => void,
  wrapperClassName?: string
}

const NavigateBetweenPlantings: FC<Props> = ({onBack, wrapperClassName, onForward, onGoTo}) => {
  const form = useForm<{ id: number }>()

  const goTo = (form: { id: number }) => {
    onGoTo(form.id)
  }

  return (
    <div className={wrapperClassName ?? styles.navigate_between_plantings__wrapper}>
      <Button variant={'outlined'} disabled={!onBack} onClick={onBack}>
        Предыдущее
      </Button>

      <Stack direction={'row'} spacing={2}>
        <Controller
          name={'id'}
          control={form.control}
          render={({field}) =>
            <TextField
              size={'small'}
              {...field}
              label={'ID насаждения:'}
            />
          }
        />

        <Button variant={'outlined'} onClick={form.handleSubmit(goTo)}><Icon icon={"search"}/></Button>
      </Stack>

      <Button variant={'outlined'} disabled={!onForward} onClick={onForward}>
        Следующее
      </Button>
    </div>
  );
};

export default NavigateBetweenPlantings;