import { atom, selector } from 'recoil';
import { IProject } from '../models/IProject';
import { IPlantingSection } from '../models/IPlanting';
import { ITablePlanting } from '../models/ITablePlanting';
import { plantingsKeysWithWidth } from "../pages/project/plantings/usePlantings";

export const projectsState = atom({
  key: 'projects-state',
  default: {
    projects: [] as IProject[],
  }
})

export const currentProjectAtom = atom({
  key: 'current-project-state',
  default: undefined as IProject | undefined
})

export const plantingStateAtom = atom({
  key: 'planting-state',
  default: {
    section: [] as IPlantingSection[]
  }
})

export const plantingsStateAtom = atom<ITablePlanting>({
  key: 'plantings-state',
  default: {
    offset: 0,
    all: 0,
    count: 100,
    searchParams: {},
    sortedParams: { plantingNumber: 'ASC' },
    currentKeys: plantingsKeysWithWidth
  }
})

export const projectsSelector = selector({
  key: 'projects-selector',
  get: ({ get }) => {
    return get(projectsState)
  }
})

export const currentProjectSelector = selector({
  key: 'current-project-selector',
  get: ({ get }) => {
    return get(currentProjectAtom)
  }
})