import React, { FC, useState } from 'react';
import { useRecoilState } from "recoil";
import { UserActionsHistoryState } from "../../../../../store/userActionsHistoryStore";
import { Box, IconButton, Table, TableBody, TableFooter, TableHead, TablePagination, TableRow } from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../../../../components/UI/StyledTable/StyledTable";
import { showInfoField } from "../../../../../helpers/showInfoField";
import UserActionsHistoryDescriptionCell from "./UserActionsHistoryDescriptionCell";
import { actionObjectType, IUserActionsHistory } from "../../../../../models/IUserActionsHistory";
import TableContainer from "@mui/material/TableContainer";
import { PLANTING_PAGE } from "../../../../../router/routeConsts";
import { Link } from "react-router-dom";
import { algorithmsTitles } from "../../../../../utils/utils";
import Icon from "../../../../../components/UI/Icons/Icon";
import { useTheme } from "@mui/material/styles";
import styles from '../userActionsStatistic.module.scss';

interface Props {
  actionsHistoryProp?: IUserActionsHistory[]
}

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

export function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <Icon icon={'doubleArrow'}/> :
          <Icon icon={'doubleArrow'} className={styles.transform__icon}/>}
      </IconButton>

      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <Icon icon={'arrowBack'}/> : <Icon icon={'arrowBack'}/>}
      </IconButton>

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <Icon icon={'arrowBack'}/> :
          <Icon icon={'arrowBack'} className={styles.transform__icon}/>}
      </IconButton>

      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <Icon icon={'doubleArrow'}/> : <Icon icon={'doubleArrow'}/>}
      </IconButton>
    </Box>
  );
}

const ShowUserActionsHistory: FC<Props> = ({ actionsHistoryProp = [] }) => {
  const [actionsHistoryFromState] = useRecoilState(UserActionsHistoryState)
  let actionsHistory = actionsHistoryFromState
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  if (actionsHistoryProp?.length) {
    actionsHistory = actionsHistoryProp
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => setPage(newPage);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0);
  };

  const getObjectNameOnRussian = (objName: actionObjectType) => {
    switch (objName) {
      case "project":
        return 'Проект'
      case "planting":
        return 'Насаждение'
      default:
        return 'Ошибка'
    }
  }

  const getCellWithActionObject = (row: IUserActionsHistory) => {
    if (row.actionObject !== 'project') {
      if (row.changesDescription.action === 'delete') {
        if (!row.changesDescription.objectNumber) {
          return <p>{getObjectNameOnRussian(row.actionObject)} с id: {row.changesDescription.objectId}</p>
        } else {
          return <p>{getObjectNameOnRussian(row.actionObject)} с номером: {row.changesDescription.objectNumber}</p>
        }
      } else {
        let link;

        switch (row.actionObject) {
          case "planting":
            link = PLANTING_PAGE + row.changesDescription.objectId
            break
          default:
            link = PLANTING_PAGE
        }

        if (!row.changesDescription.objectNumber) {
          return (
            <Link to={link}>
              {getObjectNameOnRussian(row.actionObject)} с id: {row.changesDescription.objectId}
            </Link>
          )
        } else {
          return (
            <Link to={link}>
              {getObjectNameOnRussian(row.actionObject)} с номером: {row.changesDescription.objectNumber}
            </Link>
          )
        }
      }
    } else {
      return <p>{getObjectNameOnRussian(row.actionObject)}</p>
    }
  }

  const getActionNameOnRussian = (row: IUserActionsHistory) => {
    if (row.actionObject === "project") {
      // @ts-ignore
      return algorithmsTitles[row.changesDescription.data[0].algorithmName]
    }

    switch (row.changesDescription.action) {
      case 'edit':
        return 'Редактирование'
      case 'delete':
        return 'Удаление'
      case 'delete_photo':
        return 'Удаление фото'
      case 'create_photo':
        return 'Добавление фото'
      default:
        return 'Ошибка'
    }
  }

  if (!actionsHistory.length) {
    return <></>
  }

  return (
    <TableContainer>
      <Table sx={{ width: '100rem' }}>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>
              Пользователь
            </StyledTableCell>

            <StyledTableCell>
              Объект действия
            </StyledTableCell>

            <StyledTableCell>
              Действие
            </StyledTableCell>

            <StyledTableCell>
              Дата и время действия
            </StyledTableCell>

            <StyledTableCell>
              Описание
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>

        <TableBody>
          {(rowsPerPage > 0
              ? actionsHistory.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : actionsHistory
          ).map((row) => (
            <StyledTableRow>
              <StyledTableCell>
                <p>
                  {row.user.lastname + ' '}
                  {row.user.firstname + ' '}
                  {row.user.patronymic + ' '}
                </p>
                <p>{row.user.email}</p>
              </StyledTableCell>

              <StyledTableCell>
                <p>{getCellWithActionObject(row)}</p>
              </StyledTableCell>

              <StyledTableCell>
                {getActionNameOnRussian(row)}
              </StyledTableCell>

              <StyledTableCell>
                {showInfoField(row.dateCreated, { dateWithoutTime: false })}
              </StyledTableCell>

              <StyledTableCell>
                <UserActionsHistoryDescriptionCell
                  action={row.changesDescription.action}
                  description={row.changesDescription}
                  entity={row.actionObject}
                />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'Всё', value: -1 }]}
              colSpan={3}
              count={actionsHistory.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default ShowUserActionsHistory;