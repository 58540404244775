import React, { useState } from 'react';
import styles from './userActionsStatistic.module.scss';
import SelectProject from "../../../../components/SelectProject/SelectProject";
import { Card, CardContent, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { ls_getProjectInfo, ls_setProjectInfo } from "../../../../helpers/localStorage";
import { useCustomMutation } from "../../../../api/useCustomMutation";
import { GET_USER_ACTIONS_HISTORY_URL } from "../../../../api/urls";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { UserActionsHistoryState } from "../../../../store/userActionsHistoryStore";
import ShowUserActionsHistory from "./userActionsStatisticComponents/ShowUserActionsHistory";

const UserActionsStatistic = () => {
  const [project, setProject] = useState<any>(ls_getProjectInfo())
  const [actionObject, setActionObject] = useState<string | undefined>(undefined)
  const [actionsHistory, setActionsHistory] = useRecoilState(UserActionsHistoryState)
  const mutation = useCustomMutation()

  const actionObjects = [
    { value: 'all', key: 'Все' },
    { value: 'planting', key: 'Насаждение' },
    { value: 'project', key: 'Проект' },
  ]

  const onSelectProject = (project: any) => {
    setProject(project)
    ls_setProjectInfo(project)
  }

  const onChangeActionObject = (e: any) => {
    setActionObject(e.target.value === 'all' ? undefined : e.target.value)
  }

  const onSubmit = async () => {
    try {
      const query = actionObject ? `?schema=${project.schema}&actionObject=${actionObject}` : `?schema=${project.schema}`
      const { data } = await mutation.mutateAsync({
        method: "get",
        url: GET_USER_ACTIONS_HISTORY_URL + query
      })
      setActionsHistory(data);
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  return (
    <div className={styles.user_actions__wrapper}>
      <Card className={styles.user_actions__card}>
        <CardContent className={styles.user_actions__form}>
          <SelectProject onProjectClick={onSelectProject}/>

          <h3>Проект: {project ? project.name : 'Не выбран'}</h3>

          <FormControl className={styles.user_actions__form__select}>
            <InputLabel id="actionsObject">Выберите объект</InputLabel>

            <Select
              labelId="actionsObject"
              label={'Выберите объект'}
              onChange={(e) => onChangeActionObject(e)}
              defaultValue={'all'}
            >
              {actionObjects.map((obj) => (
                <MenuItem key={obj.value} value={obj.value}>
                  {obj.key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <LoadingButton
            loading={mutation.isLoading}
            variant={'outlined'}
            onClick={onSubmit}
          >
            Загрузить
          </LoadingButton>
        </CardContent>
      </Card>

      {actionsHistory.length ?
        <Card>
          <CardContent>
            <ShowUserActionsHistory/>
          </CardContent>
        </Card>
        :
        <></>
      }
    </div>
  );
};

export default UserActionsStatistic;