import {axiosInstance} from "../axios"
import { GET_ALL_DELETED_MUNICIPALITIES_URL, GET_MUNICIPALITIES_URL } from "../urls"


export const getMunicipalities = () => {
    return axiosInstance.get(GET_MUNICIPALITIES_URL)
}

export const getDeletedMunicipalities = () => {
  return axiosInstance.get(GET_ALL_DELETED_MUNICIPALITIES_URL)
}