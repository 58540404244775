import React, { createRef, useMemo, useState } from 'react';
import { useRecoilState } from "recoil";
import { mapStateAtom } from "../../../../../store/mapStore";
import { useCustomMutation } from "../../../../../api/useCustomMutation";
import * as turf from "@turf/turf";
import { ls_getProjectInfo } from "../../../../../helpers/localStorage";
import {
  UPDATE_BORDER_URL,
  UPDATE_PLANTING_URL,
  UPDATE_SECTION_URL
} from "../../../../../api/urls";
import { toast } from "react-toastify";
import styles from "../customButtons.module.scss";
import Icon from "../../../../../components/UI/Icons/Icon";

const UseMovePlantingButtons = () => {
  const [mapState, setMapState] = useRecoilState(mapStateAtom)
  const mutation = useCustomMutation()
  const [points, setPoints] = useState<undefined | number[][]>()
  const [step, setStep] = useState<{ angle: number, distance: number }>({ angle: 5, distance: 50 })
  const [elRefs, setElRefs] = useState([])

  const onSelect = (geometry: any) => {
    setPoints(geometry.coordinates)
    setElRefs((elRefs) =>
      Array(1)
        .fill('')
        .map((_, i) => elRefs[i] || createRef()),
    )
  }

  const onDragEnd = (newPoint: any) => {
    let newGeometry = {
      type: 'Point',
      coordinates:
        [newPoint.lng, newPoint.lat]
    }

    setMapState((prev) => ({ ...prev, selectedObject: { ...mapState.selectedObject, geometry: newGeometry } }))
  }

  const eventHandlers = useMemo(
    () => ({
      dragend(e: any) {
        // @ts-ignore
        const marker = elRefs[0].current
        if (marker != null) {
          // @ts-ignore
          onDragEnd(marker.getLatLng())
        }
      },
    }),
    [points],
  )

  const onArrowClick = (direction: 'left' | 'right' | 'top' | 'bottom') => {
    let angle = 0;

    switch (direction) {
      case "bottom":
        angle = 180
        break
      case "top":
        break
      case "left":
        angle = 270
        break
      case "right":
        angle = 90
        break
    }

    const geometry = turf.transformTranslate(mapState.selectedObject.geometry, step.distance, angle, { units: 'centimeters' })

    const newPlanting = {
      ...mapState.selectedObject,
      geometry,
    }

    setMapState((prev) => ({ ...prev, selectedObject: newPlanting }))
  }

  const onSave = async () => {
    try {
      const schema = ls_getProjectInfo().schema

      if (mapState.preSelectedObject?.type === 'section') {
        await mutation.mutateAsync({
          method: 'patch',
          data: {
            schema,
            geometry: mapState.selectedObject.geometry
          },
          url: UPDATE_SECTION_URL + mapState.preSelectedObject.id
        })

        toast.success('Изменения сохранены, перезагрузите страницу, чтобы увидеть их')
        return
      }

      if (mapState.preSelectedObject?.type === 'border') {
        await mutation.mutateAsync({
          method: 'patch',
          data: {
            schema,
            geometry: mapState.selectedObject.geometry
          },
          url: UPDATE_BORDER_URL + mapState.preSelectedObject.id
        })

        toast.success('Изменения сохранены, перезагрузите страницу, чтобы увидеть их')
        return
      }

      await mutation.mutateAsync({
        method: 'patch',
        data: {
          schema,
          geometry: mapState.selectedObject.geometry
        },
        url: UPDATE_PLANTING_URL + mapState.selectedObject.id,
      })

      if (!mapState.needReFetch) {
        setMapState((prev) => ({
          ...prev,
          needReFetch: true,
        }))
      }

      toast.success('Изменения сохранены')

    } catch (e: any) {
      console.log(e)
      toast.error(
        <div>
          <p>{e.response?.data?.error}</p>
          <p>{e.response?.data?.message}</p>
        </div>
      )
    }
  }

  const onKeyDown = (e: KeyboardEvent) => {
    if (!mapState.selectedObject || mapState.selectedObject.editDescription) {
      return
    }

    switch (e.code) {
      case 'KeyA':
        onArrowClick('left')
        break
      case 'KeyS':
        onArrowClick('bottom')
        break
      case 'KeyD':
        onArrowClick('right')
        break
      case 'KeyW':
        onArrowClick('top')
        break
      case 'Escape':
        setMapState((prev) => ({ ...prev, selectedObject: null, preSelectedObject: null }))
        break
      case 'ArrowRight':
        onChangeStep('angle', 1)
        break
      case 'ArrowLeft':
        onChangeStep('angle', -1)
        break
      case 'ArrowUp':
        onChangeStep('distance', 1)
        break
      case 'ArrowDown':
        onChangeStep('distance', -1)
        break
      case 'ShiftLeft':
        onChangeStep('shift', -1)
        break
      case 'ShiftRight':
        onChangeStep('shift', -1)
        break
      default:
        break
    }
  }

  const onInfo = () => {
    toast((
      <div>
        <p>
          Чтобы передвигать объект используйте кнопки
        </p>

        <div className={styles.info_icons_wrapper}>
          <Icon icon={"arrowBack"}/>
          <Icon icon={"arrowBack"} className={styles.arrow_top}/>
          <Icon icon={"arrowBack"} className={styles.arrow_right}/>
          <Icon icon={"arrowBack"} className={styles.arrow_bottom}/>
        </div>

        либо клавиши WASD

        <p>
          Чтобы крутить насаждение используйте кнопки
        </p>

        <div className={styles.info_icons_wrapper}>
          <Icon icon={"rotate"}/>
          <Icon icon={"rotate"} className={styles.mirroring}/>
        </div>

        либо клавиши QE

        <p>
          Чтобы сбросить изменения нажмите Escape, либо на крестик в окне справа внизу
        </p>

        <p>
          Чтобы изменить дистанцию передвижения, нажимайте клавиши ↑ ↓
        </p>

        <p>
          Чтобы изменить угол поворота, нажимайте клавиши ← →
        </p>

        <p>
          Чтобы быстро изменить угол поворота и дистанцию передвижения, нажимайте клавишу Shift
        </p>

        <p>
          Перетаскивайте маркеры, чтобы изменять крайние точки
        </p>

        <p>
          Чтобы сохранить изменения нажмите зелёную кнопку слева экрана
        </p>

        <Icon icon={"save"}/>
      </div>
    ), { autoClose: 30000 })
  }

  const onChangeStep = (entity: 'angle' | 'distance' | 'shift', direction: -1 | 1) => {
    if (entity === 'angle' && step.angle <= 1 && direction === -1) {
      toast.error('Нельзя задать угол меньше 1')
      return
    }

    if (entity === 'distance' && step.distance <= 1 && direction === -1) {
      toast.error('Нельзя задать передвижение меньше 1')
      return
    }

    const newStep = step

    if (entity === 'shift') {
      if (step.angle !== 1 || step.distance !== 10) {
        newStep.angle = 1
        newStep.distance = 10
      }
    } else {
      newStep[entity] += direction
    }

    setStep(newStep)

    toast.success(
      <div>
        <p>Текующие значения:</p>
        <p>Передвижения: {step.distance}см</p>
        <p>Поворот: {step.angle}°</p>
      </div>
    )
  }

  return { onArrowClick, onSave, onInfo, onKeyDown, onSelect, points, mapState, eventHandlers, elRefs }
};

export default UseMovePlantingButtons;