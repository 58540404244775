import { DictionaryType } from "../../models/_admin/DictionaryType.type";
import { axiosInstance } from "../axios";
import { GET_ALL_DICTIONARY_URL, GET_DICTIONARY_URL } from "../urls";

export const getDictionary = async (t: DictionaryType) => {
  return await axiosInstance.get(GET_DICTIONARY_URL + `?t=${t}`)
}

export const getAllDictionary = async () => {
  return await axiosInstance.get(GET_ALL_DICTIONARY_URL)
}