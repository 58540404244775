// packages
import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
// hooks
import { useCustomMutation } from '../../../api/useCustomMutation'
// urls
import { CREATE_DICTIONARY_URL } from '../../../api/urls'
import { DictionaryType } from "../../../models/_admin/DictionaryType.type";
import { russifier } from "../dictionary";

interface Props {
  refetch: any,
  type: DictionaryType
}

const CreateDictionaryDialog: FC<Props> = ({ refetch, type }) => {
  const mutation = useCustomMutation()
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const toggleOpen = () => {
    setOpen(!open);
  }

  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      name: '',
    }
  })

  const onCancel = () => {
    reset()
    toggleOpen()
  }

  const onCreateProject = (dto: any) => {
    setIsLoading(true)

    mutation.mutateAsync({
      method: 'post',
      data: { ...dto, t: type },
      url: CREATE_DICTIONARY_URL
    })
      .then(({ data }: AxiosResponse) => {
        toast.success(data.message)
        toggleOpen()
        reset()
        refetch()
        setIsLoading(false)
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
        setIsLoading(false)
      })
  }

  return (
    <>
      <Button onClick={toggleOpen} variant={'outlined'}>
        Создать {russifier[type].delete}
      </Button>

      <Dialog open={open} onClose={toggleOpen}>
        <DialogTitle>
          Создание округа
        </DialogTitle>

        <DialogContent>
          <TextField {...register('name')} label={'Название'}/>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            onClick={onCancel}
            autoFocus
            loading={isLoading}
          >
            Отмена
          </LoadingButton>

          <LoadingButton
            onClick={handleSubmit(onCreateProject)}
            loading={isLoading}
          >
            Cоздать
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreateDictionaryDialog
