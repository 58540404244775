import React, { FC, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';
import Icon from '../Icons/Icon';
import Loader from "../Loader/Loader";

interface Props {
  currentObject: 'project' | 'area' | 'municipality' | 'user'
  params: any
  onRemove: (id: number) => void
  onRestore: (id: number) => void
  isLoading: boolean
}

const DeleteOrRestoreButtons: FC<Props> = ({
                                             currentObject,
                                             params,
                                             onRestore,
                                             onRemove,
                                             isLoading
                                           }) => {


  const [isConfirm, setIsConfirm] = useState(false)

  const notDeleted = 'Не удалён'

  const handleOpenAlert = () => setIsConfirm(true)
  const handleCloseAlert = () => setIsConfirm(false)

  const text = {
    'project': ['проекта', 'проект'],
    'area': ['объекта', 'объект'],
    'municipality': ['округа', 'округ'],
    'user': ['пользователя', 'пользователя']
  }

  const onAgree = () => {
    if (params.row.dateDeleted === notDeleted) {
      onRemove(params.row.id)
    } else {
      onRestore(params.row.id)
    }

    handleCloseAlert()
  }

  if (isLoading) {
    return <Loader/>
  }

  if (params.row.dateDeleted === notDeleted) {
    return (
      <>
        <Dialog open={isConfirm} onClose={handleCloseAlert}>
          <DialogTitle>
            Удаление {text[currentObject][0]}
          </DialogTitle>

          <DialogContent>

            <Typography variant={'h6'}>
              Вы действительно хотите удалить {text[currentObject][1]}?
            </Typography>

            <DialogContentText>
              После удаления {text[currentObject][0]} запись в базе данных о нем останется, и его можно будет
              восстановить в любой момент.
            </DialogContentText>

          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseAlert} autoFocus>
              Отмена
            </Button>

            <Button onClick={onAgree}>
              Удалить
            </Button>
          </DialogActions>
        </Dialog>

        <IconButton onClick={handleOpenAlert}>
          <Icon icon={'delete'} color={'#ff0000'}/>
        </IconButton>
      </>
    )
  }

  return (
    <>
      <IconButton onClick={handleOpenAlert}>
        <Icon icon={'restore'} color={'#ff0000'}/>
      </IconButton>

      <Dialog open={isConfirm} onClose={handleCloseAlert}>
        <DialogTitle>
          Восстановление {text[currentObject][0]}
        </DialogTitle>

        <DialogContent>
          <Typography variant={'h6'}>
            Вы действительно хотите восстановить {text[currentObject][1]}?
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseAlert} autoFocus>
            Отмена
          </Button>

          <Button onClick={onAgree}>
            Восстановить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
};

export default DeleteOrRestoreButtons;