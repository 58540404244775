import React from 'react';
import { useQuery } from 'react-query';
import { getPlantingById } from '../../../../api/project/projectApi';
import { plantingDto } from '../usePlantings';
import { useCustomMutation } from '../../../../api/useCustomMutation';
import { UPDATE_PLANTING_URL, WRITE_USER_HISTORY_URL } from '../../../../api/urls';
import { toast } from 'react-toastify';
import { ls_getProjectInfo } from '../../../../helpers/localStorage';
import { NO_INFO } from '../../../../utils/utils';
import { useNavigate } from "react-router-dom";
import { showInfoField } from "../../../../helpers/showInfoField";
import { getAllDictionary } from "../../../../api/dictionary/dictionaryApi";
import * as turf from '@turf/turf';

const useMapPlanting = (id: number) => {
  const mutation = useCustomMutation()
  const projectInfo = ls_getProjectInfo()
  const navigate = useNavigate()

  const cancel = () => navigate(-1)

  const {
    data: plantingResponse,
    refetch: plantingRefetch,
    isLoading: plantingIsLoading,
  } = useQuery([`planting/${id}`, id], () => getPlantingById(id))

  const { data: dictionary, isLoading: dictionaryIsLoading } = useQuery(['dictionary-all'], getAllDictionary)

  const searchDirty = (data: any) => {
    let dirty = {} as any
    for (const { fieldName, type } of plantingDto) {
      if (type === 'bool') {
        if (showInfoField(data[fieldName]) !== showInfoField(plantingResponse?.data.planting[fieldName])) {
          dirty[fieldName] = true
        }
        continue
      }

      if (fieldName === 'geometry') {
        continue
      }

      if (data[fieldName] !== showInfoField(plantingResponse?.data.planting[fieldName], { dateWithoutTime: true })) {
        dirty[fieldName] = true
      }
    }

    return dirty;
  }

  const onSubmit = async (data: any) => {
    const numberKeys = [
      'plantingNumber',
      'height',
      'age',
      'trunkCount'
    ]

    const dirty = searchDirty(data)

    for (const { fieldName } of plantingDto) {
      if (!dirty[fieldName] && fieldName !== 'id') {
        delete data[fieldName];
        continue;
      }

      if (data[fieldName] === NO_INFO || data[fieldName] === '') {
        data[fieldName] = null
      }
    }

    if (data['geometry']) {
      const coordinates = [Number(data['geometry'].split(',')[0]), Number(data['geometry'].split(',')[1])]
      data['geometry'] = turf.point(coordinates).geometry
    }

    for (const [key] of Object.entries(data)) {
      if (numberKeys.includes(key)) {
        if (data[key] === '') {
          data[key] = null;
        } else {
          data[key] = Number(data[key]);
        }
      }
    }

    data.schema = projectInfo.schema

    let changesDescription = {
      action: 'edit',
      objectId: data.id,
      data: []
    } as any

    for (const [key, _] of Object.entries(dirty)) {
      changesDescription.data.push({
        fieldName: key,
        oldData: plantingResponse?.data.planting[key],
        newData: data[key]
      })
    }

    if (!Object.entries(changesDescription.data).length) {
      toast.error('Вы ничего не изменяли')
      return
    }

    try {
      const { data: editData } = await mutation.mutateAsync({
        method: 'patch',
        data: data,
        url: UPDATE_PLANTING_URL + data.id,
      })

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: projectInfo.schema,
          changesDescription,
          actionObject: 'planting'
        },
        url: WRITE_USER_HISTORY_URL
      })

      toast.success(editData.message)
      await plantingRefetch()

    } catch (e: any) {
      console.log(e)

      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  return {
    isLoading: plantingIsLoading || dictionaryIsLoading,
    planting: plantingResponse?.data.planting,
    onSubmit,
    plantingRefetch,
    cancel,
    dictionary: dictionary?.data,
    projectInfo,
  }
};

export default useMapPlanting;