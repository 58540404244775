import React, { FC, useEffect } from 'react';
import { TableVirtuoso } from 'react-virtuoso'
import TableHeaders from '../table/TableHeaders';
import TableCells from '../table/TableCells';
import { useTable } from '../table/useTable';
import { useRecoilState } from 'recoil';
import { plantingsStateAtom } from '../../../store/projectStore';
import { useNavigate } from 'react-router-dom';
import { EDIT_PLANTING_PAGE, PLANTING_PAGE } from '../../../router/routeConsts';
import { useCustomMutation } from '../../../api/useCustomMutation';
import { DELETE_PLANTING_URL, WRITE_USER_HISTORY_URL } from '../../../api/urls';
import { toast } from 'react-toastify';
import { Button, Stack, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { getPlantings } from '../../../api/project/projectApi';
import Loader from '../../../components/UI/Loader/Loader';
import { useForm } from 'react-hook-form';
import { ls_getProjectInfo } from '../../../helpers/localStorage';
import cn from 'classnames';
import styles from './plantings.module.scss';
import Icon from '../../../components/UI/Icons/Icon';
import { plantingTablesHeaders } from "./usePlantings";
import { ITableSettings } from "../../../models/ITableSettings";

const Plantings: FC = () => {
  const [plantingsState, setPlantingsState] = useRecoilState(plantingsStateAtom)
  const { count, searchParams, all, offset, sortedParams, currentKeys } = plantingsState

  const {
    data: response,
    isLoading,
    refetch,
    error
  } = useQuery(
    [offset, searchParams, sortedParams],
    () => getPlantings(offset, count, searchParams, sortedParams),
    { retry: false }
  )

  const form = useForm()
  const mutation = useCustomMutation()
  const navigate = useNavigate()
  const projectInfo = ls_getProjectInfo()
  const { configMUITable } = useTable()

  const toFirstPage = () => {
    setPlantingsState(prev => ({
      ...prev,
      offset: 0
    }))
  }

  const toLastPage = () => {
    if (all - count < 1) {
      toast.error('Вы уже на последней странице')
      return
    }

    setPlantingsState(prev => ({
      ...prev,
      offset: all - count
    }))
  }

  const onNext = () => {
    if (all - count > offset) {
      setPlantingsState(prev => ({
        ...prev,
        offset: prev.offset + count
      }))
    }
  }

  const onPrev = () => {
    if (offset >= count) {
      setPlantingsState(prev => ({
        ...prev,
        offset: prev.offset - count
      }))
    }
  }

  const onSearch = (query: any) => {
    setPlantingsState(prev => ({
      ...prev,
      searchParams: query,
      offset: 0
    }))
  }

  const onSort = (key: any) => {
    let param = 'ASC'

    if (plantingsState.sortedParams[key]) {
      if (plantingsState.sortedParams[key] === 'ASC') {
        param = 'DESC'
      }
    }

    const query = { [key]: param }

    setPlantingsState(prev => ({
      ...prev,
      sortedParams: query
    }))
  }

  const onReset = () => {
    setPlantingsState(prev => ({
      ...prev,
      searchParams: {},
      sortedParams: { plantingNumber: 'ASC' }
    }))
    form.reset()
  }

  const toDetails = (id: number) => {
    navigate(PLANTING_PAGE + id)
  }

  const onDelete = ({ id, objectNumber }: { id: number, objectNumber: number }) => {
    mutation.mutateAsync({
      method: 'delete',
      url: DELETE_PLANTING_URL + id + `?schema=${projectInfo.schema}`
    })
      .then(({ data }) => {

        const changesDescription = {
          objectId: id,
          action: 'delete',
          objectNumber
        }

        mutation.mutateAsync({
          method: "post",
          data: {
            schema: projectInfo.schema,
            changesDescription,
            actionObject: 'planting'
          },
          url: WRITE_USER_HISTORY_URL
        })

        toast.success(data.message)
        refetch()
      })
      .catch(({ response }) => {
        toast.error(response?.data.message)
        toast.error(response?.data.error)
      })
  }

  const tableSettings: ITableSettings = {
    allKeys: plantingTablesHeaders,
    currentKeys: currentKeys,
    onChangeKeys: keys => {
      setPlantingsState((prev) => ({ ...prev, currentKeys: keys }))
    }
  }

  useEffect(() => {
    if (error) {
      toast.error(
        <p>
          {/*@ts-ignore*/}
          {error.response?.data.message}
          {/*@ts-ignore*/}
          {error.response?.data.error}
        </p>
      )
    }
  }, [error])

  useEffect(() => {
    setPlantingsState(prev => ({
      ...prev,
      all: response?.data.all
    }))
  }, [response?.data.all, setPlantingsState])

  if (!projectInfo?.schema) {
    return <h2>Загрузите проект</h2>
  }

  if (!error && (isLoading || !response?.data.plantings)) {
    return <Loader/>
  }

  return (
    <div className={styles.plantings__container}>
      <TableVirtuoso
        className={styles.custom_scroll}
        style={{ height: 'calc(100vh - 17rem)' }}
        data={error ? [] : response?.data.plantings}
        totalCount={error ? 0 : response?.data.plantings.length}
        components={configMUITable}
        fixedHeaderContent={() =>
          <TableHeaders
            form={form}
            onSearch={onSearch}
            onReset={onReset}
            onSorted={onSort}
            sortedParams={plantingsState.sortedParams}
            disabledHeaders={['sectionNumber', 'countPhotos']}
            tableSettings={tableSettings}
          />}
        itemContent={(index, planting) =>
          <>
            {
              !response?.data.plantings?.length
                ? <div>Ничего не найдено</div>
                : <TableCells
                  cells={{ ...planting, countPhotos: planting.photos?.length || 0 }}
                  keysWithWidth={currentKeys}
                  onDetails={toDetails}
                  onDelete={onDelete}
                  editUrl={EDIT_PLANTING_PAGE}
                />
            }
          </>}
      />

      <Stack direction={'row'} justifyContent={"space-between"}>
        <Stack direction={'row'} spacing={4} justifyContent={'flex-end'} alignItems={'center'} padding={2}>
          <Typography>с {offset} по {count + offset > all ? all : count + offset} из {all}</Typography>

          <Icon icon={'doubleArrow'} className={cn(styles.page_icon, styles.first_page_icon)} onClick={toFirstPage}/>

          <Button onClick={onPrev} variant={'contained'}>Предыдущая</Button>
          <Button onClick={onNext} variant={'contained'}>Следующая</Button>

          <Icon icon={'doubleArrow'} className={cn(styles.page_icon, styles.last_page_icon)} onClick={toLastPage}/>
        </Stack>
      </Stack>
    </div>
  );
};

export default React.memo(Plantings);