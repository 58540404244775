import { useRecoilState } from "recoil";
import { currentProjectAtom } from "../../../store/projectStore";
import { showDate } from "../../../helpers/showDate";
import React from "react";

interface ProjectTableData {
  label: string,
  value: string | number | JSX.Element | undefined
}

export const useInfo = () => {
  const [project] = useRecoilState(currentProjectAtom)
  const mainData: ProjectTableData[] = [
    { label: 'Название объекта: ', value: project?.area?.name },
    { label: 'Дата создания: ', value: showDate(project?.dateCreated) },
    { label: 'Схема объекта: ', value: project?.schema }
  ]

  const earthData: ProjectTableData[] = [
    { label: 'Площадь в границах объекта:', value: project?.projectData?.objectArea + ' м2' },
    { label: 'Количество насаждений', value: project?.projectData?.plantingsCount },
    { label: 'Кол-во секторов', value: project?.projectData?.sectionsCount },
    { label: 'Кол-во границ', value: project?.projectData?.bordersCount },
  ]

  const statistic = [
    { label: 'Виды', value: project?.projectData?.statistic?.kind, },
    { label: 'Жизненные формы', value: project?.projectData?.statistic?.lifeForm, },
    { label: 'Состояния', value: project?.projectData?.statistic?.condition, },
    { label: 'Род', value: project?.projectData?.statistic?.genus, }
  ]

  return {
    mainData,
    earthData,
    statistic
  }
}