import React, { FC, useMemo } from 'react';
import { useQuery } from "react-query";
import { ls_getProjectInfo } from "../../../helpers/localStorage";
import { getDataForMiniMap } from "../../../api/project/projectApi";
import { FeatureGroup, LayersControl, Polygon } from "react-leaflet";
import PlantingWithIconMarker from "../../PlantingWithIconMarker/PlantingWithIconMarker";
import { booleanWithin, circle, point } from "@turf/turf";
import { EDIT_PLANTING_PAGE, PLANTING_PAGE } from "../../../router/routeConsts";
import { useNavigate } from "react-router-dom";
import { IPlanting } from "../../../models/IPlanting";
import MiniMapTileLayers from "./MiniMapTileLayers";

interface Props {
  idPlanting: number
}

const MiniMapObjects: FC<Props> = ({ idPlanting }) => {
  const project = ls_getProjectInfo()
  const {
    data,
    isLoading,
  } = useQuery([idPlanting, project?.schema], () => getDataForMiniMap(idPlanting, project?.schema))
  const navigate = useNavigate()
  const plantingHandlers = useMemo(
    () => ({
      async click(e: any) {
        const fill = data?.data.plantingsWithPhotos.find((p: IPlanting) => {
          const pCircle = circle(p.geometry, 1, { steps: 10, units: 'centimeters' })
          return booleanWithin(point([e.latlng.lng, e.latlng.lat]), pCircle.geometry)
        })

        if (fill) navigate((window.location.href.includes('edit') ? EDIT_PLANTING_PAGE : PLANTING_PAGE) + fill.id + '?common')
      },
    }),
    [data?.data.plantingsWithPhoto, isLoading]
  )

  if (!data) {
    return <></>
  }

  return (
    <LayersControl position="bottomright" collapsed={false}>
      <MiniMapTileLayers/>

      <LayersControl.Overlay name={'границы'} checked={true}>
        <FeatureGroup>
          {data.data.borders.map((border: any) => (
            <Polygon
              key={border.uuid}
              positions={[border.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]}
              stroke={true}
              pathOptions={{ color: 'rgb(20,128,236)', }}
            />
          ))}
        </FeatureGroup>
      </LayersControl.Overlay>

      <LayersControl.Overlay name={'секторы'} checked={true}>
        <FeatureGroup>
          {data.data.sections.map((section: any) => (
            <Polygon
              key={section.uuid}
              positions={[section.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]}
              stroke={true}
              pathOptions={{ color: 'rgb(20,128,236)', }}
            />
          ))}
        </FeatureGroup>
      </LayersControl.Overlay>

      <LayersControl.Overlay name={'Насаждения'} checked={true}>
        <FeatureGroup>
          {data.data.plantingsWithPhotos.map((planting: any) => (
            <PlantingWithIconMarker
              key={planting.uuid}
              planting={planting}
              markerProps={{ eventHandlers: plantingHandlers }}
            />
          ))}
        </FeatureGroup>
      </LayersControl.Overlay>
    </LayersControl>
  )
};

export default MiniMapObjects;
