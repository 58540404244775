import React, { FC, useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import styles from './selectProject.module.scss';
import Loader from "../UI/Loader/Loader";
import { useQuery } from "react-query";
import { getProjectsWithPermissions } from "../../api/project/projectApi";
import { ls_getProjectInfo } from "../../helpers/localStorage";
import { OverridableStringUnion } from "@mui/types";
import { ButtonPropsVariantOverrides } from "@mui/material/Button/Button";

interface Props {
  onProjectClick: (project: any) => void
  needFirstSelect?: boolean,
  variant?: OverridableStringUnion<
    'text' | 'outlined' | 'contained',
    ButtonPropsVariantOverrides
  >;
}

const SelectProject: FC<Props> = ({ onProjectClick, needFirstSelect = true, variant = 'outlined' }) => {
  const { data: projectsData } = useQuery('projects_with_permissions', getProjectsWithPermissions)
  const [open, setOpen] = useState(false)
  const [preValue, setPreValue] = useState('')
  const [projects, setProjects] = useState<any[]>()

  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  const onChange = (e: any) => {
    if (!e.target.value) {
      setProjects(projectsData)
      setPreValue(e.target.value);
      return
    }

    if (e.target.value.length < preValue.length) {
      const arr = projectsData?.filter((project) => project.name.toLowerCase().includes(e.target.value.toLowerCase()))
      setProjects(arr)
      setPreValue(e.target.value);

      return;
    }

    const arr = projects?.filter((project) => project.name.toLowerCase().includes(e.target.value.toLowerCase()))
    setProjects(arr)

    setPreValue(e.target.value);
  }

  useEffect(() => {
    if (projectsData) {
      setProjects(projectsData.sort((A, B) => A.name.localeCompare(B.name)))

      if (needFirstSelect) {
        onProjectClick(ls_getProjectInfo() ? ls_getProjectInfo() : projectsData[0])
      }
    }
  }, [projectsData])

  if (!projects) {
    return <Loader/>
  }

  return (
    <div>
      <Button
        className={styles.select__button}
        onClick={onOpen}
        variant={variant}
      >
        Выбрать проект
      </Button>

      <Dialog open={open} onClose={onClose} maxWidth={'xs'}>
        <DialogTitle>
          Выберите проект:
        </DialogTitle>

        <DialogContent>
          <div className={styles.dialog_wrapper}>
            <TextField
              className={styles.search__input}
              label={'Поиск'}
              onChange={(e) => onChange(e)}
            />

            {projects ?
              <div className={styles.projects_wrapper}>
                {projects.map((project) => (
                  <div key={project.schema} className={styles.project__item}>
                    <Button onClick={() => {
                      onClose()
                      onProjectClick(project)
                    }}>
                      {project.name}
                    </Button>
                  </div>
                ))}
              </div>
              :
              <Loader/>
            }
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SelectProject;