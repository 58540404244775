import React from 'react';
import cn from "classnames";
import styles from "./customButtons.module.scss";
import Icon from "../../../../components/UI/Icons/Icon";
import { useRecoilState } from "recoil";
import { mapStateAtom } from "../../../../store/mapStore";

const SelectFinger = () => {
  const [mapState, setMapState] = useRecoilState(mapStateAtom)

  const onClick = () => {
    if (!mapState.selectFinger.isOn) {
      setMapState((prev) => ({ ...prev, selectFinger: { ...mapState.selectFinger, isOn: true } }))
    } else {
      setMapState((prev) => ({
        ...prev,
        selectFinger: { isOn: false, plantingArray: [], sectionsArray: [] }
      }))
    }
  }

  return (
    <div
      className={cn('leaflet-top', 'leaflet-left', mapState.ruler.isOn ? styles.select_finger_ruler_is_on : styles.select_finger_container)}>
      <div onClick={onClick} className={cn('leaflet-control', 'leaflet-bar', styles.custom_btn)}>
        <Icon icon={"selectFinger"}/>
      </div>
    </div>
  )
};

export default SelectFinger;