import React, { FC, useRef, useState } from 'react';
import {
  COPY_IMAGE_ON_CROC_AND_DB_URL,
  CREATE_IMAGE_ON_CROC_AND_DB_URL,
  UPDATE_IMAGE_ON_CROC_URL, WRITE_USER_HISTORY_URL
} from "../../../api/urls";
import { toast } from "react-toastify";
import { CustomDialog } from "react-st-modal";
import CustomConfirmDeleteDialog from "../CustomConfirmDialog/CustomConfirmDeleteDialog";
import { useParams } from "react-router-dom";
import { useCustomMutation } from "../../../api/useCustomMutation";
import { useForm } from "react-hook-form";
import { ls_getProjectInfo } from "../../../helpers/localStorage";
import styles from "./editPhotos.module.scss";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";
import Icon from "../Icons/Icon";
import LoadingButton from "@mui/lab/LoadingButton";
import dayjs from "dayjs";

type EditForm = {
  image: FileList
}

type CopyForm = {
  id: number
  copyTo: string
}

interface Props {
  refetch: () => void
  photos: string[]
  deleteUrl: string
  uuid: string
}

const EditPhotos: FC<Props> = ({ refetch, photos, deleteUrl, uuid }) => {
  const mutation = useCustomMutation()
  const { id } = useParams()
  const editForm = useForm<EditForm>()
  const copyForm = useForm<CopyForm>({ defaultValues: { copyTo: 'plantings', id: 0 } })

  const projectInfo = ls_getProjectInfo()
  const imageUrl = `${projectInfo.hostingUrl + projectInfo.imageFolder}/`
  const [isOpen, setIsOpen] = useState<'create' | 'edit' | 'copy' | 'close'>('close')
  const photoRef = useRef('photo')

  const onClose = () => setIsOpen('close')
  const onShowCreate = () => setIsOpen('create')

  const onShowCopy = (photo: string) => {
    photoRef.current = photo
    setIsOpen('copy')
  }

  const onShowEdit = (photo: string) => {
    photoRef.current = photo
    setIsOpen('edit')
  }

  const onCopy = async (form: CopyForm) => {
    try {
      const [relativePath, fileName] = photoRef.current.split('/')

      const { data } = await mutation.mutateAsync({
        method: 'patch',
        url: COPY_IMAGE_ON_CROC_AND_DB_URL,
        data: {
          ...form,
          imageFolder: projectInfo.imageFolder,
          relativePath: relativePath,
          photo: fileName,
          schema: projectInfo.schema
        }
      })


      const changesDescription = {
        objectId: form.copyTo,
        action: 'edit',
        data: [{
          fieldName: 'photos',
          description: `copy photo from planting id: ${form.id}`,
          oldData: null,
          newData: fileName
        }]
      }

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: projectInfo.schema,
          changesDescription,
          actionObject: 'planting'
        },
        url: WRITE_USER_HISTORY_URL
      })


      refetch()
      toast.success(data.message)
      onClose()

    } catch (e: any) {
      toast.error(
        <p>
          {e.response?.data.message}
          {e.response?.data.error}
        </p>
      )
    }
  }

  const onEdit = async (form: EditForm) => {
    try {
      const [relativePath, fileName] = photoRef.current.split('/')

      const formData = new FormData()

      formData.set('image', form.image[0])
      formData.set('relativePath', relativePath)
      formData.set('fileName', fileName)
      formData.set('imageFolder', projectInfo.imageFolder)

      const { data } = await mutation.mutateAsync({
        method: 'patch',
        url: UPDATE_IMAGE_ON_CROC_URL,
        data: formData
      })

      const changesDescription = {
        objectId: id,
        action: 'edit',
        data: [{
          fieldName: 'photos',
          oldData: photoRef.current,
        }]
      }

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: projectInfo.schema,
          changesDescription,
          actionObject: 'planting'
        },
        url: WRITE_USER_HISTORY_URL
      })

      toast.success(<p>{data.message} Обновите страницу, чтобы увидеть изменения</p>)
      onClose()

    } catch (e: any) {
      toast.error(
        <p>
          {e.response?.data.message}
          {e.response?.data.error}
        </p>
      )
    }
  }

  const onDelete = async (photo: string) => {
    try {
      const result = await CustomDialog(
        <CustomConfirmDeleteDialog
          stringForConfirm={photo}
          okButtonText={'Удалить'}
          cancelButtonText={'Отмена'}
          title={'Вы уверены что хотите удалить фотографию? Востановить её будет невозможно'}
          body={
            <div>
              <p>Имя фото: <b>{photo}</b></p>
              <p>Для подтверждения действия введите имя фото в поле ниже</p>
            </div>
          }
        />
      )

      if (!result) return

      const { data } = await mutation.mutateAsync({
        method: 'delete',
        url: deleteUrl +
          `?photo=${photo}&id=${id}&schema=${projectInfo.schema}`,
      })

      const changesDescription = {
        objectId: id,
        action: 'delete_photo',
        data: [{
          fieldName: 'photos',
          photo
        }]
      }

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: projectInfo.schema,
          changesDescription,
          actionObject: 'planting'
        },
        url: WRITE_USER_HISTORY_URL
      })

      refetch()
      toast.success(data.message)

    } catch (e: any) {
      toast.error(
        <p>
          {e.response?.data.message}
          {JSON.stringify(e.response?.data.error)}
        </p>
      )
    }
  }

  const onCreate = async (form: EditForm) => {
    try {
      const formData = new FormData()
      let relativePath = 'plantings'

      const uploadTime = dayjs().format('DD_MM_YYYY_HH_MM_ss');
      const newName = `${uploadTime}__${uuid}__${id}.jpg`;

      formData.set('image', form.image[0])
      formData.set('relativePath', relativePath)
      formData.set('newName', newName)
      formData.set('imageFolder', projectInfo.imageFolder)
      formData.set('id', id as string)
      formData.set('schema', projectInfo.schema)

      const { data } = await mutation.mutateAsync({
        method: 'post',
        data: formData,
        url: CREATE_IMAGE_ON_CROC_AND_DB_URL
      })

      const changesDescription = {
        objectId: id,
        action: 'create_photo',
        data: [{
          fieldName: 'photos',
        }]
      }

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: projectInfo.schema,
          changesDescription,
          actionObject: 'planting'
        },
        url: WRITE_USER_HISTORY_URL
      })

      refetch()
      toast.success(data.message)
      onClose()
    } catch (e: any) {
      toast.error(
        <p>
          {e.response?.data.message}
          {JSON.stringify(e.response?.data.error)}
        </p>
      )
    }
  }

  return (
    <Stack className={styles.right__content} spacing={2}>
      <Box className={styles.planting_img_wrapper}>
        {photos &&
          photos.map((photo: any) =>
            <div key={photo} className={styles.image_with_icons}>
              <img src={imageUrl + photo.toLowerCase()} alt={`photo: ${imageUrl + photo}`}/>

              <div className={styles.images_icons}>
                <Icon icon={'copy'} onClick={() => onShowCopy(photo)}/>
                <Icon icon={'edit'} onClick={() => onShowEdit(photo)}/>
                <Icon icon={'delete'} onClick={() => onDelete(photo)}/>
              </div>
            </div>
          )}
      </Box>

      <div className={styles.buttons}>
        <Button
          variant={'contained'}
          onClick={onShowCreate}
          color={'info'}
        >
          Добавить фото
        </Button>
      </div>

      {isOpen === 'edit' &&
          <Dialog open={!!isOpen} onClose={onClose}>
              <DialogTitle>Выберите фото для замены</DialogTitle>

              <DialogContent>
                  <Stack spacing={1}>
                      <Input
                          type="file"
                          color="info"
                          {...editForm.register('image', { required: true })}
                      />
                  </Stack>
              </DialogContent>

              <DialogActions>
                  <Button
                      variant={'contained'}
                      onClick={onClose}
                  >
                      Отмена
                  </Button>

                  <LoadingButton
                      loading={mutation.isLoading}
                      variant={'contained'}
                      onClick={editForm.handleSubmit(onEdit)}
                      color={'warning'}
                  >
                      Потдвердить
                  </LoadingButton>
              </DialogActions>
          </Dialog>}

      {isOpen === 'copy' &&
          <Dialog open={!!isOpen} onClose={onClose}>
              <DialogTitle>Выберите куда скопировать фото</DialogTitle>

              <DialogContent>
                  <Stack spacing={3} padding={2}>
                      <TextField
                        {...copyForm.register('id', { required: true, valueAsNumber: true })}
                        label={'ID насаждения'}
                        size={'small'}
                      />
                  </Stack>
              </DialogContent>

              <DialogActions>
                  <Button
                      variant={'contained'}
                      onClick={onClose}
                  >
                      Отмена
                  </Button>

                  <LoadingButton
                      loading={mutation.isLoading}
                      variant={'contained'}
                      onClick={copyForm.handleSubmit(onCopy)}
                      color={'success'}
                  >
                      Копировать
                  </LoadingButton>
              </DialogActions>
          </Dialog>}

      {isOpen === 'create' &&
          <Dialog open={!!isOpen} onClose={onClose}>
              <DialogTitle>Выберите фото</DialogTitle>

              <DialogContent>
                  <Stack spacing={1}>
                      <Input
                          type="file"
                          color="info"
                          {...editForm.register('image', { required: true })}
                      />
                  </Stack>
              </DialogContent>

              <DialogActions>
                  <Button
                      variant={'contained'}
                      onClick={onClose}
                  >
                      Отмена
                  </Button>

                  <LoadingButton
                      loading={mutation.isLoading}
                      variant={'contained'}
                      onClick={editForm.handleSubmit(onCreate)}
                      color={'success'}
                  >
                      Потдвердить
                  </LoadingButton>
              </DialogActions>
          </Dialog>}
    </Stack>
  );
};

export default EditPhotos;