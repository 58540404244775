import React from 'react';
import { Box } from '@mui/material';
import Navbar from '../../../components/UI/Navbar/Navbar';
import { Outlet } from 'react-router-dom';
import styles from './statistic.module.scss';
import { ADMIN_STATISTICS, ADMIN_USER_ACTIONS_STATISTIC, } from '../../../router/routeConsts';
import { SCOPES } from '../../../helpers/permissions/permissionsMaps';

const Statistics = () => {
  const routes = [
    { to: ADMIN_STATISTICS, label: 'Общая', scopes: [SCOPES.admin, SCOPES.superAdmin] },
    { to: ADMIN_USER_ACTIONS_STATISTIC, label: 'История действий пользователей', scopes: [SCOPES.admin, SCOPES.superAdmin] },
  ]

  return (
    <Box className={styles.wrapper}>
      <Navbar routes={routes} label={''}/>
      <Outlet/>
    </Box>
  )
};

export default Statistics;