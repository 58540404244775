import { IKeyWithWidth } from '../../../models/IKeysWithWidth';
import { IPlanting } from '../../../models/IPlanting';
import { IPlantingDto } from "../../../models/IPlantingDto";
import { SCOPES } from "../../../helpers/permissions/permissionsMaps";
import { IHeader } from "../../../models/IHeader";

export const plantingDto: IPlantingDto<IPlanting>[] = [
  {
    fieldName: 'id',
    label: 'ID насаждения:',
    disabled: true,
    type: 'text',
    nameInImportsFile: undefined,
    searchable: true,
    width: "small",
    permissions: [SCOPES.admin, SCOPES.superAdmin]
  },
  {
    fieldName: 'uuid',
    label: 'Uuid насаждения:',
    disabled: true,
    type: 'text',
    nameInImportsFile: 'uuid',
    searchable: true,
    width: "small",
    permissions: [SCOPES.admin, SCOPES.superAdmin]
  },
  {
    fieldName: 'plantingNumber',
    label: '№ насаждения:',
    disabled: true,
    type: 'text',
    nameInImportsFile: 'planting_number',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'sectionNumber',
    label: '№ сектора:',
    disabled: true,
    type: 'text',
    nameInImportsFile: undefined,
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'geometry',
    label: 'Координаты:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'geometry',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'age',
    label: 'Возраст:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'age',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'count',
    label: 'Кол-во:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'count',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'diameter',
    label: 'Диаметр:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'diameter',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'height',
    label: 'Высота:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'height',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'notes',
    label: 'Примечание:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'notes',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'trunkCount',
    label: 'Кол-во стволов:',
    disabled: false,
    type: 'text',
    nameInImportsFile: 'trunkCount',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'kind',
    label: 'Вид:',
    disabled: false,
    type: 'select',
    nameInImportsFile: 'kind',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'genus',
    label: 'Род:',
    disabled: false,
    type: 'select',
    nameInImportsFile: 'genus',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'lifeForm',
    label: 'Жизненная форма:',
    disabled: false,
    type: 'select',
    nameInImportsFile: 'life_form',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'condition',
    label: 'Состояние:',
    disabled: false,
    type: 'select',
    nameInImportsFile: 'condition',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'name',
    label: 'Имя:',
    disabled: false,
    type: 'select',
    nameInImportsFile: 'name',
    searchable: true,
    width: "small",
    permissions: []
  },
]

export const plantingTablesHeaders: IHeader[] = [
  {
    fieldName: 'id',
    label: 'ID насаждения:',
    searchable: true,
    width: "small",
    permissions: [SCOPES.admin, SCOPES.superAdmin]
  },
  {
    fieldName: 'uuid',
    label: 'Uuid насаждения:',
    searchable: true,
    width: "small",
    permissions: [SCOPES.admin, SCOPES.superAdmin]
  },
  {
    fieldName: 'plantingNumber',
    label: '№ насаждения:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'sectionNumber',
    label: '№ сектора:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'age',
    label: 'Возраст:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'count',
    label: 'Кол-во:',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'diameter',
    label: 'Диаметр',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'height',
    label: 'Высота',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'notes',
    label: 'Примечание',
    searchable: false,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'trunkCount',
    label: 'Кол-во стволов',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'kind',
    label: 'Вид',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'genus',
    label: 'Род',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'lifeForm',
    label: 'Жизненная форма',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'condition',
    label: 'Состояние',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'name',
    label: 'Имя',
    searchable: true,
    width: "small",
    permissions: []
  },
  {
    fieldName: 'countPhotos',
    label: 'Кол-во фото',
    searchable: false,
    width: "small",
    permissions: []
  },
]

export const plantingsKeysWithWidth: IKeyWithWidth[] = [
    {
      fieldName: 'id',
      searchable: true,
      label: 'ID насаждения:',
      width: 'small',
      permissions: [SCOPES.admin, SCOPES.superAdmin]
    },
    {
      fieldName: 'plantingNumber',
      searchable: true,
      label: '№ насаждения:',
      width: 'small'
    },
    {
      fieldName: 'sectionNumber',
      searchable: true,
      label: '№ сектора:',
      width: 'small'
    },
    {
      fieldName: 'countPhotos',
      searchable: true,
      label: 'Кол-во фото:',
      width: 'small'
    },
    {
      fieldName: 'age',
      searchable: true,
      label: 'Возраст:',
      width: 'small'
    },
    {
      fieldName: 'diameter',
      searchable: true,
      label: 'Диаметр:',
      width: 'small'
    },
    {
      fieldName: 'trunkCount',
      searchable: true,
      label: 'Кол-во стволов:',
      width: 'small'
    },
    {
      fieldName: 'name',
      searchable: true,
      label: 'Название:',
      width: 'medium'
    },
  ]