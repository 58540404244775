export const NO_INFO = 'н/д'

export enum algorithmsTitles {
  CopySchema = 'Копирование схемы',
  CheckSequencesInDb = 'Проверка ошибок в базе данных',
  DeletePhotosDuplicates = 'Удаление дублированных фотографий',
  ReplaceSection = 'Заменить сектор',
  ReplaceBorder = 'Заменить границу',
}

