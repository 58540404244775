import React, { useEffect } from 'react';
import { Marker, Polygon, Popup, useMapEvents } from "react-leaflet";
import { useRulerMarkers } from "./useRulerMarkers";
import Icon from "../../../../../components/UI/Icons/Icon";
import styles from "../customButtons.module.scss";
import { toast } from "react-toastify";

const RulerMarkers = () => {
  const { elRefs, onNewPoint, eventHandlers, mapState, setMapState } = useRulerMarkers()

  useMapEvents({
    dblclick(e) {
      if (mapState.ruler.isOn) {
        const points = [...mapState.ruler.pointsArray]

        if (points.length === 0) {
          points.push(e.latlng, e.latlng)
        } else {
          points.splice(points.length - 1, 0, e.latlng)
        }

        onNewPoint(points.length)

        setMapState(prev => {
          return { ...prev, ruler: { ...mapState.ruler, pointsArray: points } }
        })
      }
    }
  })

  const onCopy = async (textToCopy: string) => {
    try {
      await navigator.clipboard.writeText(textToCopy)

      toast.success('Координаты скопированы')
    } catch (e: any) {
      console.log(e)
      toast.error(e.message)
    }
  }

  useEffect(() => {
    elRefs.map((r: any, index) => {
      if (r?.current) {
        if (index === 0) {
          r.current._icon.src = 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-yellow.png'
        } else {
          r.current._icon.src = 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png'
        }
      }
    })
  }, [elRefs])

  if (!mapState.ruler.isOn || !mapState.ruler.pointsArray.length) {
    return <></>
  }

  return (
    <>
      {mapState.ruler.pointsArray.map((point: any, index: number) => {
        if (index !== mapState.ruler.pointsArray.length - 1)
          return (
            <Marker
              title={index.toString()}
              draggable={true}
              key={index}
              position={point}
              ref={elRefs[index]}
              eventHandlers={eventHandlers}
            >
              <Popup key={`popup-${index}`}>
                <p>Точка №{index + 1}</p>

                <div className={styles.ruler__popup_coordinates__wrapper}>
                  {`${point.lat.toFixed(6)}, ${point.lng.toFixed(6)}`}

                  <Icon
                    onClick={() => onCopy(`${point.lat.toFixed(6)}, ${point.lng.toFixed(6)}`)}
                    className={styles.ruler__coordinates__copy_btn}
                    icon={"copy"}
                  />
                </div>
              </Popup>
            </Marker>
          )
      })}

      <Polygon
        positions={mapState.ruler.pointsArray}
        pathOptions={{
          color: '#ff6900',
        }}
      />
    </>
  );
};

export default RulerMarkers;