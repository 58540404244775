import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Layout from '../pages/layout/Layout';
import SignIn from '../pages/authorization/SignIn/SignIn';
import Home from '../pages/home/Home';
import OverView from '../pages/overview/OverView';
import {
  ADMIN_CEMETERIES, ADMIN_CONDITIONS,
  ADMIN_FEATURES, ADMIN_GENUS, ADMIN_KINDS, ADMIN_LIFE_FORMS,
  ADMIN_MUNICIPALITIES, ADMIN_NAMES,
  ADMIN_OTHER_FEATURES,
  ADMIN_PROJECTS,
  ADMIN_STATISTICS,
  ADMIN_STUBS_FEATURES,
  ADMIN_USER_ACTIONS_STATISTIC,
  ADMIN_USERS,
  EDIT_PLANTING_PAGE,
  EDIT_PROFILE_PAGE,
  FORGOT_PASSWORD_PAGE,
  HOME_PAGE,
  IMPORT_FEATURES,
  MAP_PAGE,
  OVERVIEW_ADMIN_PAGE,
  OVERVIEW_PAGE,
  PLANTING_PAGE,
  PLANTINGS_PAGE,
  PROFILE_PAGE,
  PROJECTS_PAGE,
  RESET_PASSWORD_PAGE,
  SIGN_IN_PAGE
} from './routeConsts';
import { RequireAuth } from '../helpers/RequireAuth';
import Main from '../pages/main/Main';
import Profile from '../pages/profile/Profile';
import Project from '../pages/project/Project';
import Plantings from '../pages/project/plantings/Plantings';
import MapLayout from '../pages/project/map/MapLayout';
import PermissionsGate from '../helpers/permissions/PermissionsGate';
import { SCOPES } from '../helpers/permissions/permissionsMaps';
import Overview from '../pages/_admin/overview/OverView';
import Features from '../pages/_admin/features/Features';
import Planting from '../pages/project/plantings/Planting';
import Projects from '../pages/_admin/projects/Projects';
import Areas from '../pages/_admin/areas/Areas';
import Municipalities from '../pages/_admin/municipalities/Municipalities';
import Statistics from '../pages/_admin/statistics/Statistics';
import Users from '../pages/_admin/users/Users';
import EditPlantingForm from '../pages/project/plantings/editForm/EditPlantingForm';
import MainStatistic from '../pages/_admin/statistics/mainStatistic/MainStatistic';
import MainProfilePage from '../pages/profile/pages/MainProfilePage';
import EditProfilePage from '../pages/profile/pages/EditProfilePage';
import MainFeatures from '../pages/_admin/features/main/MainFeatures';
import ImportFeatures from '../pages/_admin/features/import/ImportFeatures';
import ResetPassword from "../pages/reset/ResetPassword";
import ForgotPassword from "../pages/authorization/ForgotPassword/ForgotPassword";
import OtherFeatures from "../pages/_admin/features/other/OtherFeatures";
import FindersMenu from "../pages/_admin/features/finders/FindersMenu";
import UserActionsStatistic from "../pages/_admin/statistics/userActionsStatistic/UserActionsStatistic";
import Dictionaries from "../components/DictionaryTable/Dictionaries";

const NotAccess = () =>
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <p style={{ fontSize: '2rem' }}>Такой страницы не существует</p>
    <p style={{ fontSize: '2rem' }}>Или у вас нет к ней доступа</p>
    <p style={{ fontSize: '2rem' }}>Можете обратиться к администрации <strong>Cloudmaps.</strong></p>
  </div>

type IRoute = { path: string, Element: any, scopes: string[] }
export const router = () => {
  const mainRoutes: IRoute[] = [
    { path: PROJECTS_PAGE, Element: Project, scopes: [] },
    { path: PLANTINGS_PAGE, Element: Plantings, scopes: [] },
    { path: PLANTING_PAGE + ':id', Element: Planting, scopes: [] },
    {
      path: EDIT_PLANTING_PAGE + ':id',
      Element: EditPlantingForm,
      scopes: [SCOPES.admin, SCOPES.superAdmin, SCOPES.superUser, SCOPES.editorUser, SCOPES.canEdit]
    },
    { path: MAP_PAGE, Element: MapLayout, scopes: [] },
  ];

  return (
    <Routes>
      <Route element={<Layout/>}>
        <Route element={<Home/>} path={HOME_PAGE}/>

        <Route element={<SignIn/>} path={SIGN_IN_PAGE}/>
        {/*<Route element={<SignUp/>} path={SIGN_UP_PAGE}/>*/}

        <Route element={<ResetPassword/>} path={`${RESET_PASSWORD_PAGE}*`}/>
        <Route element={<ForgotPassword/>} path={FORGOT_PASSWORD_PAGE}/>

        <Route element={<RequireAuth><Main/></RequireAuth>}>

          <Route element={<OverView/>} path={OVERVIEW_PAGE}>

            {mainRoutes.map(({ Element, scopes, path }) => (
              <Route
                key={path}
                path={path}
                element={
                  <PermissionsGate scopes={scopes} RenderError={() => <NotAccess/>}>
                    <Element/>
                  </PermissionsGate>
                }
              />
            ))}

          </Route>

          <Route
            element={
              <PermissionsGate
                scopes={[SCOPES.admin, SCOPES.superUser]}
                RenderError={() => <NotAccess/>}
              >
                <Overview/>
              </PermissionsGate>
            }
            path={OVERVIEW_ADMIN_PAGE}
          >

            <Route element={<Features/>} path={ADMIN_FEATURES}>
              <Route element={<MainFeatures/>} index/>
              <Route element={<ImportFeatures/>} path={IMPORT_FEATURES}/>
              <Route element={<FindersMenu/>} path={ADMIN_STUBS_FEATURES}/>
              <Route element={<OtherFeatures/>} path={ADMIN_OTHER_FEATURES}/>
            </Route>

            <Route element={<Projects/>} path={ADMIN_PROJECTS}/>
            <Route element={<Areas/>} path={ADMIN_CEMETERIES}/>
            <Route element={<Municipalities/>} path={ADMIN_MUNICIPALITIES}/>
            <Route element={<Users/>} path={ADMIN_USERS}/>
            <Route element={<Dictionaries type={"condition"}/>} path={ADMIN_CONDITIONS}/>
            <Route element={<Dictionaries type={"genus"}/>} path={ADMIN_GENUS}/>
            <Route element={<Dictionaries type={"kind"}/>} path={ADMIN_KINDS}/>
            <Route element={<Dictionaries type={"life_form"}/>} path={ADMIN_LIFE_FORMS}/>
            <Route element={<Dictionaries type={"name"}/>} path={ADMIN_NAMES}/>

            <Route element={<Statistics/>} path={ADMIN_STATISTICS}>
              <Route element={<MainStatistic/>} index/>
              <Route element={<UserActionsStatistic/>} path={ADMIN_USER_ACTIONS_STATISTIC}/>
            </Route>

          </Route>

          <Route element={<Profile/>} path={PROFILE_PAGE}>
            <Route element={<MainProfilePage/>} index/>
            <Route element={<EditProfilePage/>} path={EDIT_PROFILE_PAGE}/>
          </Route>
        </Route>

        <Route element={<NotAccess/>} path={`*`}/>
      </Route>
    </Routes>
  )
}