import React, { FC } from 'react';
import { IPlanting } from "../../models/IPlanting";
import { CircleMarker, Marker } from "react-leaflet";
import L, { PointExpression } from "leaflet";
import { MarkerProps } from "react-leaflet/lib/Marker";

interface Props {
  planting: IPlanting,
  markerProps?: Partial<MarkerProps>,
  color?: string,
  newIcon?: { iconUrl: string, iconSize: PointExpression }
}

const PlantingWithIconMarker: FC<Props> = ({ planting, newIcon, markerProps, color }) => {
  let circleColor = color ?? '#1480ec'
  if (newIcon) {
    const icon = new L.Icon({
      iconUrl: newIcon.iconUrl,
      iconRetinaUrl: newIcon.iconUrl,
      iconSize: newIcon.iconSize,
    })

    return (
      <Marker
        position={[planting.geometry.coordinates[1], planting.geometry.coordinates[0]]}
        icon={icon}
        {...markerProps}
      />
    )
  }

  if (!planting.iconOnMap)
    return <CircleMarker
      center={[planting.geometry.coordinates[1], planting.geometry.coordinates[0]]}
      pathOptions={{ color: circleColor }}
      {...markerProps}
    />

  const icon = new L.Icon({
    iconUrl: planting.iconOnMap,
    iconRetinaUrl: planting.iconOnMap,
    iconSize: planting.iconSizeOnMap,
  })

  return (
    <Marker
      position={[planting.geometry.coordinates[1], planting.geometry.coordinates[0]]}
      icon={icon}
      {...markerProps}
    />
  )
};

export default PlantingWithIconMarker;