import React, { FC, useState } from 'react';
import { useDialog } from "react-st-modal";
import styles from "./styles.module.scss";
import { Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";

interface Props {
  okButtonText?: string
  cancelButtonText?: string
  title: string
  body: string | JSX.Element
}

type objectType = 'section' | 'border'

interface IDialog {
  confirm: boolean,
  type: objectType,
}

const CustomCreatePlantingDialog: FC<Props> = ({ cancelButtonText = 'Отмена', okButtonText = 'Создать', title, body }) => {
  const dialog = useDialog<IDialog>()
  const [type, setType] = useState<objectType>('section')

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <span>{title}</span>
      </div>

      <div className={styles.body}>
        <div>{body}</div>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Создаваемый объект</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={type}
            label="Создаваемый объект"
            // @ts-ignore
            onChange={(e) => setType(e.target.value)}
          >
            <MenuItem value={'section'}>Сектор</MenuItem>
            <MenuItem value={'border'}>Граница</MenuItem>
          </Select>
        </FormControl>

        <Stack direction={'row'} spacing={2}>
          <Button
            variant={'contained'}
            onClick={() => {
              dialog.close({ confirm: true, type })
            }}
          >
            {okButtonText}
          </Button>

          <Button
            variant={'contained'}
            onClick={() => {
              dialog.close({ confirm: false, type})
            }}
          >
            {cancelButtonText}
          </Button>
        </Stack>
      </div>
    </div>
  );
};

export default CustomCreatePlantingDialog;