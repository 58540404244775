import React, {useEffect} from 'react';
import cn from "classnames";
import styles from "../customButtons.module.scss";
import Icon from "../../../../../components/UI/Icons/Icon";
import useMovePLantigButtons from "./useMovePlantingButtons";
import {Marker} from "react-leaflet";

const markeredObjects= ['border', 'section']

const MovePlantingButtons = () => {
  const {
    onKeyDown,
    onInfo,
    onSave,
    mapState,
    onArrowClick,
    onSelect,
    points,
    eventHandlers,
    elRefs
  } = useMovePLantigButtons()


  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)

    console.log(mapState.preSelectedObject)

    if (mapState.preSelectedObject && mapState.selectedObject && markeredObjects.includes(mapState.preSelectedObject?.type)) {
      onSelect(mapState.selectedObject.geometry)
    }

    return () => window.removeEventListener('keydown', onKeyDown)
  }, [mapState.selectedObject])

  if (!mapState.selectedObject || !points?.length) {
    return <></>
  }

  return (
    <>
      <div className={cn('leaflet-top', 'leaflet-left', styles.save_after_moving_btn, styles.rotate_out_center)}>
        <div className={cn('leaflet-control', 'leaflet-bar', styles.custom_btn)} onClick={onSave}>
          <Icon icon={"save"}/>
        </div>
      </div>

      <div className={cn('leaflet-top', 'leaflet-left', styles.info_btn, styles.rotate_out_center)}>
        <div className={cn('leaflet-control', 'leaflet-bar', styles.custom_btn, styles.info_btn_animation)}
             onClick={onInfo}>
          <Icon icon={"info"}/>
        </div>
      </div>

      <div className={cn('leaflet-top', 'leaflet-left', styles.move_left_btn_container, styles.rotate_out_center)}>
        <div
          className={cn('leaflet-control', 'leaflet-bar', styles.move_buttons)}
          onClick={() => onArrowClick('left')}
          tabIndex={0}
        >
          <Icon icon={"arrowBack"}/>
        </div>
      </div>

      <div className={cn('leaflet-top', styles.move_top_btn_container, styles.rotate_out_center)}>
        <div className={cn('leaflet-control', 'leaflet-bar', styles.move_buttons)} onClick={() => onArrowClick('top')}>
          <Icon icon={"arrowBack"} className={styles.arrow_top}/>
        </div>
      </div>

      <div className={cn('leaflet-top', styles.move_right_btn_container, styles.rotate_out_center)}>
        <div className={cn('leaflet-control', 'leaflet-bar', styles.move_buttons)}
             onClick={() => onArrowClick('right')}>
          <Icon icon={"arrowBack"} className={styles.arrow_right}/>
        </div>
      </div>

      <div className={cn('leaflet-top', styles.move_bottom_btn_container, styles.rotate_out_center)}>
        <div className={cn('leaflet-control', 'leaflet-bar', styles.move_buttons)}
             onClick={() => onArrowClick('bottom')}>
          <Icon icon={"arrowBack"} className={styles.arrow_bottom}/>
        </div>
      </div>

      {points?.map((point, index) => {
          {
            if (index !== points?.length - 1 && typeof points[1] === 'number' && typeof points[0] === 'number' )
              return (
                <Marker
                  key={index}
                  ref={elRefs[index]}
                  draggable={true}
                  alt={index.toString()}
                  eventHandlers={eventHandlers}
                  position={{lat: point[1], lng: point[0]}}
                />
              )
          }
        }
      )}
    </>
  );
};

export default MovePlantingButtons;