import React from 'react';
import { Grid } from '@mui/material';
import ExportDataFromDB from "../../../../components/ExportDataFromDB/ExportDataFromDB";

const MainFeatures = () => {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <ExportDataFromDB/>
      </Grid>
    </Grid>
  );
};

export default MainFeatures;


