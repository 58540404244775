import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { getProjects } from '../../api/project/projectApi';
import { useRecoilState } from 'recoil';
import { projectsState } from '../../store/projectStore';
import { Outlet } from 'react-router-dom';
import styles from './overview.module.scss';
import Loader from '../../components/UI/Loader/Loader';
import Tabs from '../../components/UI/Tabs/Tabs';
import { SCOPES } from '../../helpers/permissions/permissionsMaps';
import { MAP_PAGE, PLANTINGS_PAGE, PROJECTS_PAGE } from '../../router/routeConsts';

const OverView = () => {
  const { isLoading, data } = useQuery(['current-project'], getProjects)
  const [, setProjects] = useRecoilState(projectsState)

  const links = [
    { label: 'Объекты', to: PROJECTS_PAGE, scopes: [SCOPES.admin, SCOPES.user] },
    { label: 'Насаждения', to: PLANTINGS_PAGE, scopes: [SCOPES.admin, SCOPES.user] },
    { label: 'Карта', to: MAP_PAGE, scopes: [SCOPES.admin, SCOPES.user] },
  ]

  useEffect(() => {
    if (data) {
      setProjects({
        ...projectsState,
        projects: data.data
      })
    }
  }, [isLoading])

  if (isLoading || !data) {
    return <Loader/>
  }

  return (
    <div className={styles.wrapper}>
      <Tabs links={links}/>
      <div className={styles.content}>
        <Outlet/>
      </div>
    </div>
  );
};

export default OverView;