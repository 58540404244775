// packages
import React, { FC, useState } from 'react'
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField
} from '@mui/material'
import { useForm } from 'react-hook-form'
import LoadingButton from '@mui/lab/LoadingButton'
import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import { useQuery } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
// components
import Loader from '../../../components/UI/Loader/Loader'
// hooks
import { useCustomMutation } from '../../../api/useCustomMutation'
// logic
import { getAreas } from '../../../api/area/getAreas'
// urls
import { CREATE_PROJECT_URL } from '../../../api/urls'
// validation
import { updateProjectSchema } from '../../../validationSchemas/updateProjectSchema'

interface Props {
  isOpen: boolean,
  onClose: any
  refetch: Function
}

const CreateProjectForm: FC<Props> = ({ isOpen, onClose, refetch }) => {
  const { data: areas } = useQuery(['areas'], getAreas)
  const mutation = useCustomMutation()

  const [isLoading, setIsLoading] = useState(false)

  const { handleSubmit, register, reset, formState: { errors } } = useForm({
    resolver: yupResolver(updateProjectSchema),
    mode: 'onChange',
    defaultValues: {
      schema: '',
      name: '',
      idArea: 0,
      hostingUrl: process.env.REACT_APP_DEFAULT_CROC_URL,
      imageFolder: ''
    }
  })

  const onCancel = () => {
    reset()
    onClose()
  }

  const onCreateProject = (dto: any) => {
    if (!dto.idArea) {
      toast.error('Выберите объект')
      return
    }

    dto.idArea = findArea(dto.idArea)
    dto.imageFolder = dto.schema

    setIsLoading(true)

    mutation.mutateAsync({
      method: 'post',
      data: dto,
      url: CREATE_PROJECT_URL
    })
      .then(({ data }: AxiosResponse) => {
        toast.success(data.message)
        refetch()
        reset()
        setIsLoading(false)
        onClose()
      })
      .catch(({ response }) => {
        setIsLoading(false)
        toast.error(response?.data?.message)
      })
  }

  const findArea = (name: string) => {
    return areas?.data.areas.find((obj: any) => obj.name === name).id
  }

  if (!areas?.data) {
    return <Loader/>
  }

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} style={{ overflowY: 'hidden' }}>

        <DialogTitle>
          Создание проекта
        </DialogTitle>

        <DialogContent>
          <Stack spacing={2} padding={1}>
            <TextField
              {...register('schema')}
              label={'Схема'}
              error={!!errors.schema}
              helperText={errors.schema?.message}
            />

            <DialogContentText>
              Название папки с фотографиями будет такое же как и схема, вы сможете в любой момент
              после создания проекта изменить это поле.
            </DialogContentText>

            <TextField
              {...register('name')}
              label={'Название'}
              error={!!errors.name}
              helperText={errors.name?.message}
            />

            <Autocomplete
              disablePortal
              getOptionLabel={(option: any) => option.name || ''}
              options={areas.data.areas}
              sx={{ width: 300 }}
              renderInput={(params) =>
                <TextField
                  {...params}
                  {...register('idArea')}
                  label={'Объект'}
                />}
            />

            <TextField {...register('hostingUrl')} label={'Url хостинга'}/>
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            onClick={onCancel}
            autoFocus
            loading={isLoading}
          >
            Отмена
          </LoadingButton>

          <LoadingButton
            onClick={handleSubmit(onCreateProject)}
            loading={isLoading}
          >
            Создать
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreateProjectForm