import React, { FC, useState } from 'react';
import { Dialog, DialogContent, Typography } from "@mui/material";
import styles from './photoWithZoom.module.scss';

interface Props {
  imageUrl?: string
}

const PhotoWithZoom: FC<Props> = ({ imageUrl }) => {
  const [showBig, setShowBig] = useState({
    showDialog: false,
  });

  const [zoom, setZoom] = useState({
    backgroundPosition: '0% 0%',
    backgroundImage: `url(${imageUrl})`,
  })

  const onCloseOpen = (key: 'showDialog') => setShowBig(prevState => ({ ...prevState, [key]: !prevState[key] }))

  const handleMouseMove = (e: any) => {
    const { left, top, width, height } = e.target.getBoundingClientRect()
    const x = (e.pageX - left) / width * 100
    const y = (e.pageY - top) / height * 100
    setZoom(prevState => ({ ...prevState, backgroundPosition: `${x}% ${y}%` }))
  }

  if (!imageUrl) {
    return <div/>
  }

  return (
    <div className={styles.photo_with_zoom__zoom_wrapper}>
      <Typography variant={'h5'}>
        Нажмите на фото для увеличения
      </Typography>

      <img
        src={imageUrl}
        alt={`photo: ${imageUrl}`}
        onClick={() => onCloseOpen('showDialog')}
        className={styles.photo_with_zoom__photo}
      />

      <Dialog open={showBig.showDialog} onClose={() => onCloseOpen("showDialog")} maxWidth={'xl'}>
        <DialogContent>
          <div className={styles.photo_with_zoom__zoom_wrapper}>
            <Typography variant={'h5'}>
              Наведите мышку для увеличения
            </Typography>
            <figure style={zoom} onMouseMove={handleMouseMove} className={styles.figure}>
              <img src={imageUrl} alt={`photo: ${imageUrl}`}/>
            </figure>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PhotoWithZoom;