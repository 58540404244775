import React from 'react';
import { LayersControl, TileLayer } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import RosreyestrOverlays from "./RosreyestrOverlays";

const MapLayersControl: any = () => {
  return (
    <>
      <LayersControl.BaseLayer name="OpenStreetMap" checked>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </LayersControl.BaseLayer>

      {/*<LayersControl.BaseLayer name="YandexMaps">*/}
      {/*  <TileLayer*/}
      {/*    updateWhenIdle={false}*/}
      {/*    subdomains={['01', '02', '03', '04']}*/}
      {/*    attribution='&copy; <a http="yandex.ru" target="_blank">Яндекс</a> contributors'*/}
      {/*    url="http://core-renderer-tiles.maps.yandex.net/tiles?l=map&v=22.06.26-0-b220606200930&x={x}&y={y}&z={z}&scale=1&lang=ru_RU"*/}
      {/*  />*/}
      {/*</LayersControl.BaseLayer>*/}

      <LayersControl.BaseLayer name="Google Maps Satellite">
        <ReactLeafletGoogleLayer
          apiKey="AIzaSyBeCIFlCwSPzojTq1RUwCjtNlulPeVXvMs"
          type={'satellite'}
        />
      </LayersControl.BaseLayer>

      <LayersControl.BaseLayer name="Спутник">
        <TileLayer
          attribution="Esri"
          className="basemap"
          maxNativeZoom={19}
          maxZoom={19}
          subdomains={["clarity"]}
          url="https://{s}.maptiles.arcgis.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        />
      </LayersControl.BaseLayer>

      <RosreyestrOverlays/>
    </>
  );
};

export default MapLayersControl
