import { atom } from 'recoil';

export const mapStateAtom = atom({
  key: 'map-state-atom',
  default: {
    center: [] as number[],
    schema: undefined as string | undefined,
    zoom: 17,
    selectedObject: null as any | null,
    preSelectedObject: null as null | { id: number, type: 'common' | 'plantingWithoutSection' | 'section' | 'border'},
    selectedSectionsNumbers: [] as string[],
    preSelectedSectionNumber: undefined as string | undefined,
    createdObject: null as any | null,
    needReFetch: false,
    ruler: {
      isOn: false,
      pointsArray: [] as any[]
    },
    selectFinger: {
      isOn: false,
      sectionsArray: [] as any[],
      plantingArray: [] as any[]
    }
  }
})