import React, { FC } from 'react';
import styles from './mapLayoutModal.module.scss';
import { Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import { mapStateAtom } from "../../../../store/mapStore";
import Loader from "../../../../components/UI/Loader/Loader";
import { IPlantingSection } from "../../../../models/IPlanting";

interface Props {
  onAddSection: (value: string) => void,
  onShowSection: (sNumber: (string | undefined)) => void,
  memoizedSections:  IPlantingSection[] | undefined
}

const SectionsNumbersModal: FC<Props> = ({onAddSection, onShowSection, memoizedSections}) => {
  const [mapState] = useRecoilState(mapStateAtom)

  if (!memoizedSections) {
    return <Loader/>
  }

  return (
    <div
      className={styles.sections_numbers__wrapper}
      onMouseLeave={() => onShowSection(undefined)}
    >

      <Typography variant={'h5'}>
        Выберите сектора
      </Typography>

      <div className={styles.sections_numbers__content} style={{ width: `${memoizedSections.length + 1}rem` }}>
        {memoizedSections.map((s) => (
          <div
            key={s.sectionNumber}
            style={{ color: `${mapState.selectedSectionsNumbers.includes(s.sectionNumber) ? '#66ff00' : '#ffffff'}` }}
            className={
              mapState.selectedSectionsNumbers.includes(s.sectionNumber) ?
                styles.sections_numbers__selected_item
                :
                styles.sections_numbers__item
            }
            onMouseEnter={() => onShowSection(s.sectionNumber)}
            onClick={() => {
              onShowSection(undefined)
              onAddSection(s.sectionNumber)
            }}
          >
            {s.sectionNumber}
          </div>
        ))}

        <div
          className={styles.sections_numbers__item}
          onClick={() => onAddSection('all-sections')}
        >
          Все
        </div>

        <div
          className={styles.sections_numbers__item}
          onClick={() => onAddSection('remove-all-sections')}
        >
          Сбросить
        </div>
      </div>
    </div>
  );
};

export default SectionsNumbersModal;