import React from 'react';
import { LayersControl, WMSTileLayer } from "react-leaflet";

const RosreyestrOverlays = () => {
  return (
    <>
      {/*<LayersControl.Overlay name="Росреестр зоны и территории">*/}
      {/*  <WMSTileLayer*/}
      {/*    url={'https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/Zones/MapServer/export?layers=show%3A0%2C5%2C6%2C11&format=PNG32&bboxSR=102100&imageSR=102100&transparent=true&f=image'}*/}
      {/*    layers={'show:22,21,20,19,18,16,15,14,13,11,10,9,7,6,4,3,2,1'}*/}
      {/*    format={'png32'}*/}
      {/*    transparent*/}
      {/*  />*/}
      {/*</LayersControl.Overlay>*/}

      {/*<LayersControl.Overlay name="Росреестр границы единиц АТД и населённых пунктов">*/}
      {/*  <WMSTileLayer*/}
      {/*    url={'https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/BordersGKN/MapServer/export?layers=show%3A0%2C5%2C16%2C25%2C32&format=PNG32&bboxSR=102100&imageSR=102100&transparent=true&f=image'}*/}
      {/*    layers={'show:22,21,20,19,18,16,15,14,13,11,10,9,7,6,4,3,2,1'}*/}
      {/*    format={'png32'}*/}
      {/*    transparent*/}
      {/*  />*/}
      {/*</LayersControl.Overlay>*/}

      {/*<LayersControl.Overlay name="Росреестр здания и сооружения">*/}
      {/*  <WMSTileLayer*/}
      {/*    url={'https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreObjects/MapServer/export?layers=show%3A30&format=PNG32&bboxSR=102100&imageSR=102100&transparent=true&f=image'}*/}
      {/*    format={'png32'}*/}
      {/*    transparent*/}
      {/*  />*/}
      {/*</LayersControl.Overlay>*/}

      <LayersControl.Overlay name="Росреестр земельные участки">
        <WMSTileLayer
          url={'https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreObjects/MapServer/export?dpi=96&bboxSR=102100&imageSR=102100&f=image&_ts=false&size=1024%2C1024'}
          format={'PNG32'}
          maxZoom={19}
          //layers={'show%3A0%2C5%2C16%2C25%2C32'}
          transparent
          params={{service: '', layers: 'show:27,24,23,22'}}
        />
      </LayersControl.Overlay>
    </>
  );
};

export default RosreyestrOverlays;