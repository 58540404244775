import { useState } from 'react';
import { useQuery } from 'react-query';
import { Theme, useTheme } from '@mui/material/styles';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { getProjectByMunicipality, getProjects } from "../../../../../../api/project/projectApi";
import { IProject } from "../../../../../../models/IProject";
import { useCustomMutation } from "../../../../../../api/useCustomMutation";
import {
  CREATE_USER_PERMISSION_URL,
  DELETE_USER_PERMISSION_URL,
  GET_USER_PERMISSIONS_URL
} from "../../../../../../api/urls";
import { useGetRole } from "../../../../../../helpers/permissions/useGetRole";
import { hasPermissions } from "../../../../../../helpers/permissions/PermissionsGate";
import { PERMISSIONS, SCOPES } from "../../../../../../helpers/permissions/permissionsMaps";


const useEditUserPermission = (params: any) => {
  let projectsData
  const { data: allProjectsData } = useQuery(['projects'], getProjects)
  const { data: projectsByMuniData } = useQuery([`cemetery-by-municipality`], getProjectByMunicipality)
  const { role } = useGetRole()
  const permissions = PERMISSIONS[role]
  const permissionGranted = hasPermissions({ permissions, scopes: [SCOPES.admin, SCOPES.superAdmin] })

  if (permissionGranted) {
    projectsData = allProjectsData
  } else {
    projectsData = projectsByMuniData
  }

  const [isOpen, setIsOpen] = useState(false)
  const [currentProjects, setCurrentProjects] = useState<string[]>([])
  const [userPermission, setUserPermission] = useState<any[] | undefined>([])
  const [submitDelete, setSubmitDelete] = useState<number | undefined>(undefined)
  const [projects, setProjects] = useState<IProject[]>([])

  const theme = useTheme()
  const mutation = useCustomMutation()

  const handleCloseOpen = () => setIsOpen(!isOpen)
  const handleOpenAlert = (id: number) => setSubmitDelete(id)
  const handleCloseAlert = () => setSubmitDelete(undefined)

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }

  function getStyles(name: string, projectName: readonly string[], theme: Theme) {
    return {
      fontWeight:
        projectName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    }
  }

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event
    setCurrentProjects(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleSubmit = () => {
    const currentProjectsId = currentProjects.map((currentProject) => {
      return projects.filter((project) => project.name === currentProject)[0].id
    })
    currentProjectsId.forEach((currentProjectId) => {
      mutation.mutateAsync({
        method: 'post',
        data: {
          userId: params.row.id,
          projectId: currentProjectId
        },
        url: CREATE_USER_PERMISSION_URL
      }).then(({ data }: AxiosResponse) => {
        toast.success(data.message)
        setCurrentProjects([])
        getUserPermission()
      })
        .catch(({ response }) => {
          toast.error(response?.data?.message)
        })
    })
  }

  const handleSubmitDelete = () => {
    mutation.mutateAsync({
      method: 'delete',
      url: DELETE_USER_PERMISSION_URL + submitDelete
    })
      .then(({ data }: AxiosResponse) => {
        toast.success(data.message)
        handleCloseAlert()
        getUserPermission()
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
      })
  }

  const getUserPermission = () => {
    mutation.mutateAsync({
      method: 'get',
      url: GET_USER_PERMISSIONS_URL + params.id
    })
      .then((data) => {
        setUserPermission(data.data.data)
      })
  }

  const findProjectByPermissionId = () => {
    if (submitDelete && userPermission) {
      const find = userPermission.filter((value) => value.id === submitDelete)[0]
      return find.project
    }
  }

  return {
    projectsData,
    isOpen,
    currentProjects,
    userPermission,
    submitDelete,
    projects,
    setProjects,
    theme,
    mutation,
    handleCloseOpen,
    handleOpenAlert,
    handleCloseAlert,
    handleChange,
    handleSubmit,
    handleSubmitDelete,
    findProjectByPermissionId,
    getUserPermission,
    MenuProps,
    getStyles
  };
};

export default useEditUserPermission;