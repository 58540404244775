import React, { useCallback, useEffect } from 'react';
import CustomCreatePlantingDialog from "../../../../../components/UI/CustomConfirmDialog/CustomCreatePlantingDialog";
import { useRecoilState } from "recoil";
import { mapStateAtom } from "../../../../../store/mapStore";
import { CustomDialog } from "react-st-modal";
import { useCustomMutation } from "../../../../../api/useCustomMutation";
import { toast } from "react-toastify";
import { CREATE_BORDER_URL, CREATE_PLANTING_URL, CREATE_SECTION_URL } from "../../../../../api/urls";
import { useNavigate } from "react-router-dom";
import { PLANTING_PAGE } from "../../../../../router/routeConsts";

const CustomCreatePlanting = () => {
  const [mapState, setMapState] = useRecoilState(mapStateAtom)
  const mutation = useCustomMutation()
  const navigate = useNavigate()

  const confirm = useCallback(async () => {

    let req = {
      type: 'common',
      confirm: true
    }

    if (mapState.createdObject.geometry.type === 'Polygon') {
      req = await CustomDialog(
        <CustomCreatePlantingDialog
          title={'Какой объект вы хотите создать?'}
          body={''}
        />
      ) as any
    }

    if (!req || !req.confirm) {
      return
    }

    switch (req.type) {
      case 'common':
        try {
          const { data } = await mutation.mutateAsync({
            method: 'post',
            url: CREATE_PLANTING_URL,
            data: mapState.createdObject
          })

          toast.success(data.message)
          setTimeout(() => navigate(PLANTING_PAGE + data.planting[0].id + '?common'), 1000)
        } catch (e: any) {
          toast.error(e.response?.data.message)
        }
        break;

      case 'section':
        try {
          const { data } = await mutation.mutateAsync({
            method: 'post',
            url: CREATE_SECTION_URL,
            data: mapState.createdObject
          })
          toast.success(data.message)
        } catch (e: any) {
          toast.error(e.response?.data.message)
        }
        break

      case 'border':
        try {
          const { data } = await mutation.mutateAsync({
            method: 'post',
            url: CREATE_BORDER_URL,
            data: mapState.createdObject
          })
          toast.success(data.message)
        } catch (e: any) {
          toast.error(e.response?.data.message)
        }
        break

      default:
        toast.error('ERROR TYPE OF OBJECT')
        return
    }

    setMapState(prev => ({ ...prev, createdObject: null, needReFetch: true, }))
  }, [mapState.createdObject])

  useEffect(() => {
    if (!mapState.createdObject) {
      return
    }

    confirm()
  }, [mapState.createdObject])

  return (
    <>
    </>
  );
};

export default CustomCreatePlanting;