import React, { useEffect, useState } from 'react';
import { LayersControl, TileLayer, useMapEvents } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import RosreyestrOverlays from "../../../pages/project/map/MapLayers/RosreyestrOverlays";

const MiniMapTileLayers = () => {
  const [baseLayer, setBaseLayer] = useState<string>()

  useMapEvents({
    baselayerchange: event => {
      setBaseLayer(event.name)
    },
  })

  useEffect(() => {
    const bottomLeafletBar = document.getElementsByClassName('leaflet-control-attribution')

    const russianFlag = document.createElement('svg')
    russianFlag.innerHTML = '<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="12" height="8"><path fill="#fff" d="M0 0h12v4H0Z"></path><path fill="#0047ff" d="M0 3h12v4H0Z"></path><path fill="#ff0000" d="M0 6h12v4H0Z"></path></svg>'

    bottomLeafletBar[0]?.children[0]?.children[0]?.replaceWith(russianFlag)
  }, [baseLayer])

  return (
    <>
      <LayersControl.BaseLayer name="OpenStreetMap" checked>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </LayersControl.BaseLayer>

      {/*<LayersControl.BaseLayer name="YandexMaps">*/}
      {/*  <TileLayer*/}
      {/*    updateWhenIdle={false}*/}
      {/*    subdomains={['01', '02', '03', '04']}*/}
      {/*    attribution='&copy; <a http="yandex.ru" target="_blank">Яндекс</a> contributors'*/}
      {/*    url="http://core-renderer-tiles.maps.yandex.net/tiles?l=map&v=22.06.26-0-b220606200930&x={x}&y={y}&z={z}&scale=1&lang=ru_RU"*/}
      {/*  />*/}
      {/*</LayersControl.BaseLayer>*/}

      <LayersControl.BaseLayer name="Google Maps Satellite">
        <ReactLeafletGoogleLayer
          apiKey="AIzaSyBeCIFlCwSPzojTq1RUwCjtNlulPeVXvMs"
          type={'satellite'}
        />
      </LayersControl.BaseLayer>

      <LayersControl.BaseLayer name="Спутник">
        <TileLayer
          attribution="Esri"
          className="basemap"
          maxNativeZoom={19}
          maxZoom={19}
          subdomains={["clarity"]}
          url="https://{s}.maptiles.arcgis.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        />
      </LayersControl.BaseLayer>

      <RosreyestrOverlays/>
    </>
  );
};

export default MiniMapTileLayers;