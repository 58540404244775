import React, { useState } from 'react';
import { Button, Card, CardActions, CardContent, CardHeader, Dialog, Stack, TextField } from "@mui/material";
import SelectProject from "../SelectProject/SelectProject";
import { LoadingButton } from "@mui/lab";
import styles from './importStyles.module.scss';
import { useCustomMutation } from "../../api/useCustomMutation";
import { useForm } from "react-hook-form";
import { ls_setProjectInfo } from "../../helpers/localStorage";
import ImportFileMap from "./ImportFileMap";
import { toast } from "react-toastify";
import { IMPORT_URL } from "../../api/urls";

const Import = () => {
  const mutation = useCustomMutation()
  const { register, handleSubmit } = useForm()

  const [importErrors, setImportErrors] = useState<string | undefined>()
  const [openMap, setOpenMap] = useState(false)
  const [project, setProject] = useState<any>()

  const onOpenOrCloseMap = () => {
    setOpenMap(!openMap);
  }

  const onProjectClick = (project: any) => {
    ls_setProjectInfo(project)
    setProject(project)
  }

  const inputLabelProps = { shrink: true }
  const size = 'small'
  const type = 'file'

  const onSubmit = async (data: any) => {
    try {
      const formData = new FormData()

      for (const [key, value] of Object.entries<FileList | string>(data)) {
        if (typeof value === 'string') {
          formData.set(key, value)
          continue
        }

        if (value.length) {
          formData.set(key, value[0])
        }
      }

      formData.set('schema', project.schema)

      const res = await mutation.mutateAsync({
        method: 'post',
        url: IMPORT_URL,
        data: formData
      })

      setImportErrors(undefined)
      toast.success(res.data.message)

    } catch (e: any) {
      console.log(e);
      setImportErrors(e.response?.data.message)

      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
        </div>
      )
    }
  }

  return (
    <Card elevation={4} className={styles.card}>
      <CardHeader title={'Инвентаризация'}/>

      <CardContent>
        <Stack spacing={2}>

          <SelectProject onProjectClick={onProjectClick}/>
          {project &&
              <h2>{project ? project.name : 'Выберите проект'}</h2>
          }

          <TextField
            {...register('bordersFile', { required: true })}
            size={size}
            type={type}
            label={'Границы'}
            InputLabelProps={inputLabelProps}
          />
          <TextField
            {...register('sectionsFile', { required: false })}
            size={size}
            type={type}
            label={'Сектора'}
            InputLabelProps={inputLabelProps}
          />
          <TextField
            {...register('plantingsFile', { required: false })}
            size={size}
            type={type}
            label={'Насаждения'}
            InputLabelProps={inputLabelProps}
          />
        </Stack>

        {importErrors && importErrors.split(':')[1] &&
            <div style={{ color: 'red' }}>
              {JSON.stringify(importErrors)}
            </div>
        }
      </CardContent>

      <CardActions>
        <Stack direction={"row"} justifyContent={"space-between"} width={'100%'}>
          <LoadingButton
            loading={mutation.isLoading}
            variant={'outlined'}
            onClick={handleSubmit(onSubmit)}
          >
            Загрузить
          </LoadingButton>

          <Button onClick={onOpenOrCloseMap}>
            Карта файлов
          </Button>
        </Stack>
      </CardActions>

      <Dialog open={openMap} fullScreen onClose={onOpenOrCloseMap}>
        <ImportFileMap onClose={onOpenOrCloseMap}/>
      </Dialog>
    </Card>
  );
};

export default Import;