import React from 'react';
import { Grid } from "@mui/material";
import PermissionsGate from "../../../../helpers/permissions/PermissionsGate";
import { SCOPES } from "../../../../helpers/permissions/permissionsMaps";
import CopySchema from "../../../../components/copySchema/CopySchema";
import CheckSequencesInDb from "../../../../components/checks/CheckSequencesInDB";

const OtherFeatures = () => {
  return (
    <Grid container spacing={3} columns={12}>
      <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin]}>
        <Grid xs={4} item>
          <CheckSequencesInDb/>
        </Grid>

        <Grid xs={6} item>
          <CopySchema/>
        </Grid>
      </PermissionsGate>
    </Grid>
  );
};

export default OtherFeatures;