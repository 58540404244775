import dayjs from 'dayjs';
import { NO_INFO } from '../utils/utils';

type Data = any

type Options = {
  dateWithoutTime?: boolean
  withParseInt?: boolean
}

const initOptions: Options = {
  dateWithoutTime: false,
  withParseInt: false
}

export const showInfoField =
  (data: Data, { dateWithoutTime, withParseInt }: Options = initOptions): string | number => {
    if (!data || data === 'null') {
      return NO_INFO
    }

    if (typeof data === 'object' && JSON.stringify(data).includes('type')) {
      if (data.type) {
        return `${data.coordinates[1]}, ${data.coordinates[0]}`
      }
    }

    if (Array.isArray(data)) {
      return data.toString()
    }

    if (typeof data === 'boolean') {
      return data ? 'ДА' : 'НЕТ'
    }

    const date = dayjs(data)

    if (
      data &&
      date.isValid() &&
      typeof data !== 'number' &&
      typeof data === 'string' &&
      data.includes('Z') &&
      isNaN(Number(data))
    ) {
      return date.format(dateWithoutTime ? 'DD-MM-YYYY' : 'DD.MM.YYYY HH:mm')
    }

    if (data || data === 0) {
      if (typeof data === 'number' && withParseInt) {
        return data.toFixed(2)
      }
      return data.toString()
    }

    return NO_INFO
  }