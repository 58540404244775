import {
  ACTIVATE_ICON_URL, ADD_ICON_URL, ARROW_BACK_ICON_URL,
  BAN_ICON_URL, CHECK_CIRCLE_ICON_URL, CLOSE_ICON_URL, COPY_ICON_URL,
  DELETE_ICON_URL, DOUBLE_ARROW_ICON_URL, EDIT_CEMETERY_PERMISSIONS_ICON_URL,
  EDIT_ICON_URL, EDIT_MUNICIPALITY_ICON_URL, EXPAND_MORE_ICON_URL,
  INFO_ICON_URL,
  RESTORE_ICON_URL,
  ROLE_ICON_URL, ROTATE_ICON_URL, RULER_ICON_URL, SAVE_ICON_URL, SEARCH_ICON_URL, SELECT_FINGER_ICON_URL,
  SET_PERMISSION_ICON_URL, SETTINGS_ICON_URL, START_ICON_URL, VISIBILITY_ICON_URL, VISIBILITY_OFF_ICON_URL
} from "./iconsRoutes";
import styles from './icons.module.scss'
import { IconType } from "../../../models/IconType";

export const getIconsPath = (icon: IconType) => {

  const iconPath: { [key: string]: { url: string, colorClass: string } } = {
    ban: {
      url: BAN_ICON_URL,
      colorClass: styles.orange
    },
    activate: {
      url: ACTIVATE_ICON_URL,
      colorClass: styles.orange
    },
    updateRole: {
      url: ROLE_ICON_URL,
      colorClass: styles.pink
    },
    delete: {
      url: DELETE_ICON_URL,
      colorClass: styles.red
    },
    editPermissions: {
      url: SET_PERMISSION_ICON_URL,
      colorClass: styles.green
    },
    edit: {
      url: EDIT_ICON_URL,
      colorClass: styles.orange
    },
    info: {
      url: INFO_ICON_URL,
      colorClass: styles.blue
    },
    restore: {
      url: RESTORE_ICON_URL,
      colorClass: styles.red
    },
    doubleArrow: {
      url: DOUBLE_ARROW_ICON_URL,
      colorClass: styles.blue
    },
    arrowBack: {
      url: ARROW_BACK_ICON_URL,
      colorClass: styles.blue
    },
    expandMore: {
      url: EXPAND_MORE_ICON_URL,
      colorClass: styles.blue
    },
    visibility: {
      url: VISIBILITY_ICON_URL,
      colorClass: styles.blue
    },
    visibilityOff: {
      url: VISIBILITY_OFF_ICON_URL,
      colorClass: styles.blue
    },
    add: {
      url: ADD_ICON_URL,
      colorClass: styles.green
    },
    copy: {
      url: COPY_ICON_URL,
      colorClass: styles.green
    },
    save: {
      url: SAVE_ICON_URL,
      colorClass: styles.green
    },
    rotate: {
      url: ROTATE_ICON_URL,
      colorClass: styles.blue
    },
    editCemeteryPermissions: {
      url: EDIT_CEMETERY_PERMISSIONS_ICON_URL,
      colorClass: styles.green
    },
    editMunicipality: {
      url: EDIT_MUNICIPALITY_ICON_URL,
      colorClass: styles.pink
    },
    ruler: {
      url: RULER_ICON_URL,
      colorClass: styles.orange
    },
    start: {
      url: START_ICON_URL,
      colorClass: styles.green
    },
    close: {
      url: CLOSE_ICON_URL,
      colorClass: styles.blue
    },
    checkCircle: {
      url: CHECK_CIRCLE_ICON_URL,
      colorClass: styles.green
    },
    selectFinger: {
      url: SELECT_FINGER_ICON_URL,
      colorClass: styles.pink
    },
    settings: {
      url: SETTINGS_ICON_URL,
      colorClass: styles.blue
    },
    search: {
      url: SEARCH_ICON_URL,
      colorClass: styles.blue
    }
  }

  return iconPath[icon] || { colorClass: styles.blue, url: INFO_ICON_URL }
}