import React, { useState } from 'react';
import { Card, CardActions, CardContent, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import styles from '../styles.module.scss';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { validateCopySchema } from "../../validationSchemas/validateCopySchema";
import { useCustomMutation } from "../../api/useCustomMutation";
import { IProject } from "../../models/IProject";
import { COPY_SCHEMA_URL, WRITE_USER_HISTORY_URL } from "../../api/urls";
import SelectProject from "../SelectProject/SelectProject";
import CustomCardHeader from "../UI/MyCardHeader/CustomCardHeader";
import { algorithmsTitles } from "../../utils/utils";

type Form = {
  newSchema: string
  projectName: string
}

const CopySchema = () => {
  const { register, handleSubmit, formState: { errors } } = useForm<Form>({
    resolver: yupResolver(validateCopySchema)
  })
  const mutation = useCustomMutation()

  const [project, setProject] = useState<IProject>()

  const onProjectClick = (selectedProject: IProject) => {
    setProject(selectedProject)
  }

  const onSubmit = async (form: Form) => {
    try {
      if (!project) {
        toast.error('Выберите проект')
        return
      }

      let changesDescription = {
        action: 'algorithm',
        objectId: project.id,
        data: [{algorithmName: 'CopySchema', newSchema: form.newSchema}]
      } as any

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: project.schema,
          changesDescription,
          actionObject: 'project'
        },
        url: WRITE_USER_HISTORY_URL
      })

      const dto = { ...form, oldSchema: project.schema }

      const { data } = await mutation.mutateAsync({
        method: 'post',
        url: COPY_SCHEMA_URL,
        data: dto
      })

      toast.success(data.message)
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  const onInfoClick = () => {
    toast(
      <>
        Копируем данные в новую схему.
      </>
    )
  }

  return (
    <Card elevation={6} className={styles.card}>
      <CustomCardHeader title={algorithmsTitles.CopySchema} onInfoClick={onInfoClick}/>

      <CardContent>
        <Stack spacing={3} direction={"row"}>

          <div>
            <SelectProject onProjectClick={onProjectClick}/>
          </div>

          <TextField
            {...register('newSchema')}
            size={'small'}
            type={'text'}
            label={'Имя новой схемы'}
            error={!!errors.newSchema}
            helperText={errors.newSchema?.message}
          />

          <TextField
            {...register('projectName')}
            size={'small'}
            type={'text'}
            label={'Название нового проекта'}
            error={!!errors.projectName}
            helperText={errors.projectName?.message}
          />
        </Stack>
      </CardContent>

      <CardActions>
        <LoadingButton
          loading={mutation.isLoading}
          variant={'contained'}
          onClick={handleSubmit(onSubmit)}
        >
          Скопировать
        </LoadingButton>

        {project ?
          <Stack direction={"row"} spacing={1} marginLeft={2}>
            <Typography variant={'h6'}>Выбранный проект:</Typography>
            <Typography variant={'h6'}>{project.name}</Typography>
            <Typography variant={'h6'}>{project.schema}</Typography>
          </Stack>
          :
          <></>
        }
      </CardActions>

    </Card>
  );
};

export default CopySchema;