// packages
import React, { FC, useState } from 'react'
import { useQuery } from 'react-query'
import { getMunicipalities } from '../../../api/municipality/getMunicipalities'
import { useCustomMutation } from '../../../api/useCustomMutation'
import { useForm } from 'react-hook-form'
import { Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
// components
import Loader from '../../../components/UI/Loader/Loader'
// urls
import { CREATE_AREA_URL } from '../../../api/urls'

interface Props {
  isOpen: boolean,
  onClose: any
}

const CreateAreaForm: FC<Props> = ({ isOpen, onClose }) => {
  const { data: municipalities } = useQuery(['municipalities'], getMunicipalities)
  const mutation = useCustomMutation()
  const [isLoading, setIsLoading] = useState(false)
  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      name: '',
      idRgis: '',
      idMunicipality: '',
    }
  })

  const onCancel = () => {
    reset()
    onClose()
  }

  const findMunicipalityByName = (name: string) => {
    const municipality = municipalities?.data.municipalities.find((obj: any) => obj.name === name)
    return municipality.id
  }

  const onCreateProject = (dto: any) => {
    setIsLoading(true)
    dto.idMunicipality = findMunicipalityByName(dto.idMunicipality)
    dto.idRgis = parseInt(dto.idRgis)

    mutation.mutateAsync({
      method: 'post',
      data: dto,
      url: CREATE_AREA_URL
    })
      .then(({ data }: AxiosResponse) => {
        toast.success(data.message)
        onClose(false)
        reset()
        setIsLoading(false)
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
        setIsLoading(false)
      })
  }

  if (!municipalities) {
    return <Loader/>
  }

  return (
    <>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>
          Создание проекта
        </DialogTitle>

        <DialogContent>
          <Stack spacing={2} padding={1}>
            <TextField {...register('name')} label={'Название'}/>

            <Autocomplete
              disablePortal
              id="auto-complete-municipalities"
              getOptionLabel={(option: any) => option.name || ''}
              options={municipalities.data.municipalities}
              sx={{ width: 300 }}
              renderInput={(params) =>
                <TextField
                  {...params}
                  {...register('idMunicipality')}
                  label={'Округ'}
                />}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            onClick={onCancel}
            autoFocus
            loading={isLoading}
          >
            Отмена
          </LoadingButton>

          <LoadingButton
            onClick={handleSubmit(onCreateProject)}
            loading={isLoading}
          >
            Cоздать
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreateAreaForm