import React, {FC, useEffect} from 'react';
import {MapContainer, ScaleControl} from 'react-leaflet';
import styles from './mapLayout.module.scss';
import ProjectMap from './ProjectMap/ProjectMap'
import {Box, Stack, Typography} from '@mui/material';
import WhereAmIButton from "./CustomButtons/WhereAmIButton";
import MovePlantingButtons from "./CustomButtons/movePlantingButtons/movePlantingButtons";
import {SCOPES} from "../../../helpers/permissions/permissionsMaps";
import PermissionsGate from "../../../helpers/permissions/PermissionsGate";
import Ruler from "./CustomButtons/ruller/Ruler";
import {useRecoilState} from "recoil";
import {mapStateAtom} from "../../../store/mapStore";
import useMapLayout from "./useMapLayout";
import MySpeedDial from "../../../components/UI/MySpeedDial/MySpeedDial";
import Icon from "../../../components/UI/Icons/Icon";
import CustomCreatePlanting from "./CustomButtons/CustomEdit/CustomCreatePlanting";
import SelectFinger from "./CustomButtons/SelectFinger";
import PlantingInfoModal from "./MapLayoutModal/PlantingInfoModal";
import SectionsNumbersModal from "./MapLayoutModal/SectionsNumbersModal";
import SectionModal from "./MapLayoutModal/SectionModal";
import NavigateBetweenPlantings from "../../../components/NavigateBetweenPlantings/NavigateBetweenPlantings";

const initialCenter: any = [55.763441, 37.592812];

const MapLayout: FC = () => {
  const [mapState] = useRecoilState(mapStateAtom)

  const {
    projectInfo,
    mapData,
    memoizedBorders,
    memoizedPlantingsWithoutPhotos,
    memoizedPlantingsWithoutSections,
    plantingsWithoutPhotoOnCroc,
    memoizedPlantingsWithPhotos,
    memoizedSections,
    notExisingPhotos,
    onAddSection,
    loadMapData,
    onShowInfo,
    onClose,
    loadPlantingInfo,
    onShowSection,
    onCopy,
    onGreaterPlanting,
    onLowerPlanting,
    onGoToPlanting
  } = useMapLayout()

  useEffect(() => {
    loadPlantingInfo()
  }, [mapState.preSelectedObject])

  useEffect(() => {
    loadMapData()
  }, [mapState.selectedSectionsNumbers])

  useEffect(() => {
    if (mapState.needReFetch) {
      loadMapData()
    }
  }, [mapState.needReFetch])

  if (!projectInfo) {
    return <h2>Загрузите проект</h2>
  }

  return (
    <Box className={styles.wrapper}>
      <CustomCreatePlanting/>

      <Stack direction={'row'} alignItems={'center'} spacing={1} padding={1}>
        <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin]}>
          <span className={styles.hint}/>
          <Typography> - насаждения без фото</Typography>
        </PermissionsGate>

        <NavigateBetweenPlantings
          onBack={mapState.selectedObject?.lowerId ? onLowerPlanting : undefined}
          onForward={mapState.selectedObject?.greaterId ? onGreaterPlanting : undefined}
          onGoTo={onGoToPlanting}
        />

        <div className={styles.info_btn_animation} onClick={onShowInfo}>
          <Icon icon={"info"}/>
        </div>
      </Stack>

      <PlantingInfoModal
        onClose={onClose}
        onCopy={onCopy}
      />

      <SectionModal onClose={onClose}/>

      <MySpeedDial>
        <SectionsNumbersModal
          onAddSection={onAddSection}
          onShowSection={onShowSection}
          memoizedSections={memoizedSections}
        />
      </MySpeedDial>

      <MapContainer
        preferCanvas
        className={styles.leaflet_container}
        center={initialCenter}
        zoom={12}
        doubleClickZoom={false}
        keyboard={false}
      >
        <ProjectMap
          plantingsWithoutPhotoOnCroc={plantingsWithoutPhotoOnCroc || []}
          borders={memoizedBorders || []}
          sections={memoizedSections || []}
          plantingsWithPhotos={memoizedPlantingsWithPhotos || []}
          plantingsWithoutPhotos={memoizedPlantingsWithoutPhotos || []}
          plantingsWithoutSections={memoizedPlantingsWithoutSections || []}
          showObjectsWithoutPhotoOnCroc={notExisingPhotos.showOnMap}
          sectionsCenters={mapData?.sectionsCenters || []}
          onAddSection={onAddSection}
        />

        <ScaleControl position={'topright'}/>

        <WhereAmIButton/>

        <Ruler/>

        <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin]}>
          <SelectFinger/>
        </PermissionsGate>

        <PermissionsGate
          scopes={
            mapState.preSelectedObject?.type === 'section' ?
              [SCOPES.superAdmin] :
              [SCOPES.admin, SCOPES.superAdmin, SCOPES.superUser, SCOPES.editorUser]}
        >
          <MovePlantingButtons/>
        </PermissionsGate>
      </MapContainer>
    </Box>
  );
};

export default React.memo(MapLayout);