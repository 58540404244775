import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from './overview.module.scss';
import Tabs from '../../../components/UI/Tabs/Tabs';
import {
  ADMIN_CEMETERIES, ADMIN_CONDITIONS,
  ADMIN_FEATURES, ADMIN_GENUS, ADMIN_KINDS, ADMIN_LIFE_FORMS,
  ADMIN_MUNICIPALITIES, ADMIN_NAMES,
  ADMIN_PROJECTS,
  ADMIN_STATISTICS,
  ADMIN_USERS
} from '../../../router/routeConsts';
import { SCOPES } from "../../../helpers/permissions/permissionsMaps";

const OverView = () => {
  const links = [
    { label: 'Возможности', to: ADMIN_FEATURES, scopes: [SCOPES.admin] },
    { label: 'Пользователи', to: ADMIN_USERS, scopes: [SCOPES.admin, SCOPES.superUser] },
    { label: 'Проекты', to: ADMIN_PROJECTS, scopes: [SCOPES.admin, SCOPES.superAdmin] },
    { label: 'Объекты', to: ADMIN_CEMETERIES, scopes: [SCOPES.admin, SCOPES.superAdmin] },
    { label: 'Округи', to: ADMIN_MUNICIPALITIES, scopes: [SCOPES.admin, SCOPES.superAdmin] },
    { label: 'Названия', to: ADMIN_NAMES, scopes: [SCOPES.admin, SCOPES.superAdmin] },
    { label: 'Роды', to: ADMIN_GENUS, scopes: [SCOPES.admin, SCOPES.superAdmin] },
    { label: 'Состояния', to: ADMIN_CONDITIONS, scopes: [SCOPES.admin, SCOPES.superAdmin] },
    { label: 'Виды', to: ADMIN_KINDS, scopes: [SCOPES.admin, SCOPES.superAdmin] },
    { label: 'Жизненные формы', to: ADMIN_LIFE_FORMS, scopes: [SCOPES.admin, SCOPES.superAdmin] },
    { label: 'Статистика', to: ADMIN_STATISTICS, scopes: [SCOPES.admin, SCOPES.superAdmin, SCOPES.superUser] }
  ]

  return (
    <div className={styles.wrapper}>
      <Tabs links={links}/>
      <div className={styles.content}>
        <Outlet/>
      </div>
    </div>
  );
};

export default OverView;