import React, { FC } from 'react';
import { actionObjectType, IChangesDescription, userActionsType } from "../../../../../models/IUserActionsHistory";
import { Table, TableBody } from "@mui/material";
import { plantingDto } from "../../../../project/plantings/usePlantings";
import { showInfoField } from "../../../../../helpers/showInfoField";
import { StyledTableCell, StyledTableRow } from "../../../../../components/UI/StyledTable/StyledTable";
import { algorithmsTitles } from "../../../../../utils/utils";

interface Props {
  action: userActionsType,
  description: IChangesDescription,
  entity: actionObjectType
}

const UserActionsHistoryDescriptionCell: FC<Props> = ({ description, action, entity }) => {
  const getFieldNameOnRussian = (value: string | any, key: string) => {
    if (key === 'fieldName') {
      let dto = plantingDto

      let label = 'Ошибка'

      dto.forEach((d: any) => {
        if (d.fieldName === value)
          label = d.label
      })

      return label
    } else {
      return showInfoField(value, { dateWithoutTime: true })
    }
  }

  switch (action) {
    case "edit":
      return (
        <div>
          <Table>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell sx={{ width: '10rem' }}>
                  Поле
                </StyledTableCell>

                <StyledTableCell sx={{ width: '10rem' }}>
                  Старые данные
                </StyledTableCell>

                <StyledTableCell sx={{ width: '10rem' }}>
                  Новые данные
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>

          {description.data.map((d) => (
            <Table>
              <TableBody>
                <StyledTableRow>
                  {Object.entries(d).map(([key, value]) => (
                    <StyledTableCell sx={{ width: '10rem' }}>
                      {getFieldNameOnRussian(value, key)}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableBody>
            </Table>
          ))}
        </div>
      )
    case "delete":
      return <p>Удаление объекта</p>
    case "delete_photo":
      return <p>Удаление фото</p>
    case "create_photo":
      return <p>Добавление фото</p>
    case "algorithm":
      // @ts-ignore
      return <p>Запуск алгоритма - {algorithmsTitles[description.data[0].algorithmName]}</p>
    default:
      return <p>Ошибка: {JSON.stringify(description)}</p>
  }
};

export default UserActionsHistoryDescriptionCell;