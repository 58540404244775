export const HOME_PAGE = '/'
// auth
// export const SIGN_UP_PAGE = '/signUp'
export const SIGN_IN_PAGE = '/signIn'
export const RESET_PASSWORD_PAGE = '/reset-password/'
export const FORGOT_PASSWORD_PAGE = '/forgot-password'

// SITE ROUTES
export const OVERVIEW_PAGE = '/overview/'
export const PROJECTS_PAGE = OVERVIEW_PAGE + 'projects'
export const PLANTINGS_PAGE = OVERVIEW_PAGE + 'planting'
export const PLANTING_PAGE = OVERVIEW_PAGE + 'planting/'
export const EDIT_PLANTING_PAGE = OVERVIEW_PAGE + 'planting/edit/'
export const MAP_PAGE = OVERVIEW_PAGE + 'map'

// ADMIN ROUTES
export const OVERVIEW_ADMIN_PAGE = '/admin/'

export const ADMIN_FEATURES = OVERVIEW_ADMIN_PAGE + 'features'
export const IMPORT_FEATURES = ADMIN_FEATURES + '/import'
export const COMPARING_FEATURES = ADMIN_FEATURES + '/compare'
export const ADMIN_STUBS_FEATURES = ADMIN_FEATURES + '/finders'
export const ADMIN_OTHER_FEATURES = ADMIN_FEATURES + '/other'

export const ADMIN_PROJECTS = OVERVIEW_ADMIN_PAGE + 'projects'
export const ADMIN_CEMETERIES = OVERVIEW_ADMIN_PAGE + 'areas'
export const ADMIN_MUNICIPALITIES = OVERVIEW_ADMIN_PAGE + 'municipalities'
export const ADMIN_USERS = OVERVIEW_ADMIN_PAGE + 'users'
export const ADMIN_NAMES = OVERVIEW_ADMIN_PAGE + 'names'
export const ADMIN_GENUS = OVERVIEW_ADMIN_PAGE + 'genus'
export const ADMIN_KINDS = OVERVIEW_ADMIN_PAGE + 'kinds'
export const ADMIN_LIFE_FORMS = OVERVIEW_ADMIN_PAGE + 'life-forms'
export const ADMIN_CONDITIONS = OVERVIEW_ADMIN_PAGE + 'conditions'

export const ADMIN_STATISTICS = OVERVIEW_ADMIN_PAGE + 'statistics'
export const ADMIN_USER_ACTIONS_STATISTIC = ADMIN_STATISTICS + '/actions-history'

// profile
export const PROFILE_PAGE = '/profile/'
export const EDIT_PROFILE_PAGE = PROFILE_PAGE + 'edit'
export const STATISTIC_PROFILE_PAGE = PROFILE_PAGE + 'statistic'
export const PHOTO_STATISTIC_PROFILE_PAGE = PROFILE_PAGE + 'statistic/photo'