// packages
import React, { useState } from 'react'
import { GridColumns } from '@mui/x-data-grid'
import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
// components
import AreaActions from './areaActions/AreaActions'
// logic
import { getAreas, getDeletedAreas } from '../../../api/area/getAreas'
import { getMunicipalities } from '../../../api/municipality/getMunicipalities'
import { useCustomMutation } from '../../../api/useCustomMutation'
// helpers
import { showDate } from '../../../helpers/showDate'
// urls
import { DELETE_AREA_URL, RESTORE_AREA_URL, UPDATE_AREA_URL } from '../../../api/urls'
// types
import { IArea } from '../../../models/IArea'

export const useAreas = () => {
  let dataObj: any
  const [isLoading, setIsLoading] = useState(false)
  const [deletedToggle, setDeletedToggle] = useState(false)
  const deleted = useQuery(['deletedAreas'], getDeletedAreas)
  const notDeleted = useQuery(['areas'], getAreas)

  if (!deletedToggle) {
    dataObj = notDeleted
  } else {
    dataObj = deleted
  }

  const { refetch: areasRefetch } = notDeleted
  const { refetch: deletedAreasRefetch } = deleted
  const { data: areas } = dataObj
  const { data: municipalities } = useQuery(['municipalities'], getMunicipalities)
  const mutation = useCustomMutation()

  const findMunicipalityById = (id: any) => {
    const municipality = municipalities?.data.municipalities.find((obj: any) => obj.id === id)
    if (!municipality) {
      return 'Загрузка'
    } else {
      return municipality.name
    }
  }

  const successMutation = (message: string) => {
    toast.success(message);
    areasRefetch()
    deletedAreasRefetch()
    setIsLoading(false)
  }

  const handleToggle = () => {
    setDeletedToggle(!deletedToggle)
  }

  const rows: any = areas?.data.areas.map((area: IArea) => ({
    id: area.id,
    name: area.name,
    idMunicipality: area.idMunicipality,
    municipality: findMunicipalityById(area.idMunicipality),
    dateCreated: showDate(area.dateCreated, { withTime: true }),
    dateUpdated: showDate(area.dateUpdated, { withTime: true }),
    dateDeleted: area.dateDeleted ? showDate(area.dateDeleted, { withTime: true }) : 'Не удалён'
  }))

  const onRestore = (id: number) => {
    mutation.mutateAsync({
      method: 'patch',
      url: RESTORE_AREA_URL + id
    })
      .then(({ data }: AxiosResponse) => {
        successMutation(data.message)
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
        setIsLoading(false)
      })
  }

  const onEdit = (id: any, dto: any) => {
    setIsLoading(true)
    mutation.mutateAsync({
      method: 'patch',
      data: dto,
      url: UPDATE_AREA_URL + id
    })
      .then(({ data }: AxiosResponse) => {
        successMutation(data.message)
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
        setIsLoading(false)
      })
  }

  const onRemove = (data: any) => {
    setIsLoading(true)
    mutation.mutateAsync({
      method: 'delete',
      url: DELETE_AREA_URL + data
    })
      .then(({ data }: AxiosResponse) => {
        successMutation(data.message)
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
        setIsLoading(false)
      })
  }

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      type: 'number'
    },
    {
      field: 'name',
      headerName: 'Название',
      width: 250,
      type: 'string'
    },
    {
      field: 'municipality',
      headerName: 'Округ',
      width: 200,
      type: 'string'
    },
    {
      field: 'dateCreated',
      headerName: 'Дата создания',
      width: 180
    },
    {
      field: 'dateUpdated',
      headerName: 'Дата изменения',
      width: 180
    },
    {
      field: 'dateDeleted',
      headerName: 'Дата удаления',
      width: 180
    },
    {
      field: 'actions',
      headerName: 'Действия',
      width: 100,
      renderCell: (params =>
          <AreaActions
            params={params}
            onEdit={onEdit}
            onRemove={onRemove}
            isLoading={isLoading}
            municipalities={municipalities?.data.municipalities}
            onRestore={onRestore}
          />
      ),
    }
  ]

  return { columns, rows, handleToggle, isExistDeleted: !deleted.data?.data.areas.length && !deletedToggle }
}