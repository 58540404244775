import React, { FC, useCallback, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Stack, TextField, Typography } from "@mui/material";
import MiniMap from "../../../../../components/MiniMap/MiniMap";
import { IPlanting } from "../../../../../models/IPlanting";
import styles from '../editPlantingFormStyles.module.scss';
import { NO_INFO } from "../../../../../utils/utils";
import Icon from "../../../../../components/UI/Icons/Icon";
import { ls_getProjectInfo } from "../../../../../helpers/localStorage";
import { Controller, useForm } from "react-hook-form";
import ChooseIcon from "./ChooseIcon";

interface Props {
  planting: IPlanting
}

interface IEditIconForm {
  iconUrl: string,
  iconWidth: number,
  iconHeight: number
}

const EditIconDialog: FC<Props> = ({ planting }) => {
  const [openEditIcon, setOpenEditIcon] = useState(false)
  const [icon, setIcon] = useState<undefined | string>(undefined)
  const projectInfo = ls_getProjectInfo()
  const form = useForm<IEditIconForm>({ defaultValues: { iconHeight: 35, iconWidth: 35 } })

  const setNewIcon = useCallback((icon: string) => {
    setIcon(icon)
  }, [])

  const toggleEditIcon = () => {
    setOpenEditIcon(!openEditIcon)
  }

  return (
    <>
      {/*<div className={styles.field}>*/}
      {/*  <Typography>Иконка на карте:</Typography>*/}

      {/*  <div className={styles.icon_field}>*/}
      {/*    {planting.iconOnMap ?*/}
      {/*      <img src={`${projectInfo?.hostingUrl}icons/${planting.iconOnMap}`} alt={planting.iconOnMap}/> :*/}
      {/*      <p>{NO_INFO}</p>}*/}
      {/*    <Icon icon={'edit'} onClick={toggleEditIcon}></Icon>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <Dialog open={openEditIcon} onClose={toggleEditIcon} maxWidth={'lg'}>
        <DialogTitle>
          Редактирование иконки насаждения на карте
        </DialogTitle>

        <DialogContent>
          <div className={styles.edit_icon__wrapper}>
            <MiniMap planting={planting}/>
          </div>

          <ChooseIcon
            setIconUrl={setNewIcon}
            plantingId={planting.id}
          />

          {/*<form>*/}
          {/*  <Stack direction={'row'} spacing={2}>*/}
          {/*    <div>*/}
          {/*      <Typography>Ширина</Typography>*/}

          {/*      <Controller*/}
          {/*        name={'iconWidth'}*/}
          {/*        control={form.control}*/}
          {/*        render={({ field }) =>*/}
          {/*          <TextField*/}
          {/*            size={'small'}*/}
          {/*            {...field}*/}
          {/*            label={'Ширина: '}*/}
          {/*          />*/}
          {/*        }*/}
          {/*      />*/}
          {/*    </div>*/}

          {/*    <div>*/}
          {/*      <Typography>Высота</Typography>*/}

          {/*      <Controller*/}
          {/*        name={'iconHeight'}*/}
          {/*        control={form.control}*/}
          {/*        render={({ field }) =>*/}
          {/*          <TextField*/}
          {/*            size={'small'}*/}
          {/*            {...field}*/}
          {/*            label={'Высота: '}*/}
          {/*          />*/}
          {/*        }*/}
          {/*      />*/}
          {/*    </div>*/}

          {/*    <div>*/}
          {/*      <Typography>Иконка</Typography>*/}

          {/*      <Controller*/}
          {/*        name={'iconUrl'}*/}
          {/*        control={form.control}*/}
          {/*        render={({ field }) =>*/}
          {/*          <TextField*/}
          {/*            size={'small'}*/}
          {/*            {...field}*/}
          {/*            label={'Иконка: '}*/}
          {/*          />*/}
          {/*        }*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </Stack>*/}
          {/*</form>*/}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditIconDialog;