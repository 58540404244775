import React, { FC } from 'react';
import { IPlanting } from "../../models/IPlanting";
import styles from './miniMap.module.scss';
import { MapContainer, ScaleControl } from "react-leaflet";
import MiniMapObjects from "./miniMapFragments/MiniMapObjects";
import PlantingWithIconMarker from "../PlantingWithIconMarker/PlantingWithIconMarker";

interface Props {
  planting: IPlanting
}

const MiniMap: FC<Props> = ({ planting }) => {
  return (
    <div className={styles.mini_map__wrapper}>
      <MapContainer
        className={styles.leaflet__container}
        center={[planting.geometry.coordinates[1], planting.geometry.coordinates[0]]}
        zoom={20}
        maxZoom={30}
      >
        <ScaleControl position={'topright'}/>
        <MiniMapObjects idPlanting={planting.id}/>
        <PlantingWithIconMarker planting={planting} color={'#2fff00'}/>
      </MapContainer>
    </div>
  );
};

export default MiniMap;