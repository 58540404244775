import React, {FC, useEffect, useMemo, useState} from 'react';
import {CircleMarker, FeatureGroup, LayersControl, Marker, Polygon, Tooltip, useMap} from 'react-leaflet';
import MapLayersControl from '../MapLayers/MapLayersControl';
import {booleanWithin, center, circle, flip, getCoord, point, polygon} from '@turf/turf';
import {useRecoilState} from 'recoil';
import {mapStateAtom} from '../../../../store/mapStore';
import EditControl from '../../../../components/EditControl/EditControl';
import {ls_getProjectInfo} from '../../../../helpers/localStorage';
import PermissionsGate from "../../../../helpers/permissions/PermissionsGate";
import {SCOPES} from "../../../../helpers/permissions/permissionsMaps";
import {LeafletEventHandlerFnMap} from "leaflet";
import useDebounce from "../../../../helpers/useDebounce";

interface ProjectMapProps {
  borders: any[]
  sections: any[]
  plantingsWithPhotos: any[]
  plantingsWithoutPhotos: any[]
  plantingsWithoutSections: any[]
  plantingsWithoutPhotoOnCroc: any[]
  showObjectsWithoutPhotoOnCroc: boolean
  sectionsCenters: any[]
  onAddSection: (value: string) => void
}

const initialCenter: any = [55.763441, 37.592812];

const ProjectMap: FC<ProjectMapProps> = ({
                                           plantingsWithPhotos,
                                           plantingsWithoutPhotos,
                                           plantingsWithoutSections,
                                           sections,
                                           borders,
                                           plantingsWithoutPhotoOnCroc,
                                           showObjectsWithoutPhotoOnCroc,
                                           sectionsCenters,
                                           onAddSection,
                                         }) => {
  const [mapState, setMapState] = useRecoilState(mapStateAtom)
  const [baseLayer, setBaseLayer] = useState<any>()
  const [sectionClick, setSectionClick] = useState<any>()
  const debouncedSectionClick = useDebounce(sectionClick, 300)
  const mapRef = useMap()
  // const icon = new L.Icon({
  //   iconUrl: 'https://storage.cloud.croc.ru:443/tree.cdn.cloudmaps.ru/mapIcons/test.svg',
  //   iconRetinaUrl: 'https://storage.cloud.croc.ru:443/tree.cdn.cloudmaps.ru/mapIcons/test.svg',
  //   iconSize: [35, 35],
  // })


  const plantingsHandlers = useMemo(
    () => ({
      async click(e: any) {
        let type = 'common' as 'common' | 'plantingWithoutSection'

        let fill = [] as any[]
        fill = plantingsWithPhotos.filter((p) => {
          const pCircle = circle(p.geometry, 1, {steps: 10, units: 'centimeters'})
          return booleanWithin(point([e.latlng.lng, e.latlng.lat]), pCircle.geometry)
        })

        if (!fill[0] || !fill[0].id) {
          fill = plantingsWithoutPhotos.filter((p) => {
            const pCircle = circle(p.geometry, 1, {steps: 10, units: 'centimeters'})
            return booleanWithin(point([e.latlng.lng, e.latlng.lat]), pCircle.geometry)
          })
        }

        if (!fill[0] || !fill[0].id) {
          fill = plantingsWithoutSections.filter((p) => {
            const pCircle = circle(p.geometry, 1, {steps: 10, units: 'centimeters'})
            return booleanWithin(point([e.latlng.lng, e.latlng.lat]), pCircle.geometry)
          })
          type = 'plantingWithoutSection'
        }

        let plantingId = null as null | number

        if (fill[0] && fill[0].id !== mapState.preSelectedObject?.id) {
          plantingId = fill[0].id
        }

        if (mapState.selectFinger.isOn) {
          const filteredPlanting = mapState.selectFinger.plantingArray.filter((p) => p.id === plantingId).length

          let newPlantingsArr = [] as any[]

          if (filteredPlanting) {
            newPlantingsArr = mapState.selectFinger.plantingArray.filter((p) => p.id !== plantingId)
          } else {
            newPlantingsArr = [...mapState.selectFinger.plantingArray, {
              id: plantingId,
              plantingNumber: fill[0].plantingNumber,
              uuid: fill[0].uuid
            }]
          }

          setMapState(prev => ({
            ...prev,
            selectFinger: {
              ...mapState.selectFinger,
              plantingArray: newPlantingsArr
            }
          }))
        } else {
          setMapState(prev => ({
            ...prev,
            selectedObject: null,
            preSelectedObject: plantingId ? {id: Number(plantingId), type} : null,
          }))
        }
      },
    }),
    [plantingsWithoutPhotos, plantingsWithPhotos, plantingsWithoutSections, setMapState, mapState.preSelectedObject, mapState.selectFinger]
  )

  const onSectionClick = (e: any) => {
    const section = sections.filter((s) => booleanWithin(point([e.latlng.lng, e.latlng.lat]), s.geometry))

    if (section.length) {
      if (!mapState.selectFinger.isOn) {
        onAddSection(section[0].sectionNumber)
      }
      // toast(`Номер сектора: ${section[0].sectionNumber}`);
    }

    if (mapState.selectFinger.isOn) {
      const filteredSection = mapState.selectFinger.sectionsArray.filter((s) => s.id === section[0].id).length

      let newSectionArr = [] as any[]

      if (filteredSection) {
        newSectionArr = mapState.selectFinger.sectionsArray.filter((s) => s.id !== section[0].id)
      } else {
        newSectionArr = [...mapState.selectFinger.sectionsArray, {
          id: section[0].id,
          sectionNumber: section[0].sectionNumber,
          uuid: section[0].uuid
        }]
      }

      setMapState(prev => ({
        ...prev,
        selectFinger: {
          ...mapState.selectFinger,
          sectionsArray: newSectionArr
        }
      }))
    }
  }

  const onSectionDblClick = (e: any) => {
    const section = sections.find((s) => booleanWithin(point([e.latlng.lng, e.latlng.lat]), s.geometry))

    if (mapState.selectedObject?.uuid !== section.uuid) {
      setMapState(prev => ({
        ...prev,
        selectedObject: section,
        preSelectedObject: {id: section.id, type: 'section'}
      }))
    } else {
      setMapState(prev => ({...prev, selectedObject: null, preSelectedObject: null}))
    }
  }

  const sectionsHandlers = useMemo(
    () => ({
      click(e: any) {
        setSectionClick({e, action: 'click'})
      },
      dblclick(e: any) {
        setSectionClick({e, action: 'dblclick'})
      },
    } as LeafletEventHandlerFnMap), [])

  const bordersHandlers = useMemo(
    () => ({
      dblclick(e: any) {
        const border = borders.find((b) => booleanWithin(point([e.latlng.lng, e.latlng.lat]), b.geometry))

        if (mapState.selectedObject?.uuid !== border.uuid) {
          setMapState(prev => ({
            ...prev,
            selectedObject: border,
            preSelectedObject: {id: border.id, type: 'border'}
          }))
        } else {
          setMapState(prev => ({...prev, selectedObject: null}))
        }
      },
    } as LeafletEventHandlerFnMap), [borders, mapState.selectedObject, setMapState])

  const onEdited = (e: any) => {
    let feature: any

    if (e.layer._latlng) {
      const layer = e.layer._latlng

      feature = point([layer.lng, layer.lat])
    } else {
      const layer = e.layer.editing.latlngs[0]
      feature = polygon(layer)
      // @ts-ignore
      feature.geometry.coordinates[0] = feature.geometry.coordinates[0].map(({lat, lng}) => ([lng, lat]))
      feature.geometry.coordinates[0].push(feature.geometry.coordinates[0][0])
    }

    const plantingDto = {
      schema: ls_getProjectInfo().schema,
      geometry: feature.geometry,
    }

    setMapState((prev) => ({...prev, createdObject: plantingDto}))
  }

  const getSectionCenter = (section: any): [number, number] => {
    return [section.geometry.coordinates[1], section.geometry.coordinates[0]]
  }

  useEffect(() => {
    if (debouncedSectionClick) {
      if (debouncedSectionClick.action === 'click') {
        onSectionClick(debouncedSectionClick.e)
      } else {
        onSectionDblClick(debouncedSectionClick.e)
      }
    }
  }, [debouncedSectionClick])

  useEffect(() => {
    let newCenter

    if (!mapState.center.length) {
      if (borders[0]) {
        newCenter = getCoord(flip(center(borders[0].geometry)))
      } else {
        newCenter = initialCenter
      }
    } else {
      newCenter = mapState.center
    }

    // @ts-ignore
    mapRef.setView([...newCenter], mapState.zoom)
    mapRef.setMaxZoom(27)
  }, [borders])

  useEffect(() => {
    const bottomLeafletBar = document.getElementsByClassName('leaflet-control-attribution')

    const russianFlag = document.createElement('svg')
    russianFlag.innerHTML = '<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="12" height="8"><path fill="#fff" d="M0 0h12v4H0Z"></path><path fill="#0047ff" d="M0 3h12v4H0Z"></path><path fill="#ff0000" d="M0 6h12v4H0Z"></path></svg>'

    bottomLeafletBar[0]?.children[0]?.children[0]?.replaceWith(russianFlag)
  }, [baseLayer])

  return (
    <LayersControl position="topright" collapsed={false}>
      <MapLayersControl/>

      <FeatureGroup>
        <EditControl
          position={'topleft'}
          draw={{
            polygon: {
              metric: true,
              shapeOptions: {
                color: '#ff0000'
              }
            },
            circlemarker: {
              color: '#ff0000'
            },
            rectangle: false,
            marker: false,
            circle: false,
            polyline: false,
          }}
          onCreated={onEdited}
          onEdited={onEdited}
        />
      </FeatureGroup>

      <LayersControl.Overlay name={'границы'} checked={true}>
        <FeatureGroup>
          {borders.map(border => (
            <Polygon
              key={border.uuid}
              positions={mapState.selectedObject?.uuid === border.uuid ? [[mapState.selectedObject.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]] : [[border.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]]}
              eventHandlers={mapState.ruler.isOn ? {} : bordersHandlers}
              stroke={true}
              pathOptions={{color: mapState.selectedObject?.uuid === border.uuid ? 'rgb(31,203,34)' : 'rgb(20,128,236)'}}
            />
          ))}
        </FeatureGroup>
      </LayersControl.Overlay>

      <LayersControl.Overlay name={'секторы'} checked={true}>
        <FeatureGroup>
          {sections.map(section => (
            <Polygon
              key={section.uuid}
              positions={mapState.selectedObject?.uuid === section.uuid ? [[mapState.selectedObject.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]] : [[section.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]]}
              stroke={true}
              pathOptions={{color: mapState.selectFinger.sectionsArray.some((s) => s.uuid === section.uuid) || mapState.selectedObject?.uuid === section.uuid ? 'rgb(31,203,34)' : 'rgb(20,128,236)'}}
              eventHandlers={mapState.ruler.isOn ? {} : sectionsHandlers}
            />
          ))}
        </FeatureGroup>
      </LayersControl.Overlay>

      {mapState.preSelectedSectionNumber &&
        <FeatureGroup>
          {sections.map((s) => (
              <div key={s.sectionNumber}>
                {s.sectionNumber !== mapState.preSelectedSectionNumber ?
                  <></>
                  :
                  <>
                    <Polygon
                      positions={[[s.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]]}
                      stroke={true}
                      color={'rgb(255,116,0)'}
                    />
                  </>
                }
              </div>
            )
          )}
        </FeatureGroup>
      }

      <LayersControl.Overlay name={'Номера секторов'} checked={true}>
        <FeatureGroup>
          {sectionsCenters.map((s) => (
            <Marker
              key={s.sectionNumber}
              position={getSectionCenter(s.center)}
              opacity={0}
            >
              <Tooltip direction="bottom" offset={[0, 0]} opacity={1} permanent>{s.sectionNumber}</Tooltip>
            </Marker>
          ))}
        </FeatureGroup>
      </LayersControl.Overlay>

      <LayersControl.Overlay name={'насаждения'} checked={true}>
        <FeatureGroup>
          {plantingsWithPhotos.map(planting => (
            <CircleMarker
              key={planting.uuid}
              center={
                mapState.selectedObject?.uuid === planting.uuid ?
                  [mapState.selectedObject.geometry.coordinates[1], mapState.selectedObject.geometry.coordinates[0]]
                  :
                  [planting.geometry.coordinates[1], planting.geometry.coordinates[0]]
              }

              radius={6}
              weight={1}
              pathOptions={mapState.selectedObject?.uuid === planting.uuid ? {color: 'rgb(84,255,0)'} : planting.pathOptions}
              eventHandlers={plantingsHandlers}
            />
          ))}
        </FeatureGroup>
      </LayersControl.Overlay>

      {/*<LayersControl.Overlay name={'тест'} checked={true}>*/}
      {/*  <FeatureGroup>*/}
      {/*    {plantingsWithPhotos.map(planting => (*/}
      {/*      <Marker*/}
      {/*        key={planting.uuid}*/}
      {/*        icon={icon}*/}
      {/*        position={[planting.geometry.coordinates[1], planting.geometry.coordinates[0]]}*/}
      {/*        eventHandlers={plantingsHandlers}*/}
      {/*      />*/}
      {/*    ))}*/}
      {/*  </FeatureGroup>*/}
      {/*</LayersControl.Overlay>*/}

      <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin]}>
        <LayersControl.Overlay name={'насаждения без фото'} checked={true}>
          <FeatureGroup>
            {plantingsWithoutPhotos.map(planting => (
              <CircleMarker
                key={planting.uuid}
                center={
                  mapState.selectedObject?.uuid === planting.uuid ?
                    [mapState.selectedObject.geometry.coordinates[1], mapState.selectedObject.geometry.coordinates[0]]
                    :
                    [planting.geometry.coordinates[1], planting.geometry.coordinates[0]]
                }
                radius={6}
                weight={1}
                pathOptions={mapState.selectedObject?.uuid === planting.uuid ? {color: 'rgb(84,255,0)'} : planting.pathOptions}
                eventHandlers={plantingsHandlers}
              />
            ))}
          </FeatureGroup>
        </LayersControl.Overlay>

        <LayersControl.Overlay name={'насаждения без сектора'} checked={true}>
          <FeatureGroup>
            {plantingsWithoutSections.map(planting => (
              <CircleMarker
                key={planting.uuid}
                center={
                  mapState.selectedObject?.uuid === planting.uuid ?
                    [mapState.selectedObject.geometry.coordinates[1], mapState.selectedObject.geometry.coordinates[0]]
                    :
                    [planting.geometry.coordinates[1], planting.geometry.coordinates[0]]
                }
                radius={6}
                weight={1}
                pathOptions={mapState.selectedObject?.uuid === planting.uuid ? {color: 'rgb(84,255,0)'} : planting.pathOptions}
                eventHandlers={plantingsHandlers}
              />
            ))}
          </FeatureGroup>
        </LayersControl.Overlay>
      </PermissionsGate>

      <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin]}>
        {showObjectsWithoutPhotoOnCroc &&
          <>
            <LayersControl.Overlay name={'насаждение с невыгружнными фотографиями'} checked={true}>
              <FeatureGroup>
                {plantingsWithoutPhotoOnCroc.length > 0 && plantingsWithoutPhotoOnCroc.map(planting => {
                  if (planting.geometry) {
                    return (
                      <CircleMarker
                        key={planting.uuid}
                        center={[planting.geometry.coordinates[1], planting.geometry.coordinates[0]]}
                        radius={3.3}
                        weight={.3}
                        pathOptions={planting.pathOptions}
                      />
                    )
                  }
                })}
              </FeatureGroup>
            </LayersControl.Overlay>
          </>
        }
      </PermissionsGate>
    </LayersControl>
  );
};

export default ProjectMap;