import React, { FC } from 'react';
import styles from './mapLayoutModal.module.scss'
import Icon from "../../../../components/UI/Icons/Icon";
import { Link } from "react-router-dom";
import { PLANTING_PAGE } from "../../../../router/routeConsts";
import { Stack } from "@mui/material";
import Loader from "../../../../components/UI/Loader/Loader";
import { useRecoilState } from "recoil";
import { mapStateAtom } from "../../../../store/mapStore";
import { showInfoField } from "../../../../helpers/showInfoField";

interface Props {
  onClose: () => void,
  onCopy: (textToCopy: string) => Promise<void>,
}

const PlantingInfoModal: FC<Props> = ({ onClose, onCopy }) => {
  const [mapState] = useRecoilState(mapStateAtom)

  if (!mapState.preSelectedObject || mapState.preSelectedObject?.type === 'section' || mapState.preSelectedObject?.type === 'border') {
    return <></>
  }

  return (
    <>
      <div className={styles.modal}>
        <div className={styles.close} onClick={onClose}>
          <Icon icon={"close"}/>
        </div>

        <p>Насаждение {mapState.preSelectedObject?.type === "plantingWithoutSection" ? '(без ссылки на сектор)' : ''}</p>

        {mapState.selectedObject ?
          <>
            <Link
              to={PLANTING_PAGE + mapState.selectedObject.id}>
              <span>№</span>
              <span>{mapState.selectedObject.plantingNumber}</span>
            </Link>

            <Stack spacing={1}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '0 0.5rem' }}>
                <p> Название: {showInfoField(mapState.selectedObject.name)} </p>
                <p> Вид: {showInfoField(mapState.selectedObject.kind)} </p>
                <p> Состояние: {showInfoField(mapState.selectedObject.condition)} </p>
                <div style={{display: "flex", alignItems:'center'}}>
                  Координаты: {showInfoField(mapState.selectedObject.geometry)}
                  <div
                    style={{padding: '0 0.5rem'}}
                    className={styles.copy_btn_wrapper}
                    onClick={
                      () => onCopy(showInfoField(mapState.selectedObject.geometry).toString())
                    }
                  >
                    <Icon icon={"copy"}/>
                  </div>
                </div>
              </div>
            </Stack>
          </>
          :
          <Loader/>
        }
      </div>
    </>
  )
};

export default PlantingInfoModal;