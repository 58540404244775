import { DictionaryType } from "../../models/_admin/DictionaryType.type";
import { useQuery } from "react-query";
import { getDictionary } from "../../api/dictionary/dictionaryApi";
import { toast } from "react-toastify";
import { showDate } from "../../helpers/showDate";
import { IDictionary } from "../../models/IDictionary";
import { DELETE_DICTIONARY_URL, UPDATE_DICTIONARY_URL } from "../../api/urls";
import { AxiosResponse } from "axios";
import { useCustomMutation } from "../../api/useCustomMutation";
import { GridColumns } from "@mui/x-data-grid";
import React from "react";
import DictionaryActions from "./dialogs/DictionaryActions";

export const useDictionary = (
  type: DictionaryType,
  toggleIsLoading: (v: boolean) => void,
  isLoading: boolean
) => {
  const { data, refetch } = useQuery(['dictionary', type], () => getDictionary(type))
  const mutation = useCustomMutation()

  const successMutation = (message: string) => {
    toast.success(message);
    refetch()
    toggleIsLoading(false)
  }

  const rows: any = data?.data.map((dictionary: IDictionary) => ({
    id: dictionary.id,
    name: dictionary.name,
    dateCreated: showDate(dictionary.dateCreated, { withTime: true }),
    dateUpdated: showDate(dictionary.dateUpdated, { withTime: true }),
    dateDeleted: dictionary.dateDeleted ? showDate(dictionary.dateDeleted, { withTime: true }) : 'Не удалён'
  }))

  const onEdit = (dto: any) => {
    toggleIsLoading(true)

    mutation.mutateAsync({
      method: 'patch',
      data: dto,
      url: UPDATE_DICTIONARY_URL
    })
      .then(({ data }: AxiosResponse) => {
        successMutation(data.message)
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
        toggleIsLoading(false)
      })
  }

  const onRemove = (id: any) => {
    toggleIsLoading(true)

    mutation.mutateAsync({
      method: 'delete',
      url: DELETE_DICTIONARY_URL + id + `?t=${type}`
    })
      .then(({ data }: AxiosResponse) => {
        successMutation(data.message)
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
        toggleIsLoading(false)
      })
  }

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      type: 'number'
    },
    {
      field: 'name',
      headerName: 'Название',
      width: 250,
      type: 'string'
    },
    {
      field: 'dateCreated',
      headerName: 'Дата создания',
      width: 180
    },
    {
      field: 'dateUpdated',
      headerName: 'Дата изменения',
      width: 180
    },
    {
      field: 'dateDeleted',
      headerName: 'Дата удаления',
      width: 180
    },
    {
      field: 'actions',
      headerName: 'Действия',
      width: 120,
      renderCell: (params =>
          <DictionaryActions
            params={params}
            onEdit={onEdit}
            onRemove={onRemove}
            isLoading={isLoading}
            type={type}
          />
      ),
    }
  ]

  return {
    columns,
    rows,
    refetch
  }
}