// packages
import React, {memo, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import LoadingButton from "@mui/lab/LoadingButton";
import {Confirm} from 'react-st-modal';
import {toast} from 'react-toastify';
import {useQuery} from 'react-query';
import {useRecoilState} from 'recoil';
//components
import PlantingHeader from '../../headers/PlantingHeader/PlantingHeader';
import Loader from '../../../components/UI/Loader/Loader';
import ActionList from '../../../components/UI/ActionList/ActionList';
import PermissionsGate from '../../../helpers/permissions/PermissionsGate';
import Icon from '../../../components/UI/Icons/Icon';
import {StyledTableCell, StyledTableRow} from "../../../components/UI/StyledTable/StyledTable";
import ShowUserActionsHistory
  from "../../_admin/statistics/userActionsStatistic/userActionsStatisticComponents/ShowUserActionsHistory";
import PhotoWithZoom from "../../../components/PhotoWithZoom/PhotoWithZoom";
import NavigateBetweenPlantings from "../../../components/NavigateBetweenPlantings/NavigateBetweenPlantings";
// hooks
import {plantingDto} from './usePlantings';
import {useCustomMutation} from '../../../api/useCustomMutation';
// helpers
import {showInfoField} from '../../../helpers/showInfoField';
import {ls_getProjectInfo} from '../../../helpers/localStorage';
import {confirmTextHelper} from '../../../helpers/confirmTextHelper';
import {SCOPES} from '../../../helpers/permissions/permissionsMaps';
import {IUserActionsHistory} from "../../../models/IUserActionsHistory";
// styles
import styles from './plantings.module.scss';
// store
import {mapStateAtom} from '../../../store/mapStore';
// urls/api
import {getPlantingById} from '../../../api/project/projectApi';
import {EDIT_PLANTING_PAGE, MAP_PAGE, PLANTING_PAGE} from '../../../router/routeConsts';
import {
  DELETE_PLANTING_PHOTO_URL,
  DELETE_PLANTING_URL,
  GET_USER_ACTIONS_HISTORY_URL,
  WRITE_USER_HISTORY_URL
} from '../../../api/urls';

interface IActionsHistory {
  open: boolean,
  data: IUserActionsHistory[]
}

const Planting = () => {
  const params = useParams()
  const navigate = useNavigate()
  const mutation = useCustomMutation()
  const [actionsHistory, setActionsHistory] = useState<IActionsHistory>({open: false, data: []})
  const projectInfo = ls_getProjectInfo()
  const [, setMapState] = useRecoilState(mapStateAtom)

  const {
    data: response,
    isLoading,
    refetch,
    isError
  } = useQuery(['planting', params.id], () => getPlantingById(Number(params.id!)), {retry: false})

  const [currentPlanting, setCurrentPlanting] = useState<any>(undefined)

  const imageUrl = projectInfo.hostingUrl + projectInfo.imageFolder + '/'

  const onSubmit = async () => {
    try {
      setActionsHistory({open: true, data: []})

      const query = `?schema=${projectInfo.schema}&actionObject=planting&objectId=${currentPlanting.id}`

      const {data} = await mutation.mutateAsync({
        method: "get",
        url: GET_USER_ACTIONS_HISTORY_URL + query
      })

      setActionsHistory((prev) => ({...prev, data}));

    } catch (e: any) {
      setActionsHistory({open: false, data: []})

      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  const onRemovePhoto = async (id: number, photo: string) => {
    try {
      const {body, title, cancel, confirm} = confirmTextHelper('фото насаждения', 'фото насаждения')
      const result = await Confirm(body, title, confirm, cancel)

      if (!result) return

      const {data} = await mutation.mutateAsync({
        method: 'delete',
        url: DELETE_PLANTING_PHOTO_URL + `?id=${id}&photo=${photo}&schema=${projectInfo.schema}`,
      })

      toast.success(data.message)
      await refetch()
    } catch (e: any) {
      toast.error(
        <p>
          {e.response?.data.message}
          {e.response?.data.error}
        </p>
      )
    }
  }

  const onOpenEditPlanting = () => {
    navigate(EDIT_PLANTING_PAGE + currentPlanting.id)
  }

  const onOpenPlantingInMap = () => {
    if (!currentPlanting.geometry) {
      toast.error('Нельзя перейти на карту к насаждению без геометрии')
      return
    }

    setMapState(prev => ({
      ...prev,
      center: [currentPlanting.geometry.coordinates[1], currentPlanting.geometry.coordinates[0]],
      selectedObject: currentPlanting,
      preSelectedObject: {id: currentPlanting.id, type: 'common'},
      zoom: 30
    }))

    navigate(MAP_PAGE)
  }

  const onDelete = async () => {
    try {
      const {body, title, cancel, confirm} = confirmTextHelper('насаждения', 'насаждение')
      const result = await Confirm(body, title, confirm, cancel)

      if (!result) return

      await mutation.mutateAsync({
        method: 'delete',
        url: DELETE_PLANTING_URL + currentPlanting.id + `?schema=${projectInfo.schema}`
      })

      const changesDescription = {
        objectId: currentPlanting.id,
        action: 'delete',
        objectNumber: currentPlanting.plantingNumber
      }

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: projectInfo.schema,
          changesDescription,
          actionObject: 'planting'
        },
        url: WRITE_USER_HISTORY_URL
      })

      setMapState(prev => ({...prev, selectedObject: null}))
      navigate(-1)
    } catch (e: any) {
      toast.error(
        <p>
          {e.response?.data.message}
          {e.response?.data.error}
        </p>
      )
    }
  }

  const onLowerPlanting = () => {
    navigate(PLANTING_PAGE + response?.data.lowerId)
  }
  const onGreaterPlanting = () => {
    navigate(PLANTING_PAGE + response?.data.greaterId)
  }

  const onGoToPlanting = (id: number) => {
    if (!Number.isInteger(Number(id)) || id < 0) {
      toast.error('id насаждения может быть только целым положительным числом')
      return
    }

    navigate(PLANTING_PAGE + id)
  }

  useEffect(() => {
    setCurrentPlanting(response?.data.planting)
  }, [response])

  if (isError) {
    toast.error('Ошибка получения ограды')
    navigate(-1)
    return <></>
  }

  if (isLoading || !response || !currentPlanting) {
    return <Loader/>
  }

  return (
    <div>
      <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
        <PlantingHeader title={'Информация о насаждение'}/>

        <Stack direction={'row'} spacing={1}>
          <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin, SCOPES.superUser]}>
            <>
              <LoadingButton
                loading={mutation.isLoading}
                variant={'contained'}
                onClick={onSubmit}
              >
                Посмотреть историю изменений
              </LoadingButton>
            </>
          </PermissionsGate>

          <Button variant={'contained'} onClick={onOpenPlantingInMap}>
            На карту
          </Button>

          <PermissionsGate scopes={[SCOPES.canEdit]}>
            <ActionList
              items={[
                {cb: onOpenEditPlanting, text: 'Редактировать'},
                {cb: () => onDelete(), text: 'Удалить насаждение'},
              ]}
            />
          </PermissionsGate>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={2} className={styles.modal_wrapper}>
        <Stack className={styles.planting__wrapper} spacing={2}>
          <Stack className={styles.planting_info_wrapper} spacing={2}>
            <div>
              <Table aria-label="customized table" size={'small'} stickyHeader={true}>
                <TableHead>
                  <TableRow>
                    <TableCell/>

                    <TableCell>
                      Из базы данных
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {plantingDto.map(el => (
                    <PermissionsGate key={el.label} scopes={el.permissions}>
                      <StyledTableRow key={el.label}>
                        <StyledTableCell>
                          {el.label}
                        </StyledTableCell>

                        <StyledTableCell>
                          {showInfoField(currentPlanting[el.fieldName], {dateWithoutTime: true})}
                        </StyledTableCell>
                      </StyledTableRow>
                    </PermissionsGate>
                  ))}
                </TableBody>
              </Table>

            </div>
          </Stack>

          <NavigateBetweenPlantings
            onBack={response?.data.lowerId ? onLowerPlanting : undefined}
            onForward={response?.data.greaterId ? onGreaterPlanting : undefined}
            onGoTo={onGoToPlanting}
          />
        </Stack>

        <div style={{width: '50%'}}>
          {/*<MiniMap planting={currentPlanting}/>*/}

          <Box>
            {currentPlanting.photos?.length
              ? currentPlanting.photos.map((photo: any) => (
                <div key={photo}>
                  <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin, SCOPES.superUser]}>
                    <Icon
                      icon={'delete'}
                      color={'#ff0000'}
                      className={styles.remove_photo}
                      onClick={() => onRemovePhoto(currentPlanting.id, photo)}
                    />
                  </PermissionsGate>
                  <PhotoWithZoom imageUrl={imageUrl + photo}/>
                </div>
              ))
              : <h2> Без фото </h2>
            }
          </Box>
        </div>
      </Stack>

      <Dialog
        open={actionsHistory.open}
        onClose={() => setActionsHistory({open: false, data: []})}
        maxWidth={"xl"}
      >
        <DialogTitle>
          История изменений насаждения с id: {currentPlanting.id}
        </DialogTitle>

        <DialogContent>
          {
            mutation.isLoading ?
              <Loader/>
              :
              <>
                {
                  actionsHistory.data.length !== 0 ?
                    <ShowUserActionsHistory actionsHistoryProp={actionsHistory.data}/>
                    :
                    <p>Насаждение не изменялось</p>
                }
              </>
          }
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default memo(Planting);