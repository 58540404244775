import React, { useState } from 'react'
import { GridColumns } from '@mui/x-data-grid'
import { showDate } from '../../../helpers/showDate'
import ProjectActions from './projectsActions/ProjectActions'
import { IProject } from '../../../models/IProject'
import { useCustomMutation } from '../../../api/useCustomMutation'
import { AxiosResponse } from 'axios'
import {
  CHECK_SEQUENCES_URL,
  RESTORE_PROJECT_URL,
  SOFT_DELETE_PROJECT_URL,
  UNSOFT_DELETE_PROJECT_URL,
  UPDATE_PROJECT_URL, WRITE_USER_HISTORY_URL
} from '../../../api/urls'
import { toast } from 'react-toastify'
import { useQuery } from 'react-query'
import { getDeletedProjects, getProjects } from '../../../api/project/projectApi'

interface IGetTableProps {
  urlRender: any,
}

export const useProjects = ({ urlRender }: IGetTableProps) => {
  let dataObj: any
  const [deletedToggle, setDeletedToggle] = useState(false)
  const deleted = useQuery(['deletedProjects'], getDeletedProjects)
  const notDeleted = useQuery(['projects'], getProjects)

  if (!deletedToggle) {
    dataObj = notDeleted
  } else {
    dataObj = deleted
  }

  const { data: projects } = dataObj
  const { refetch: projectsRefetch } = notDeleted
  const { refetch: deletedProjectsRefetch } = deleted
  const mutation = useCustomMutation()

  const handleToggle = () => {
    setDeletedToggle(!deletedToggle)
  }

  const successMutation = (message: string) => {
    toast.success(message);
    projectsRefetch()
    deletedProjectsRefetch()
  }

  const onRestore = (id: number) => {
    mutation.mutateAsync({
      method: 'patch',
      url: RESTORE_PROJECT_URL + id
    })
      .then(({ data }: AxiosResponse) => {
        successMutation(data.message)
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
      })
  }

  const onEdit = (projectId: number, dto: any) => {
    mutation.mutateAsync({
      method: 'patch',
      data: dto,
      url: UPDATE_PROJECT_URL + projectId
    })
      .then(({ data }: AxiosResponse) => {
        successMutation(data.message)
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
      })
  }

  const onRemove = (projectId: number) => {
    mutation.mutateAsync({
      method: 'delete',
      url: SOFT_DELETE_PROJECT_URL + projectId
    })
      .then(({ data }: AxiosResponse) => {
        successMutation(data.message)
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
      })
  }

  const onDelete = (projectId: number) => {
    mutation.mutateAsync({
      method: 'delete',
      url: UNSOFT_DELETE_PROJECT_URL + projectId
    })
      .then(({ data }: AxiosResponse) => {
        successMutation(data.message)
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
      })
  }

  const rows: any = projects ? projects.data.map((project: IProject) => ({
    id: project.id,
    name: project.name,
    schema: project.schema,
    area: project.area?.name,
    hostingUrl: project.hostingUrl,
    imageFolder: project.imageFolder,
    dateCreated: showDate(project.dateCreated, { withTime: true }),
    dateUpdated: showDate(project.dateUpdated, { withTime: true }),
    dateDeleted: project.dateDeleted ? showDate(project.dateDeleted, { withTime: true }) : 'Не удалён',
    actions: project.id
  })) : []

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      type: 'number'
    },
    {
      field: 'name',
      headerName: 'Название',
      width: 200,
      type: 'string'
    },
    {
      field: 'schema',
      headerName: 'Схема',
      width: 200,
      type: 'string'
    },
    {
      field: 'imageFolder',
      headerName: 'Папка фото',
      width: 200,
      type: 'string'
    },
    {
      field: 'area',
      headerName: 'Объект',
      width: 200,
      type: 'string'
    },
    {
      field: 'hostingUrl',
      headerName: 'url хостинга',
      width: 200,
      renderCell: (params: any) => urlRender(params.value),
    },
    {
      field: 'dateCreated',
      headerName: 'Дата создания',
      width: 150
    },
    {
      field: 'dateUpdated',
      headerName: 'Дата изменения',
      width: 150
    },
    {
      field: 'dateDeleted',
      headerName: 'Дата удаления',
      width: 150
    },
    {
      field: 'actions',
      headerName: 'Действия',
      width: 120,
      renderCell: (params =>
          <ProjectActions
            params={params}
            onEdit={onEdit}
            onRemove={onRemove}
            isLoading={mutation.isLoading}
            onRestore={onRestore}
            onDelete={onDelete}
          />
      ),
    }
  ]

  return {
    rows,
    columns,
    refetch: projectsRefetch,
    handleToggle,
    isExistDeleted: !deleted.data?.data.length && !deletedToggle
  }
}
