export const russifier: { [key: string]: { control: string, edit: string, delete: string } } = {
  condition: {
    control: 'состояниями',
    edit: 'состояния',
    delete: 'состояние',
  },
  genus: {
    control: 'родами',
    edit: 'рода',
    delete: 'род',
  },
  kind: {
    control: 'видами',
    edit: 'вида',
    delete: 'вид',
  },
  life_form: {
    control: 'жизненными формами',
    edit: 'жизненной формы',
    delete: 'жизненную форму',
  },
  name: {
    control: 'названиями',
    edit: 'названия',
    delete: 'название',
  },
}