import { useRecoilValue } from 'recoil';
import { userSelector } from '../../store/userStore';

export const useGetRole = () => {
  const user = useRecoilValue(userSelector)

  if (user.roles) {
    return {
      role: user?.roles[0]?.name
    }
  }

  return {
    role: 'user'
  }
}