// packages
import React, { FC, useState } from 'react'
import { GridRenderCellParams } from '@mui/x-data-grid'
import { useForm } from 'react-hook-form'
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { yupResolver } from '@hookform/resolvers/yup'
import { useQuery } from 'react-query'
// components
import Loader from '../../../../components/UI/Loader/Loader'
import Icon from "../../../../components/UI/Icons/Icon";
import DeleteOrRestoreButtons from "../../../../components/UI/CrudActions/DeleteOrRestoreButtons";
// logic
import { getAreas } from '../../../../api/area/getAreas'
// validation
import { updateProjectSchema } from '../../../../validationSchemas/updateProjectSchema'

interface Props {
  params: GridRenderCellParams
  onEdit: any
  onRemove: any
  isLoading: boolean
  onRestore: any
  onDelete: any
}

const ProjectActions: FC<Props> = ({ params, onEdit, onRemove, isLoading, onRestore, onDelete }) => {
  const { handleSubmit, register, reset } = useForm({
    resolver: yupResolver(updateProjectSchema),
    defaultValues: {
      schema: params.row.schema,
      name: params.row.name,
      idArea: params.row.idArea,
      hostingUrl: params.row.hostingUrl,
      imageFolder: params.row.imageFolder
    }
  })

  const { data: areas } = useQuery(['area'], getAreas)

  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false)

  const handleOpenEdit = () => setIsOpenEditDialog(true)
  const handleCloseEdit = () => setIsOpenEditDialog(false)

  const findArea = (name: string) => {
    return areas?.data.areas.find((obj: any) => obj.name === name).id
  }

  const onEditProject = (dto: any) => {
    if (dto.idArea) {
      dto.idArea = findArea(dto.idArea)
      onEdit(params.row.id, dto);
      handleCloseEdit()
      reset()
      return
    }

    onEdit(params.row.id, {
      name: dto.name,
      hostingUrl: dto.hostingUrl,
      schema: dto.schema,
      imageFolder: dto.imageFolder
    })

    handleCloseEdit()

    reset()
  }

  if (!areas) {
    return <Loader/>
  }

  const autoCompleteData = areas.data.areas.map((cem: any) => ({
    id: cem.id,
    name: cem.name,
  }))

  return (
    <>
      <IconButton onClick={handleOpenEdit}>
        <Icon icon={'edit'} color={'#ff5b00'}/>
      </IconButton>

      <Dialog open={isOpenEditDialog} onClose={handleCloseEdit}>
        <DialogTitle>
          Обновление проекта
        </DialogTitle>

        <DialogContent>
          <Stack spacing={2} padding={1}>
            <TextField {...register('schema')} label={'Схема'} disabled={true}/>
            <TextField {...register('name')} label={'Название'}/>
            <TextField {...register('imageFolder')} label={'Папка фото'}/>

            <Autocomplete
              disablePortal
              getOptionLabel={(option: any) => option.name || ''}
              options={autoCompleteData}
              sx={{ width: 300 }}
              renderInput={(params) =>
                <TextField
                  {...params}
                  {...register('idArea')}
                  label={'Объект'}
                />}
            />

            <TextField {...register('hostingUrl')} label={'Url хостинга'}/>
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton onClick={handleCloseEdit} autoFocus loading={isLoading}>
            Отмена
          </LoadingButton>
          <LoadingButton onClick={handleSubmit(onEditProject)}>
            Обновить
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <DeleteOrRestoreButtons
        isLoading={false}
        onRestore={onRestore}
        params={params}
        onRemove={onRemove}
        currentObject={'project'}
      />
    </>
  )
}

export default ProjectActions